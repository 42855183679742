import React from 'react'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import Tag from '../../utils/Tag'
import BrowserIcon from '../../utils/BrowserIcon'
import Icon from '../../utils/Icon'
import AuditDetailsPageCardScreenshot from './AuditDetailsPageCardScreenshot'
import Button from '../../utils/Button'

const FRAGMENT = gql`
    fragment AuditDetailsPageCard on Audit {
        id
        name
        scanFrequency
        createdAt
        imageUrl
        tags
        isMobile
        isWeb
        latestScans {
            id
            url
            consentProfile {
                id
                name
            }
            screenshots {
                id
                name
                file {
                    id
                    url
                }
            }
        }
        auditScenarios {
            id
            name
        }
        auditBuilds {
            id
            deviceOs
        }
        consentProfiles {
            id
            name
        }
        domains {
            id
            url
        }
        alertsByPeriod {
            startDate
            endDate
            warningAlerts
            issueAlerts
            criticalAlerts
        }
    }
`

const CardTitle = (props) => (
    <div
        className="mb-2 text-xs font-semibold uppercase text-gray-500"
        {...props}
    />
)

const AuditDetailsPageCard = ({ audit }) => {
    const {
        id,
        domains,
        consentProfiles,
        auditScenarios,
        auditBuilds,
        tags,
        isMobile,
        isWeb,
    } = audit
    const history = useHistory()
    let uniqueDeviceOss = []
    if (auditBuilds && auditBuilds.length > 0) {
        uniqueDeviceOss = Array.from(
            new Set(auditBuilds?.map((build) => build.deviceOs))
        )
    }
    return (
        <div className="mb-8 flex gap-4 rounded-2xl border border-gray-200 bg-white p-6">
            <AuditDetailsPageCardScreenshot audit={audit} />
            <div className="flex flex-col gap-6">
                {isWeb && (
                    <div>
                        <CardTitle>
                            <Trans>All domains</Trans>
                        </CardTitle>
                        <div className="flex flex-wrap gap-2 text-sm">
                            {domains.map(({ url }) => (
                                <div>{url}</div>
                            ))}
                        </div>
                    </div>
                )}
                {consentProfiles && consentProfiles.length > 0 && (
                    <div>
                        <CardTitle>
                            <Trans>Consent profiles</Trans>
                        </CardTitle>
                        <div className="flex gap-2">
                            {consentProfiles?.map(({ name }) => (
                                <Tag>{name}</Tag>
                            ))}
                        </div>
                    </div>
                )}
                {isMobile && (
                    <div>
                        <CardTitle>
                            <Trans>Scenarios</Trans>
                        </CardTitle>
                        <div className="flex gap-2">
                            {auditScenarios?.map(({ name, id: scenarioId }) => (
                                <Tag key={scenarioId}>{name}</Tag>
                            ))}
                            <Icon
                                className="cursor-pointer text-slate-900 hover:text-slate-600"
                                name="plus"
                                size="sm"
                                onClick={() => {
                                    history.push(
                                        `/audits/${id}/settings#auditScenarios`
                                    )
                                }}
                            />
                        </div>
                    </div>
                )}
                {isWeb && (
                    <div>
                        <CardTitle>
                            <Trans>Browsers</Trans>
                        </CardTitle>
                        <div className="">
                            <BrowserIcon name="chrome" className="h-6" />
                        </div>
                    </div>
                )}
                {isMobile && (
                    <div>
                        <CardTitle>
                            <Trans>Devices</Trans>
                        </CardTitle>
                        <div className="flex items-center gap-2">
                            {uniqueDeviceOss.map((deviceOs) => (
                                <Icon
                                    key={deviceOs}
                                    name={deviceOs.toLowerCase()}
                                    className="h-6"
                                />
                            ))}
                            <Icon
                                className="cursor-pointer text-slate-900 hover:text-slate-600"
                                name="plus"
                                size="sm"
                                onClick={() => {
                                    history.push(
                                        `/audits/${id}/settings#auditBuilds`
                                    )
                                }}
                            />
                        </div>
                    </div>
                )}
                {tags && tags.length > 0 && (
                    <div>
                        <CardTitle>
                            <Trans>Tags</Trans>
                        </CardTitle>
                        <div className="flex gap-2">
                            {tags.map((tag) => (
                                <Tag type="white">{tag}</Tag>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

AuditDetailsPageCard.FRAGMENT = FRAGMENT

export default AuditDetailsPageCard
