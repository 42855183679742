import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteAuditScenarioModal from './DeleteAuditScenarioModal'
import useDeleteAuditScenarioMutation from './useDeleteAuditScenarioMutation'

export default function DeleteAuditScenarioModalWithState({
    auditScenario,
    onDismiss,
    onCompleted,
    onError,
}) {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [deleteAuditScenario, { loading }] = useDeleteAuditScenarioMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteAuditScenarioModal
            onDismiss={onDismiss}
            auditScenario={auditScenario}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    await deleteAuditScenario({
                        variables: { id: auditScenario.id },
                    })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                {auditScenario.name} successfully deleted.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}
