import React from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import Button from '../../utils/Button'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import AlertCountTag from '../../utils/AlertCountTag'
import Tabs, { Tab } from '../../utils/Tabs'
import ScanDetailsDrawerTabPages from './ScanDetailsDrawerTabPages'
import ScanPageActionsDropdownMenu from '../staff-scans/ScanPageActionsDropdownMenu'
import useDrawerStack from '../../hooks/useDrawerStack'
import * as constants from '../../../constants'

const {
    APPLICATION_LEVEL_SCAN: SCAN,
    APPLICATION_LEVEL_AUDIT: AUDIT,
    APPLICATION_LEVEL_ORGANIZATION: ORGANIZATION,
} = constants

const QUERY = gql`
    query scanDetailsDrawer($id: ID!) {
        scan(id: $id) {
            id
            url
            createdAt
            audit {
                id
                name
                imageUrl
            }
        }
    }
`

export default function CodeScanDetailsDrawer({
    scanId,
    onDeleteScanCompleted,
    level,
    drawerState,
    setDrawerState,
}) {
    const { close } = useDrawerStack()
    const activeTab = drawerState.activeTab || 'files'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { id: scanId }
    const {
        data = {},
        error,
        loading,
    } = useQuery(QUERY, {
        variables,
    })
    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />
    const { audit, url, createdAt } = data.scan
    return (
        <>
            <DrawerHeader
                avatarSrc={audit.imageUrl}
                avatarIconName="scan"
                title={url}
                description={<DateTime>{createdAt}</DateTime>}
                buttons={
                    !level === AUDIT && (
                        <ScanPageActionsDropdownMenu
                            scan={data.scan}
                            onDeleteScanCompleted={() => {
                                close()
                                onDeleteScanCompleted()
                            }}
                        >
                            <Button
                                type="white"
                                size="sm"
                                iconBeforeProps={{
                                    name: 'menu-dots',
                                    size: 'sm',
                                }}
                            />
                        </ScanPageActionsDropdownMenu>
                    )
                }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'files'}
                    onClick={() => setActiveTab('files')}
                >
                    <Trans>Files</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'alerts'}
                    onClick={() => setActiveTab('alerts')}
                >
                    <Trans>Alerts</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'data-identifiers'}
                    onClick={() => setActiveTab('data-identifiers')}
                >
                    <Trans>Data identifier</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'data-sinks'}
                    onClick={() => setActiveTab('data-sinks')}
                >
                    <Trans>Data sink</Trans>
                </Tab>
            </Tabs>
            <DrawerContent>
                {activeTab === 'files' && (
                    <ScanDetailsDrawerTabPages scanId={scanId} />
                )}
            </DrawerContent>
        </>
    )
}
