/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormSelect from '../../utils/FormSelect'
import Button from '../../utils/Button'
import ButtonLink from '../../utils/ButtonLink'
import Toggle from '../../utils/Toggle'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'
import FormButtonGroup from '../../utils/FormButtonGroup'
import TagSelect from '../../utils/TagSelect'
import RadioButton from '../../utils/RadioButton'

const CreateAuditForm = ({
    scanFrequencies,
    cmps = [],
    formState,
    onSubmit,
    isLoading,
}) => {
    const {
        values,
        handleChange,
        handleAddDomain,
        handleRemoveDomain,
        getErrorMessages,
        getNativeInputProps,
    } = formState
    return (
        <Card>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    onSubmit()
                }}
            >
                <CardHeader
                    title={<Trans>Audit Information</Trans>}
                    description={
                        <Trans>
                            Please fill in all information related to the audit
                            and its domains below.
                        </Trans>
                    }
                />
                <div className="divide-y">
                    <FormGroup
                        isHorizontal
                        label={<Trans>Name</Trans>}
                        htmlFor="name"
                    >
                        <FormInput
                            id="name"
                            className="max-w-lg"
                            errors={getErrorMessages('name')}
                            {...getNativeInputProps('name')}
                        />
                    </FormGroup>

                    <FormGroup
                        isHorizontal
                        label={<Trans>CMP</Trans>}
                        htmlFor="cmpId"
                    >
                        <FormSelect
                            id="cmpId"
                            className="max-w-md"
                            errors={getErrorMessages('cmpId')}
                            {...getNativeInputProps('cmpId')}
                        >
                            {cmps.map((cmp) => (
                                <option key={cmp.id} value={cmp.id}>
                                    {cmp.name}
                                </option>
                            ))}
                        </FormSelect>
                    </FormGroup>
                    <FormGroup
                        isHorizontal
                        label={<Trans>Take screenshots</Trans>}
                        htmlFor="takeScreenshots"
                    >
                        <div className="flex items-center justify-between">
                            <Toggle
                                name="takeScreenshots"
                                value={values.takeScreenshots}
                                onClick={(value) => {
                                    formState.handleChange(
                                        'takeScreenshots',
                                        value
                                    )
                                }}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        isHorizontal
                        label={<Trans>Domains</Trans>}
                        htmlFor="domains"
                    >
                        {values.domains.length > 0 &&
                            values.domains.map((domain, domainIndex) => (
                                <div
                                    key={domainIndex}
                                    className="mb-4 grid w-full grid-cols-8 gap-4"
                                >
                                    <FormInput
                                        id="domainName"
                                        type="text"
                                        className="col-span-6"
                                        errors={getErrorMessages(
                                            `domains.${domainIndex}`
                                        )}
                                        {...getNativeInputProps(
                                            `domains.${domainIndex}`
                                        )}
                                    />
                                    <div className="col-span-2 flex items-center">
                                        <ButtonLink
                                            type="danger"
                                            onClick={() =>
                                                handleRemoveDomain(domainIndex)
                                            }
                                            disabled={
                                                values.domains.length === 1
                                            }
                                        >
                                            <Trans>Remove</Trans>
                                        </ButtonLink>
                                    </div>
                                </div>
                            ))}
                        <div>
                            <ButtonLink onClick={() => handleAddDomain()}>
                                <Trans>+ Add another domain</Trans>
                            </ButtonLink>
                        </div>
                    </FormGroup>
                    <FormGroup isHorizontal label={<Trans>Tags</Trans>}>
                        <TagSelect
                            value={values.tags}
                            onChange={(tags) => handleChange('tags', tags)}
                        />
                    </FormGroup>
                </div>
                <FormButtonGroup>
                    <Button
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        <Trans>Save changes</Trans>
                    </Button>
                    <Link to="/audits">
                        <Button type="white">
                            <Trans>Cancel</Trans>
                        </Button>
                    </Link>
                </FormButtonGroup>
            </form>
        </Card>
    )
}

export default CreateAuditForm
