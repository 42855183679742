import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useDeleteAuditBuildMutation from './useDeleteAuditBuildMutation'
import DeleteAuditBuildModal from './DeleteAuditBuildModal'

export default function DeleteAuditBuildModalWithState({
    auditBuild,
    onDismiss,
    onCompleted,
    onError,
}) {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [deleteAuditBuild, { loading }] = useDeleteAuditBuildMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteAuditBuildModal
            onDismiss={onDismiss}
            auditBuild={auditBuild}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    await deleteAuditBuild({ variables: { id: auditBuild.id } })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                {auditBuild.name} successfully deleted.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}
