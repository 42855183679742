import React from 'react'
import Table from '../../utils/Table'
import AuditScansPageTableRow from '../audits-scans/AuditScansPageTableRow'
import TableBody from '../../utils/TableBody'
import AuditScansPageTableHeaderRow from '../audits-scans/AuditScansPageTableHeaderRow'

const AuditDetailsPageScansTable = ({ audit, scans = [] }) => (
    <Table data-testid="AuditScansPageTable">
        <thead>
            <AuditScansPageTableHeaderRow audit={audit} />
        </thead>
        <TableBody>
            {scans.map((scan) => (
                <AuditScansPageTableRow
                    key={scan.id}
                    audit={audit}
                    scan={scan}
                />
            ))}
        </TableBody>
    </Table>
)

export default AuditDetailsPageScansTable
