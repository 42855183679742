import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import Avatar from '../../utils/Avatar'
import AuditSettingsPageAuditBuildTableRowActionButtonWithState from './AuditSettingsPageAuditBuildTableRowActionButtonWithState'
import Tooltip from '../../utils/Tooltip'

const FRAGMENT = gql`
    fragment AuditSettingsPageAuditBuildTableRow on AuditBuild {
        id
        bundle
        name
        version
        iconUrl
        deviceOs
        uploadCompletedAt
        uploadFailedAt
        errorMessage
    }
`

const AuditSettingsPageAuditBuildTableRow = ({
    auditBuild,
    onDeleteAuditBuildCompleted,
}) => {
    const {
        id,
        name,
        version,
        bundle,
        iconUrl,
        deviceOs,
        uploadCompletedAt,
        uploadFailedAt,
        errorMessage,
    } = auditBuild

    const isUploading = !uploadCompletedAt && !uploadFailedAt
    return (
        <TableRow data-testid="AuditSettingsPageLoginProfileTableRow">
            <TableCell>
                <div className="flex items-center gap-3">
                    {uploadCompletedAt && (
                        <>
                            <Avatar size="sm" src={iconUrl} />
                            <div>{name}</div>
                            <div className="text-xs text-gray-500">
                                {bundle}
                            </div>
                        </>
                    )}
                    {uploadFailedAt && (
                        <>
                            <Avatar
                                size="sm"
                                iconName="alert-octagon"
                                className="text-red-600"
                                src={iconUrl}
                            />
                            <Tooltip
                                asChild
                                event="hover"
                                contentClassName="max-w-[200px] text-truncate line-clamp-5"
                                content={errorMessage}
                            >
                                <div className="text-red-600">
                                    <Trans>Upload failed</Trans>
                                </div>
                            </Tooltip>
                        </>
                    )}

                    {isUploading && (
                        <>
                            <Avatar
                                size="sm"
                                className="animate-spin"
                                iconName="spinner"
                            />
                            <p>
                                <Trans>Uploading app..</Trans>
                            </p>
                        </>
                    )}
                </div>
            </TableCell>
            <TableCell>{version}</TableCell>
            <TableCell>
                <Tag className="capitalize">{deviceOs.toLowerCase()}</Tag>
            </TableCell>
            <TableCell className="text-right">
                <AuditSettingsPageAuditBuildTableRowActionButtonWithState
                    auditBuild={auditBuild}
                    onDeleteAuditBuildCompleted={onDeleteAuditBuildCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

AuditSettingsPageAuditBuildTableRow.FRAGMENT = FRAGMENT

export default AuditSettingsPageAuditBuildTableRow
