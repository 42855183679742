import React from 'react'
import { Trans } from '@lingui/macro'
import PageHeader from '../../layout/PageHeader'
import PageContent from '../../utils/PageContent'
import TableStateProvider from '../../providers/TableStateProvider'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import StaffHeader from '../../layout/StaffHeader'
import StaffDataIdentifiersPageTableActions from './StaffDataIdentifiersPageTableActions'
import StaffDataIdentifiersPageTable from './StaffDataIdentifiersPageTable'

export default function StaffDataIdentifiersPage({
    search,
    dataIdentifiers,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onSearch,
    isFetching,
    initialOrderBy,
    onChangeOrderBy,
}) {
    return (
        <PageContainer>
            <StaffHeader />
            <SidebarContainer>
                <PageContent size="full">
                    <div className="flex items-center justify-between">
                        <PageHeader title={<Trans>Data identifiers</Trans>} />
                    </div>
                    <TableStateProvider
                        onChangeOrderBy={onChangeOrderBy}
                        initialOrderBy={initialOrderBy}
                    >
                        <StaffDataIdentifiersPageTableActions
                            search={search}
                            onSearch={onSearch}
                        />
                        <StaffDataIdentifiersPageTable
                            dataIdentifiers={dataIdentifiers}
                            isFetchingMore={isFetchingMore}
                            onFetchMore={onFetchMore}
                            hasMoreRecords={hasMoreRecords}
                            isFetching={isFetching}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
