import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>The name is required</Trans>,
    },
])

const valuesToInput = ({
    name,
    type,
    takeScreenshots,
    appleAppStoreUrl,
    googlePlayStoreUrl,
    tags,
}) => ({
    name,
    type,
    takeScreenshots,
    appleAppStoreUrl,
    googlePlayStoreUrl,
    tags,
})

const useCreateMobileAuditFormState = () => {
    const formState = useFormState(
        {
            name: '',
            type: 'MOBILE',
            takeScreenshots: true,
            appleAppStoreUrl: 'https://apps.apple.com/app/',
            googlePlayStoreUrl: 'https://play.google.com/store/apps/',
            tags: [],
        },
        {
            validation,
            valuesToInput,
        }
    )

    return {
        ...formState,
    }
}

export default useCreateMobileAuditFormState
