import React from 'react'
import { motion } from 'framer-motion'

export default function Drawer({ children, isOpen, ...props }) {
    if (!isOpen) {
        return null
    }
    return (
        <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -20, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="flex h-full flex-col overflow-x-auto"
            {...props}
        >
            {children}
        </motion.div>
    )
}
