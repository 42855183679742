import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/client'

export const MUTATION = gql`
    mutation uploadScan($input: UploadScanInput!) {
        uploadScan(input: $input) {
            scan {
                id
            }
        }
    }
`

const useUploadScanMutation = (options) => useMutation(MUTATION, options)

export default useUploadScanMutation

const QUERY = gql`
    query uploadScanModalQuery {
        purposes {
            id
            externalId
            name
        }
    }
`

export function useUploadScanModalQuery(options) {
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    return {
        error,
        purposes: data?.purposes || [],
        isFetching,
        refetch,
    }
}
