import React from 'react'
import clsx from 'clsx'

function FormInputRange(props) {
    return (
        <div className="flex items-center gap-4">
            <div className="text-sm text-gray-700">{props.min}</div>
            <InputRange {...props} />
            <div className="text-sm text-gray-700">{props.max}</div>
        </div>
    )
}

function InputRange({ className, min, max, step, ...props }) {
    return (
        <input
            type="range"
            min={min}
            max={max}
            step={step}
            className={clsx(
                'h-1 w-full cursor-pointer appearance-none rounded-lg bg-gray-200',
                className
            )}
            {...props}
        />
    )
}

export default FormInputRange
