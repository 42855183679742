import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/macro'
import Image from 'next/image'
import Button from '../../utils/Button'
import AuditDetailsPageCardScreenshotWarning from './AuditDetailsPageCardScreenshotWarning'

const AuditDetailsPageCardScreenshot = ({ audit }) => {
    const [screenShotType, setScreenShotType] = useState('after-consent')
    const { latestScans } = audit
    const [firstScan] = latestScans
    if (!firstScan || (firstScan && firstScan.screenshots.length === 0)) {
        return (
            <div className="relative flex-shrink-0 rounded border border-gray-200">
                <div className="flex h-[266px] w-[426px] flex-col items-center justify-center bg-gray-100 text-center">
                    <AuditDetailsPageCardScreenshotWarning
                        icon="warning-triangle"
                        title={<Trans>No screenshot taken</Trans>}
                        description={
                            <Trans>
                                Turn on the &quot;Take screenshots&quot; option
                                in the settings page.
                            </Trans>
                        }
                    />
                </div>
            </div>
        )
    }
    const [screenshot] = firstScan.screenshots.filter(
        (shot) => shot.name === screenShotType
    )
    return (
        <div className="group relative flex-shrink-0 rounded border border-gray-200">
            <div className="absolute right-4 top-4 z-10 hidden group-hover:flex">
                <Button
                    type="white"
                    size="sm"
                    grouped="left"
                    onClick={() => setScreenShotType('before-consent')}
                    className={clsx(
                        screenShotType === 'after-consent' && 'bg-gray-50'
                    )}
                >
                    <Trans>Before</Trans>
                </Button>
                <Button
                    type="white"
                    size="sm"
                    grouped="right"
                    onClick={() => setScreenShotType('after-consent')}
                    className={clsx(
                        screenShotType === 'after-consent'
                            ? 'bg-gray-50 text-gray-900'
                            : 'text-gray-400'
                    )}
                >
                    <Trans>After</Trans>
                </Button>
            </div>
            {screenshot ? (
                <div
                    className={clsx(
                        'relative',
                        audit.isMobile
                            ? 'h-[426px] w-[200px]'
                            : 'h-[266px] w-[426px]'
                    )}
                >
                    <Image
                        className="rounded-md object-contain object-top"
                        src={screenshot.file.url}
                        alt={screenshot.name}
                        sizes="426px"
                        fill
                        priority
                    />
                </div>
            ) : (
                <AuditDetailsPageCardScreenshotWarning
                    icon="warning-triangle"
                    title={<Trans>No screenshot taken</Trans>}
                    description={
                        <Trans>
                            Something went wrong taking the screenshot.
                        </Trans>
                    }
                />
            )}
        </div>
    )
}

export default AuditDetailsPageCardScreenshot
