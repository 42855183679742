import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useCurrentDrawerStack } from '../../hooks/useDrawerStack'
import TableCell from '../../utils/TableCell'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import CollapseButton from '../../utils/CollapseButton'
import DateTime from '../../utils/DateTime'
import Tag from '../../utils/Tag'
import ScanPageDetailsDrawer from '../scans/ScanPageDetailsDrawer'

export function determineObservationPageName(pathname) {
    let pageName = pathname
    if (pathname === '/manual-upload') {
        pageName = <Trans>Manual upload</Trans>
    } else if (pathname.startsWith('/mobile-scan')) {
        pageName = <Trans>Mobile scans</Trans>
    }
    return pageName
}

const AuditObservationTablePageTableRow = ({
    page,
    objectName,
    nextActiveTabOnClick,
}) => {
    const { add } = useCurrentDrawerStack()
    const { url, scanPages } = page
    const [isCollapsed, setIsCollapsed] = useState(true)
    const { pathname } = new URL(url)
    const pageName = determineObservationPageName(pathname)
    return (
        <>
            <TableRow>
                <td className="w-[45px] pl-12">
                    <CollapseButton
                        isCollapsed={isCollapsed}
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsCollapsed(!isCollapsed)
                        }}
                    />
                </td>
                <TableCell size="sm" hasText>
                    {pageName}
                </TableCell>
                <TableCell size="sm" hasText>
                    <Tag>{scanPages.length}x</Tag>
                </TableCell>
            </TableRow>
            {!isCollapsed && (
                <tr>
                    <td colSpan="10">
                        <table className="w-full">
                            <TableBody>
                                {scanPages.map(({ id, scan }) => {
                                    const {
                                        startedAt,
                                        consentProfile,
                                        consentSettings,
                                    } = scan
                                    return (
                                        <TableRow
                                            key={id}
                                            isSelectable
                                            onClick={() => {
                                                add(ScanPageDetailsDrawer, {
                                                    scanPageId: id,
                                                    initialSearchState:
                                                        objectName,
                                                    initialDrawerState: {
                                                        activeTab:
                                                            nextActiveTabOnClick,
                                                    },
                                                })
                                            }}
                                        >
                                            <td className="pl-32" />
                                            <TableCell
                                                size="sm"
                                                hasText
                                                className="w-full"
                                            >
                                                <DateTime>{startedAt}</DateTime>
                                            </TableCell>
                                            <TableCell size="sm" hasText>
                                                {consentProfile?.name && (
                                                    <Tag type="blue">
                                                        {consentProfile?.name}
                                                    </Tag>
                                                )}
                                                {consentSettings?.name && (
                                                    <Tag type="blue">
                                                        {consentSettings?.name}
                                                    </Tag>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </table>
                    </td>
                </tr>
            )}
        </>
    )
}

export default AuditObservationTablePageTableRow
