import React from 'react'
import clsx from 'clsx'
import { Link, useRouteMatch } from 'react-router-dom'

export const Tab = ({ className, isActive, children, onClick }) => (
    <button
        type="button"
        aria-current={isActive && 'page'}
        className={clsx(
            'whitespace-no-wrap group relative flex flex-shrink-0 items-center gap-2 border-transparent px-1 pb-4 text-sm font-semibold transition-colors duration-200',
            isActive
                ? 'text-primary-500'
                : 'text-slate-500 hover:text-slate-700',
            className
        )}
        onClick={onClick}
    >
        {children}
        <div
            className={clsx(
                'absolute bottom-0 left-1/2 h-[2px] -translate-x-1/2 bg-primary-500 transition-width',
                isActive ? 'w-full' : 'w-0 group-hover:w-full'
            )}
        />
    </button>
)

export const TabLink = ({ to, matchPath, exact, className, children }) => {
    const isActive = useRouteMatch({
        path: matchPath || to,
        exact,
    })
    return (
        <Link to={to}>
            <Tab className={className} isActive={isActive}>
                {children}
            </Tab>
        </Link>
    )
}

const Tabs = ({ className, hasMarginBottom = true, children }) => (
    <div
        className={clsx(
            'border-b border-gray-200',
            hasMarginBottom && 'mb-8',
            className
        )}
    >
        <nav className="-mb-px flex space-x-8">{children}</nav>
    </div>
)

export default Tabs
