import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditHeader from '../../layout/AuditHeader'
import PageHeader from '../../layout/PageHeader'
import AuditSettingsFormCard from './AuditSettingsFormCard'
import AuditSettingsAlertsFormCard from './AuditSettingsAlertsFormCard'
import AuditDomainsPageTable from './AuditDomainsPageTable'
import AuditDomainsPageConsentProfilesTable from './AuditDomainsPageConsentProfilesTable'
import AuditSettingsPageLoginProfilesTable from './AuditSettingsPageLoginProfilesTable'
import AuditSettingsPageAuditScenariosTable from './AuditSettingsPageAuditScenariosTable'
import { AuditSettingsPageAuditBuildsTable } from './AuditSettingsPageAuditBuildsTable'

export default function AuditSettingsPage({
    organization,
    audit,
    domains,
    loginProfiles,
    cookieTables,
    vendorTables,
    isUpdating,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    settingsFormState,
    alertSettingsFormState,
    onUpdateSettings,
    onUpdateAlertSettings,
    onFetchMore,
    onAddDomain,
    onAddConsentProfile,
    onAddLoginProfile,
    onCreateAuditScenario,
    onCreateAuditBuild,
    onDeleteAuditBuildCompleted,
    onDeleteAuditScenario,
    onDeleteDomainCompleted,
    onRemoveConsentProfileCompleted,
    onRemoveLoginProfileCompleted,
}) {
    const { type } = audit
    return (
        <PageContainer data-testid="AuditSettingsPage">
            <AuditHeader />
            <SidebarContainer>
                <PageContent isFetching={isFetching}>
                    <PageHeader
                        className="mb-6"
                        title={<Trans>Settings</Trans>}
                        description={
                            <Trans>
                                Here you can configure the settings for this
                                audit.
                            </Trans>
                        }
                    />
                    <AuditSettingsFormCard
                        organization={organization}
                        isLoading={isUpdating}
                        formState={settingsFormState}
                        loginProfiles={loginProfiles}
                        onSubmit={onUpdateSettings}
                        auditType={type}
                    />
                    <AuditSettingsAlertsFormCard
                        organization={organization}
                        cookieTables={cookieTables}
                        vendorTables={vendorTables}
                        isLoading={isUpdating}
                        formState={alertSettingsFormState}
                        onSubmit={onUpdateAlertSettings}
                    />
                    {(() => {
                        if (audit.isWeb) {
                            return (
                                <>
                                    <section>
                                        <div className="mb-4 flex justify-end">
                                            <Button
                                                onClick={onAddDomain}
                                                iconBeforeProps={{
                                                    name: 'plus',
                                                    color: 'white',
                                                }}
                                            >
                                                <Trans>Add domain</Trans>
                                            </Button>
                                        </div>
                                        <AuditDomainsPageTable
                                            audit={audit}
                                            domains={domains}
                                            isFetchingMore={isFetchingMore}
                                            onFetchMore={onFetchMore}
                                            hasMoreRecords={hasMoreRecords}
                                            onDeleteDomainCompleted={
                                                onDeleteDomainCompleted
                                            }
                                        />
                                    </section>
                                    <section className="mb-8">
                                        <div className="mb-4 flex justify-end">
                                            <Button
                                                onClick={onAddConsentProfile}
                                                iconBeforeProps={{
                                                    name: 'plus',
                                                    color: 'white',
                                                }}
                                            >
                                                <Trans>
                                                    Add consent profile
                                                </Trans>
                                            </Button>
                                        </div>
                                        <AuditDomainsPageConsentProfilesTable
                                            audit={audit}
                                            consentProfiles={
                                                audit.consentProfiles
                                            }
                                            onRemoveConsentProfileCompleted={
                                                onRemoveConsentProfileCompleted
                                            }
                                            onAddConsentProfile={
                                                onAddConsentProfile
                                            }
                                        />
                                    </section>
                                    <section>
                                        <div className="mb-4 flex justify-end">
                                            <Button
                                                onClick={onAddLoginProfile}
                                                iconBeforeProps={{
                                                    name: 'plus',
                                                    color: 'white',
                                                }}
                                            >
                                                <Trans>Add login profile</Trans>
                                            </Button>
                                        </div>
                                        <AuditSettingsPageLoginProfilesTable
                                            audit={audit}
                                            loginProfiles={audit.loginProfiles}
                                            onRemoveLoginProfileCompleted={
                                                onRemoveLoginProfileCompleted
                                            }
                                            onAddLoginProfile={
                                                onAddLoginProfile
                                            }
                                        />
                                    </section>
                                </>
                            )
                        }
                        if (audit.isMobile) {
                            return (
                                <>
                                    <section id="auditBuilds" className="mb-8">
                                        <div className="mb-4 flex justify-end">
                                            <Button
                                                onClick={onCreateAuditBuild}
                                                iconBeforeProps={{
                                                    name: 'plus',
                                                    color: 'white',
                                                }}
                                            >
                                                <Trans>Create new build</Trans>
                                            </Button>
                                        </div>
                                        <AuditSettingsPageAuditBuildsTable
                                            auditBuilds={audit.auditBuilds}
                                            onDeleteAuditBuildCompleted={
                                                onDeleteAuditBuildCompleted
                                            }
                                        />
                                    </section>
                                    <section id="auditScenarios">
                                        <div className="mb-4 flex justify-end">
                                            <Button
                                                onClick={onCreateAuditScenario}
                                                iconBeforeProps={{
                                                    name: 'plus',
                                                    color: 'white',
                                                }}
                                            >
                                                <Trans>
                                                    Create audit scenario
                                                </Trans>
                                            </Button>
                                        </div>
                                        <AuditSettingsPageAuditScenariosTable
                                            auditScenarios={
                                                audit.auditScenarios
                                            }
                                            onDeleteAuditScenario={
                                                onDeleteAuditScenario
                                            }
                                        />
                                    </section>
                                </>
                            )
                        }
                        return null
                    })()}
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
