import React from 'react'
import { Trans } from '@lingui/macro'
import useTableState from '../../hooks/useTableState'
import SearchInput from '../../utils/SearchInput'
import ScanRangeSelect from '../alerts/ScanRangeSelect'
import TableActionsContainer from '../../utils/TableActionsContainer'
import ScanPageTableActionFilters from './ScanPageTableActionFilters'
import Button from '../../utils/Button'
import useDrawerStack from '../../hooks/useDrawerStack'
import Icon from '../../utils/Icon'

export const ComparingModeInfoCard = ({ onClose }) => {
    return (
        <div className="flex h-[38px] flex-row items-center gap-3 rounded-lg bg-black pl-4 pr-2 shadow-md">
            <div className="flex items-center gap-2 text-sm text-white">
                <div className="font-semibold">
                    <Trans>Comparing mode:</Trans>
                </div>
                <div>
                    <Trans>
                        You are comparing scans, choose a scan below to compare
                        with the selected scan.
                    </Trans>
                </div>
            </div>
            <button
                type="button"
                className="flex items-center text-white underline"
                onClick={onClose}
                aria-label="Close comparing mode"
            >
                <Icon name="x" color="white" />
            </button>
        </div>
    )
}

const ScanPageTableActions = ({
    audits,
    consentProfiles,
    dateRangeState,
    search,
    activeFilters,
    onSearch,
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
    statusFilter,
    consentProfilesFilter,
    auditsFilter,
    onChangeStatusFilter,
    onChangeConsentProfilesFilter,
    onChangeAuditsFilter,
    onDeleteScans,
}) => {
    const {
        isSelectingRows,
        startSelectingRows,
        stopSelectingRows,
        selectedRows,
        clearSelectedRows,
    } = useTableState()
    const { isComparing, stopComparing } = useDrawerStack()
    const showDefaultActions = !isComparing && !isSelectingRows
    return (
        <TableActionsContainer isSticky>
            {isSelectingRows && (
                <div className="flex flex-row gap-2">
                    <Button
                        type="white"
                        size="sm"
                        onClick={() => stopSelectingRows()}
                    >
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        type="danger"
                        size="sm"
                        onClick={() =>
                            onDeleteScans(selectedRows, clearSelectedRows)
                        }
                    >
                        <Trans>Delete Scan(s)</Trans>
                    </Button>
                </div>
            )}
            {isComparing && (
                <ComparingModeInfoCard onClose={() => stopComparing()} />
            )}
            {showDefaultActions && (
                <div className="flex items-center gap-2">
                    <Button
                        type="white"
                        size="sm"
                        iconBeforeProps={{
                            name: 'check-done-01',
                            size: 'sm',
                        }}
                        onClick={() => startSelectingRows()}
                    />
                    <SearchInput value={search} onChange={onSearch} />
                    <ScanPageTableActionFilters
                        audits={audits}
                        consentProfiles={consentProfiles}
                        activeFilters={activeFilters}
                        onAddFilter={onAddFilter}
                        onRemoveFilter={onRemoveFilter}
                        onRemoveAllFilters={onRemoveAllFilters}
                        statusFilter={statusFilter}
                        consentProfilesFilter={consentProfilesFilter}
                        auditsFilter={auditsFilter}
                        onChangeStatusFilter={onChangeStatusFilter}
                        onChangeConsentProfilesFilter={
                            onChangeConsentProfilesFilter
                        }
                        onChangeAuditsFilter={onChangeAuditsFilter}
                    />
                </div>
            )}
            <div className="flex gap-2">
                <ScanRangeSelect
                    hasEmptyOption
                    onChange={dateRangeState.onChangeDate}
                    period={dateRangeState.selectedPeriod}
                    startDate={dateRangeState.startDate}
                    endDate={dateRangeState.endDate}
                />
            </div>
        </TableActionsContainer>
    )
}

export default ScanPageTableActions
