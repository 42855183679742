import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useCurrentDrawerStack } from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import useSearch from '../../hooks/useSearch'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import DrawerTabError from '../../utils/DrawerTabError'
import InfiniteScroll from '../../utils/InfiniteScroll'
import useScanDetailGroupedDataIdentifierDrawerQuery from './useScanDetailGroupedDataIdentifierDrawerQuery'
import useFilters from '../../hooks/useFilters'
import ScanDetailsDrawerTabGroupedDataIdentifiersTableRow from './ScanDetailsDrawerTabGroupedDataIdentifiersTableRow'

export default function ScanPageDetailsDrawerTabCookies({
    scanId,
    scanPageId,
}) {
    const { add } = useCurrentDrawerStack()
    const [search, handleSearch] = useSearch('')
    const [activeTab, setActiveTab] = useState('all')
    const [typeFilter, onChangeTypeFilter] = useState(null)
    const [purposeFilter, onChangePurposeFilter] = useState(null)
    const [vendorsFilter, onChangeVendorsFilter] = useState([])
    const {
        activeFilters,
        filterValues,
        onRemoveFilter,
        onAddFilter,
        onRemoveAllFilters,
    } = useFilters({
        type: typeFilter,
        purpose: purposeFilter,
        vendors: vendorsFilter,
    })
    const {
        groupedScanDataIdentifiers,
        isFetching: loading,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        error,
    } = useScanDetailGroupedDataIdentifierDrawerQuery({
        scanId,
        scanPageId,
        search,
        filters: { ...filterValues },
    })

    if (error) return <DrawerTabError error={error} />
    return (
        <div className="flex flex-col gap-3">
            {loading && <DrawerTabLoading />}
            {!loading && (
                <InfiniteScroll
                    isFetchingMore={isFetchingMore}
                    onFetchMore={handleFetchMore}
                    hasMoreRecords={hasMore}
                >
                    <Table hasMinWidth={false} isRounded hasHeader={false}>
                        <TableBody>
                            {groupedScanDataIdentifiers.length === 0 ? (
                                <DrawerEmptyCard
                                    iconName="scan"
                                    title={<Trans>Data identifiers</Trans>}
                                    description={
                                        <Trans>
                                            No data identifiers were found
                                        </Trans>
                                    }
                                />
                            ) : (
                                groupedScanDataIdentifiers.map(
                                    (groupedScanDataIdentifier) => {
                                        return (
                                            <ScanDetailsDrawerTabGroupedDataIdentifiersTableRow
                                                key={
                                                    groupedScanDataIdentifier.id
                                                }
                                                groupedScanDataIdentifier={
                                                    groupedScanDataIdentifier
                                                }
                                            />
                                        )
                                    }
                                )
                            )}
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            )}
        </div>
    )
}
