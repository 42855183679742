import React from 'react'
import Avatar from './Avatar'
import Tag from './Tag'
import Tooltip from './Tooltip'
import Icon from './Icon'

const AuditAvatar = ({ audit, hideFavoriteStar = false }) => {
    const {
        type,
        name,
        domains,
        imageUrl,
        isMobile,
        isWeb,
        isFavorite,
        auditBuilds = [],
    } = audit
    let avatarUrl = imageUrl
    if (auditBuilds.length > 0) {
        avatarUrl = auditBuilds[0]?.iconUrl
    }
    return (
        <div className="flex items-center gap-3">
            <Avatar
                iconName={type === 'MOBILE' ? 'app' : 'domain'}
                src={avatarUrl}
            />
            <div>
                <div className="flex items-center gap-2 font-medium">
                    {name}
                    {isFavorite && !hideFavoriteStar && (
                        <Icon
                            fill
                            name="star"
                            size="xs"
                            className="text-yellow-500"
                        />
                    )}
                </div>
                {(() => {
                    if (isMobile && auditBuilds.length > 0) {
                        return (
                            <div className="mt-0.5 flex items-center gap-1">
                                {auditBuilds.map((auditBuild) => (
                                    <Icon
                                        name={auditBuild.deviceOs.toLowerCase()}
                                        size="sm"
                                        className="h-6"
                                    />
                                ))}
                            </div>
                        )
                    }
                    if (isWeb && domains && domains.length > 0) {
                        const [firstDomain, ...nextDomains] = domains
                        return (
                            <div className="flex items-center gap-2">
                                <div className="text-sm text-gray-400">
                                    {domains[0].url}
                                </div>
                                {nextDomains.length > 0 && (
                                    <Tooltip
                                        content={
                                            <ul className="ml-2 list-disc">
                                                {domains.map((domain) => (
                                                    <li>{domain.url}</li>
                                                ))}
                                            </ul>
                                        }
                                    >
                                        <Tag>+{nextDomains.length}</Tag>
                                    </Tooltip>
                                )}
                            </div>
                        )
                    }
                    return null
                })()}
            </div>
        </div>
    )
}

export default AuditAvatar
