/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import { twMerge } from 'tailwind-merge'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import RadioButton from '../../utils/RadioButton'
import BulkUploadFormUploadCard from './BulkUploadFormUploadCard'
import BulkUploadAlertCard from './BulkUploadAlertCard'

const BulkUploadModal = ({
    onDismiss,
    onSubmit,
    onReset,
    formState,
    isValidated,
    alertRecords,
}) => {
    const { values, handleChange, getErrorMessages } = formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-3xl">
            <ModalTitle
                iconName="upload-cloud-02"
                title={<Trans>Bulk Upload</Trans>}
                description={
                    <Trans>
                        Use this action carefully this can cause a lot of
                        problems
                    </Trans>
                }
                onClose={onDismiss}
            />
            <div className="flex flex-col gap-4">
                <FormGroup>
                    <RadioButton.Group>
                        <RadioButton
                            className={twMerge(
                                'items-start rounded-xl',
                                formState.getValue('action') === 'CREATE' &&
                                    'border-2 border-primary-600 bg-white'
                            )}
                            value="CREATE"
                            checked={formState.getValue('action') === 'CREATE'}
                            onChange={() => {
                                formState.handleChange('action', 'CREATE')
                            }}
                        >
                            <div className="ml-2 flex flex-col">
                                <h1 className="text-start text-sm font-medium text-gray-700">
                                    <Trans>Bulk Create</Trans>
                                </h1>
                                <p className="font-regular text-start text-sm text-gray-600">
                                    <Trans>
                                        Upload a csv file to create multiple
                                        records
                                    </Trans>
                                </p>
                            </div>
                        </RadioButton>
                        <RadioButton
                            className={twMerge(
                                'items-start rounded-xl',
                                formState.getValue('action') === 'UPDATE' &&
                                    'border-2 border-primary-600 bg-white'
                            )}
                            value="UPDATE"
                            checked={formState.getValue('action') === 'UPDATE'}
                            onChange={() => {
                                formState.handleChange('action', 'UPDATE')
                            }}
                        >
                            <div className="ml-2 flex flex-col text-gray-700">
                                <h1 className="text-start text-sm font-medium">
                                    <Trans>Bulk Update</Trans>
                                </h1>
                                <p className="font-regular text-start text-sm text-gray-600">
                                    <Trans>
                                        Upload a csv file to update multiple
                                        records
                                    </Trans>
                                </p>
                            </div>
                        </RadioButton>
                    </RadioButton.Group>
                </FormGroup>
                <FormGroup htmlFor="csvFile" className="mb-4">
                    <BulkUploadFormUploadCard
                        inputClassName="hidden"
                        id="csvFile"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        errors={getErrorMessages('csvFile')}
                        onChange={(file) => handleChange('csvFile', file)}
                        onReset={onReset}
                    />
                </FormGroup>
                {alertRecords && (
                    <BulkUploadAlertCard alertRecords={alertRecords} />
                )}
                <div className="flex gap-4 pt-4 sm:flex-row-reverse">
                    <Button
                        disabled={
                            alertRecords && alertRecords.errors.length > 0
                        }
                        className="w-full"
                        onClick={() => {
                            onSubmit()
                        }}
                    >
                        {isValidated ? (
                            <Trans>Upload file</Trans>
                        ) : (
                            <Trans>Check file</Trans>
                        )}
                    </Button>

                    <Button type="white" className="w-full" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default BulkUploadModal
