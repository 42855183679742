import React from 'react'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import AuditSettingsPageLoginProfilesTableRowActionButtonWithState from './AuditSettingsPageLoginProfilesTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment AuditSettingsPageLoginProfileTableRow on LoginProfile {
        id
        name
    }
`

const AuditSettingsPageLoginProfileTableRow = ({
    audit,
    loginProfile,
    onRemoveLoginProfileCompleted,
}) => {
    const { name } = loginProfile
    return (
        <TableRow data-testid="AuditSettingsPageLoginProfileTableRow">
            <TableCell>{name}</TableCell>
            <TableCell className="text-right">
                <AuditSettingsPageLoginProfilesTableRowActionButtonWithState
                    audit={audit}
                    loginProfile={loginProfile}
                    onRemoveLoginProfileCompleted={
                        onRemoveLoginProfileCompleted
                    }
                />
            </TableCell>
        </TableRow>
    )
}

AuditSettingsPageLoginProfileTableRow.FRAGMENT = FRAGMENT

export default AuditSettingsPageLoginProfileTableRow
