import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import UploadScanModalWithState from '../audits-scans/UploadScanModalWithState'
import useStartAuditMutation from './useStartAuditMutation'
import useAuditHeaderQuery from './useAuditHeaderQuery'
import AuditHeader from './AuditHeader'
import useNotifications from '../../hooks/useNotifications'

const AuditHeaderWithState = ({
    title,
    description,
    onScanCompleted,
    onUploadCompleted,
}) => {
    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false)
    const history = useHistory()
    const { dispatchSuccess, dispatchGraphqlError, dispatchWarning } =
        useNotifications()
    const { audit } = useAuditHeaderQuery()
    const [startAudit] = useStartAuditMutation({
        onCompleted: onScanCompleted,
    })
    let startScanWarningMessage = "Can't start a manual scan."
    let canStartManualScan = true
    if (audit.isMobile) {
        const hasScenarios = audit.auditScenarios.length > 0
        const hasBuilds = audit.auditBuilds.length > 0
        if (hasScenarios && hasBuilds) {
            canStartManualScan = true
        } else {
            canStartManualScan = false
            startScanWarningMessage =
                'A build and scenario is required to start a scan.'
        }
    }
    return (
        <>
            <AuditHeader
                title={title}
                description={description}
                audit={audit}
                onStartScan={async () => {
                    if (canStartManualScan) {
                        try {
                            await startAudit()
                            history.push(`/audits/${audit.id}/scans`)
                            dispatchSuccess({
                                message: <Trans>Manual Scan started</Trans>,
                            })
                        } catch (error) {
                            dispatchGraphqlError(error)
                        }
                    } else {
                        dispatchWarning({
                            message: startScanWarningMessage,
                        })
                    }
                }}
                onUploadSession={() => setUploadModalIsOpen(true)}
            />
            {uploadModalIsOpen && (
                <UploadScanModalWithState
                    audit={audit}
                    onDismiss={() => {
                        setUploadModalIsOpen(false)
                    }}
                    onCompleted={() => {
                        onUploadCompleted()
                        history.push(`/audits/${audit.id}/scans`)
                    }}
                />
            )}
        </>
    )
}

export default AuditHeaderWithState
