import React, { useState } from 'react'
import useSearch from '../../hooks/useSearch'
import useRefetch from '../../hooks/useRefetch'
import AuditsPage from './AuditsPage'
import useAuditsPageQuery from './useAuditsPageQuery'
import { CreateAuditModal } from './CreateAuditModal'

const AuditsPageWithState = () => {
    const [search, handleSearch] = useSearch('')
    const [selectedListStyle, setSelectedListStyle] = useState('GRID')
    const [selectedTag, setSelectedTag] = useState('ALL')
    const [selectedType, setSelectedType] = useState('ALL')
    const [isCreateAuditModalOpen, setIsCreateAuditModalOpen] = useState(false)
    const {
        auditTags,
        audits,
        myFavoriteAudits,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useAuditsPageQuery({
        search,
        tag: selectedTag,
        type: selectedType,
    })
    useRefetch(refetch)
    return (
        <>
            {isCreateAuditModalOpen && (
                <CreateAuditModal
                    onDismiss={() => setIsCreateAuditModalOpen(false)}
                />
            )}
            <AuditsPage
                selectedListStyle={selectedListStyle}
                selectedTag={selectedTag}
                selectedType={selectedType}
                audits={audits}
                myFavoriteAudits={myFavoriteAudits}
                tags={auditTags}
                search={search}
                isFetching={isFetching}
                isFetchingMore={isFetchingMore}
                onFetchMore={handleFetchMore}
                hasMoreRecords={hasMore}
                onMarkAsFavoriteCompleted={() => refetch()}
                onDeleteAuditCompleted={() => refetch()}
                onSearch={handleSearch}
                onChangeTag={(tag) => setSelectedTag(tag)}
                onChangeListStyle={(style) => setSelectedListStyle(style)}
                onChangeSelectedType={(type) => setSelectedType(type)}
                onCreateAudit={() => setIsCreateAuditModalOpen(true)}
            />
        </>
    )
}

export default AuditsPageWithState
