import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import { DrawerDetailsItemText } from '../../utils/DrawerDetailsItem'
import PurposesTags from '../../utils/PurposesTags'
import Tag from '../../utils/Tag'
import Avatar from '../../utils/Avatar'

export default function IdentifiedScanTrackerDetailsDrawerTabDetails({
    identifiedScanTracker,
}) {
    if (!identifiedScanTracker) {
        return (
            <DrawerEmptyCard
                iconName="scan"
                title={<Trans>No Information</Trans>}
                description={<Trans>There was no tracker find. </Trans>}
            />
        )
    }

    const { tracker, lastSeenAt, audits, lastScanRequest } =
        identifiedScanTracker
    return (
        <div className="flex flex-col gap-4">
            <DrawerDetailsItemText isHorizontal label={<Trans>Audit</Trans>}>
                <div className="flex items-center gap-2">
                    <Avatar size="xs" src={audits[0]?.imageUrl} />
                    {audits[0]?.name}
                </div>
            </DrawerDetailsItemText>
            <DrawerDetailsItemText
                isHorizontal
                label={<Trans>Resourcetype</Trans>}
            >
                <Tag>{lastScanRequest?.resourceType || 'Unknown'}</Tag>
            </DrawerDetailsItemText>
            <DrawerDetailsItemText
                isHorizontal
                truncate={false}
                label={<Trans>Description</Trans>}
            >
                {tracker?.description || 'No description provided'}
            </DrawerDetailsItemText>
            <DrawerDetailsItemText isHorizontal label={<Trans>Purposes</Trans>}>
                {tracker?.purposes ? (
                    <PurposesTags showAll purposes={tracker.purposes} />
                ) : (
                    <Tag>Unclassified</Tag>
                )}
            </DrawerDetailsItemText>
            <DrawerDetailsItemText isHorizontal label={<Trans>Domain</Trans>}>
                {tracker?.domain}
            </DrawerDetailsItemText>
            <DrawerDetailsItemText
                isHorizontal
                label={<Trans>Last Observed</Trans>}
            >
                {new Date(`${lastSeenAt}`).toLocaleString()}
            </DrawerDetailsItemText>
        </div>
    )
}
