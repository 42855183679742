/* eslint-disable max-len */
import React from 'react'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import { useMutation, useQuery } from '@apollo/client'
import useNotifications from '../../hooks/useNotifications'
import CreateAuditScenarioModal from './CreateAuditScenarioModal'
import useCreateAuditScenarioFormState from './useCreateAuditScenarioFormState'

const MUTATION = gql`
    mutation createAuditScenario(
        $auditId: ID!
        $input: CreateAuditScenarioInput!
    ) {
        createAuditScenario(auditId: $auditId, input: $input) {
            auditScenario {
                id
            }
        }
    }
`

const useCreateAuditScenario = (options) => useMutation(MUTATION, options)

const QUERY = gql`
    query createAuditScenarioModalQuery {
        purposes {
            id
            externalId
            name
        }
    }
`

const useCreateAuditScenarioModalQuery = (options) => {
    const {
        data = { audit: {} },
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    return {
        error,
        purposes: data.purposes || [],
        isFetching,
        refetch,
    }
}

const CreateAuditScenarioModalWithState = ({
    audit,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const history = useHistory()
    const { purposes } = useCreateAuditScenarioModalQuery()
    const [createAuditScenario] = useCreateAuditScenario({
        onCompleted,
        onError,
    })
    const formState = useCreateAuditScenarioFormState(purposes)
    const { dispatchSuccess, dispatchError } = useNotifications()
    return (
        <CreateAuditScenarioModal
            purposes={purposes}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const {
                            name,
                            deviceOs,
                            consentType,
                            purposeIds,
                            actions,
                        } = formState.values
                        const input = {
                            name,
                            deviceOs,
                            actions,
                            consentType,
                            purposeIds,
                        }
                        await createAuditScenario({
                            variables: { auditId: audit.id, input },
                        })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    New audit scenario {name} created!
                                </Trans>
                            ),
                        })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
        />
    )
}

export default CreateAuditScenarioModalWithState
