import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import Button from './Button'
import StaffFeedbackModal from './StaffFeedbackModal'
import useNotifications from '../hooks/useNotifications'

const MUTATION = gql`
    mutation ($feedbackId: ID!, $score: Int!) {
        staffLogFeedback(feedbackId: $feedbackId, score: $score)
    }
`

const StaffFeedbackButtons = ({ feedbackId, onCompleted, onError }) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [badFeedbackModelIsOpen, setBadFeedbackModelIsOpen] = useState()
    const [sendFeedback, { loading: isSending }] = useMutation(MUTATION, {
        onCompleted,
        onError,
    })
    return (
        <>
            {badFeedbackModelIsOpen && (
                <StaffFeedbackModal
                    feedbackId={feedbackId}
                    onDismiss={() => setBadFeedbackModelIsOpen(false)}
                    onCompleted={onCompleted}
                    onError={onError}
                />
            )}
            <div className="flex items-center gap-2">
                <Button
                    type="white"
                    loading={isSending}
                    iconBeforeProps={{
                        size: 'sm',
                        name: 'check',
                        color: 'green',
                    }}
                    onClick={async () => {
                        try {
                            await sendFeedback({
                                variables: { feedbackId, score: 10 },
                            })
                            dispatchSuccess({
                                message: (
                                    <Trans>Successfully sent feedback!</Trans>
                                ),
                            })
                        } catch (e) {
                            dispatchGraphqlError(e)
                        }
                    }}
                >
                    <Trans>Good</Trans>
                </Button>
                <Button
                    type="white"
                    loading={isSending}
                    iconBeforeProps={{
                        size: 'sm',
                        name: 'x',
                        color: 'red',
                    }}
                    onClick={() => {
                        setBadFeedbackModelIsOpen(true)
                    }}
                >
                    <Trans>Bad</Trans>
                </Button>
            </div>
        </>
    )
}

export default StaffFeedbackButtons
