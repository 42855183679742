import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function AuditScanPageTableHeaderRow({ audit, ...props }) {
    return (
        <TableHeaderRow {...props}>
            {audit.isWeb && (
                <TableHeaderCell columnId="domain" initialWidth={200}>
                    <Trans>Domain</Trans>
                </TableHeaderCell>
            )}
            {audit.isMobile && (
                <TableHeaderCell columnId="name" initialWidth={200}>
                    <Trans>Name</Trans>
                </TableHeaderCell>
            )}
            <TableHeaderCell columnId="alertsCount" initialWidth={100}>
                <Trans>Alerts</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="consentProfile" initialWidth={120}>
                <Trans>Scenario</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="status" initialWidth={100}>
                <Trans>Status</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="createdAt" initialWidth={200}>
                <Trans>Created</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="duration" initialWidth={200}>
                <Trans>Duration</Trans>
            </TableHeaderCell>
            <TableHeaderCell />
        </TableHeaderRow>
    )
}
