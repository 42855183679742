import React from 'react'
import clsx from 'clsx'
import Avatar from './Avatar'
import ButtonLink from './ButtonLink'
import PopoverVendorInfo from './PopoverVendorInfo'
import ModalCloseButton from './ModalCloseButton'
// From here of on the "newModalTitle" imports start
import { PurposesTag } from './PurposesTags'
import InfoIcon from './InfoIcon'

const ModalTitle = ({
    title,
    isDisabled,
    description,
    descriptionClassName,
    onClose,
    iconName,
    iconColor = 'default',
    iconSize = 'lg',
    purpose,
    iconTheme = 'modern',
}) => (
    <>
        <ModalCloseButton onClick={onClose} disabled={isDisabled} />
        <div className="-mx-8 mb-6 border-b border-gray-200 px-8 pb-4">
            <div className="flex items-center gap-4">
                {iconName && (
                    <InfoIcon
                        iconName={iconName}
                        size={iconSize}
                        color={iconColor}
                        theme={iconTheme}
                    />
                )}
                <div>
                    <div className="flex items-center">
                        <h3
                            className="text-lg font-medium leading-6 text-gray-900"
                            id="modal-headline"
                        >
                            {title}
                        </h3>
                        {purpose && <PurposesTag purpose={purpose} />}
                    </div>
                    {description && (
                        <p
                            className={clsx(
                                'mt-1 line-clamp-1 text-sm leading-5 text-gray-500',
                                descriptionClassName
                            )}
                        >
                            {description}
                        </p>
                    )}
                </div>
            </div>
        </div>
    </>
)

export default ModalTitle
