import gql from 'graphql-tag'
import { useQuery } from '@apollo/client/index'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query scanDataSinksDrawer($scanPageId: ID, $search: String, $offset: Int) {
        scanDataSinks(
            scanPageId: $scanPageId
            search: $search
            offset: $offset
        ) {
            edges {
                cursor
                node {
                    id
                    dataSink {
                        id
                        name
                    }
                }
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export default function useScanPageDetailDataSinksDrawerQuery({
    scanPageId,
    search,
    filters,
}) {
    const variables = { scanPageId, search }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'scanDataSinks'
    )

    return {
        error,
        scanDataSinks: connectionToCollection(data.scanDataSinks),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}
