import gql from 'graphql-tag'
import { useQuery } from '@apollo/client/index'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query scanDetailGroupedDataIdentifierDrawer(
        $scanId: ID
        $scanPageId: ID
        $search: String
        $offset: Int
    ) {
        groupedScanDataIdentifiers(
            scanId: $scanId
            scanPageId: $scanPageId
            search: $search
            offset: $offset
        ) {
            edges {
                cursor
                node {
                    id
                    type
                    scanRequests {
                        id
                        url
                        postData
                        tracker {
                            id
                            vendor {
                                id
                                imageUrl
                            }
                        }
                    }
                    dataIdentifier {
                        id
                        name
                        patterns
                    }
                }
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export default function useScanPageDetailDataIdentifierDrawerQuery({
    scanId,
    scanPageId,
    search,
    filters,
}) {
    const variables = { scanId, scanPageId, search }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'groupedScanDataIdentifiers'
    )

    return {
        error,
        groupedScanDataIdentifiers: connectionToCollection(
            data.groupedScanDataIdentifiers
        ),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}
