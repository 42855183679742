import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import Tag from '../../utils/Tag'
import Table from '../../utils/Table'
import Duration from '../../utils/Duration'
import Tooltip from '../../utils/Tooltip'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import { useCurrentDrawerStack } from '../../hooks/useDrawerStack'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import DrawerTabError from '../../utils/DrawerTabError'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import AlertCountTag from '../../utils/AlertCountTag'
import { getPageStatusColor } from '../../../utils/entities'
import * as constants from '../../../constants'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import ScanPageDetailsDrawer from './ScanPageDetailsDrawer'
import useEvents from '../../hooks/useEvents'
import InfiniteScroll from '../../utils/InfiniteScroll'

const { SCAN_PAGE_STATUS_FAILED: FAILED } = constants

const QUERY = gql`
    query scanDetailsDrawerTabPages(
        $scanId: ID!
        $after: String
        $orderBy: ScanPagesOrderByInput
    ) {
        scanPages(scanId: $scanId, after: $after, orderBy: $orderBy) {
            edges {
                node {
                    id
                    url
                    status
                    duration
                    errorMessages
                    dataIdentifiersCount
                    dataSinksCount
                    alerts {
                        id
                        severityType
                    }
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export default function ScanDetailsDrawerTabPages({ scanId }) {
    const orderBy = { createdAt: 'ASC' }
    const variables = { scanId, orderBy }
    const { add } = useCurrentDrawerStack()
    const { data, loading, error, refetch, fetchMore } = useQuery(QUERY, {
        variables,
    })
    useEvents(
        ['scan:page:in-progress', 'scan:page:completed', 'scan:page:failed'],
        ({ scanPage }) => {
            if (scanPage && scanPage.scanId) {
                refetch()
            }
        }
    )
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'scanPages'
    )
    if (loading) return <DrawerTabLoading />
    if (error) return <DrawerTabError error={error} />
    const pages = connectionToCollection(data.scanPages)
    const hasMore = !loading && !error && hasNextPage

    if (pages.length === 0) {
        return (
            <DrawerEmptyCard
                iconName="scan"
                title={<Trans>No pages</Trans>}
                description={
                    <Trans>No pages were scanned during this scan. </Trans>
                }
            />
        )
    }
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
        >
            <Table hasMinWidth={false} isRounded hasHeader={false}>
                <TableBody>
                    {pages.map(
                        ({
                            id,
                            url,
                            status,
                            errorMessages,
                            duration,
                            alerts,
                            dataIdentifiersCount,
                            dataSinksCount,
                        }) => (
                            <TableRow
                                isSelectable
                                key={id}
                                onClick={() => {
                                    add(ScanPageDetailsDrawer, {
                                        scanPageId: id,
                                    })
                                }}
                            >
                                <TableCell py="sm" truncate className="w-2/3">
                                    <Tooltip
                                        event="hover"
                                        position="bottom-end"
                                        content={
                                            <div className="max-w-[400px] overflow-hidden break-words">
                                                {url}
                                            </div>
                                        }
                                        contentClassName="max-w-[400px]"
                                    >
                                        {new URL(url).pathname}
                                    </Tooltip>
                                </TableCell>
                                <TableCell py="sm">
                                    <div className="flex justify-end gap-1">
                                        <AlertCountTag alerts={alerts} />
                                        <Tag>
                                            {dataIdentifiersCount +
                                                dataSinksCount}
                                        </Tag>
                                        <Tooltip
                                            event="hover"
                                            enabled={status === FAILED}
                                            content={errorMessages.join(' ')}
                                        >
                                            <Tag
                                                type={getPageStatusColor(
                                                    status
                                                )}
                                                className="capitalize"
                                            >
                                                {status
                                                    .toLowerCase()
                                                    .replace('_', ' ')}
                                            </Tag>
                                        </Tooltip>
                                        <Tooltip
                                            event="hover"
                                            enabled
                                            content={<Trans>Duration</Trans>}
                                        >
                                            <Tag
                                                iconNameBefore="clock"
                                                type="gray"
                                            >
                                                <Duration format="secs-short">
                                                    {duration}
                                                </Duration>
                                            </Tag>
                                        </Tooltip>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}
