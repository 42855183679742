import React from 'react'
import { Trans } from '@lingui/macro'
import AuditHeaderWithState from './AuditHeaderWithState'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import PageContent from '../../utils/PageContent'
import AuditDetailsPageCard from './AuditDetailsPageCard'
import AuditDetailsPageScansTable from './AuditDetailsPageScansTable'
import AuditDetailsPageAlertsChartWithState from './AuditDetailsPageAlertsChartWithState'
import AuditHeader from '../../layout/AuditHeader'
import TableStateProvider from '../../providers/TableStateProvider'

const AuditDetailsPage = ({ audit, scans, onScanCompleted, isFetching }) => (
    <PageContainer data-testid="AuditDetailsPage">
        <AuditHeader />
        <SidebarContainer>
            <PageContent isFetching={isFetching}>
                <AuditHeaderWithState
                    title={<Trans>Overview</Trans>}
                    description={
                        <Trans>
                            Here you can find a brief overview of the current
                            audit.
                        </Trans>
                    }
                    onScanCompleted={onScanCompleted}
                />
                {(() => {
                    if (isFetching) {
                        return null
                    }
                    return (
                        <>
                            <h3 className="mb-3 mt-6 font-semibold">
                                <Trans>Latest observations</Trans>
                            </h3>
                            <AuditDetailsPageCard audit={audit} />
                            <h3 className="mb-3 font-semibold">
                                <Trans>Alerts overview</Trans>
                            </h3>
                            <AuditDetailsPageAlertsChartWithState />
                            <>
                                <h3 className="mb-3 font-semibold">
                                    <Trans>Recent scans</Trans>
                                </h3>
                                <TableStateProvider>
                                    <AuditDetailsPageScansTable
                                        audit={audit}
                                        scans={scans}
                                    />
                                </TableStateProvider>
                            </>
                        </>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default AuditDetailsPage
