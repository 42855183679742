import React from 'react'
import { Trans } from '@lingui/macro'
import { atob, isBase64Encoded } from '../../../utils/index'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import { DrawerDetailsItemText } from '../../utils/DrawerDetailsItem'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import RequestParamTableRow from '../../utils/RequestParamTableRow'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import Avatar from '../../utils/Avatar'

export default function IdentifiedTrackersDrawerTabDetails({
    identifiedTracker,
}) {
    if (!identifiedTracker) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No details</Trans>}
                description={
                    <Trans>
                        There were no details found for this Identifiedtracker
                    </Trans>
                }
            />
        )
    }
    const { lastSeenAt, lastScanRequest, tracker } = identifiedTracker
    let url = ''
    if (lastScanRequest?.url !== undefined) {
        url = new URL(lastScanRequest?.url)
    }
    const {
        hostname: lastScanRequestHostname,
        pathname: lastScanRequestPathname,
    } = url
    const decodedSearchParams = Array.from(url?.searchParams?.entries()).map(
        ([key, value]) => [key, isBase64Encoded(value) ? atob(value) : value]
    )

    return (
        <div className="flex flex-col gap-4">
            {tracker && tracker.vendor && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Vendor</Trans>}
                >
                    <div className="flex items-center gap-2">
                        <Avatar
                            size="xs"
                            iconName="building-07"
                            src={tracker.vendor.imageUrl}
                        />
                        {tracker.vendor.name}
                    </div>
                </DrawerDetailsItemText>
            )}
            <DrawerDetailsItemText
                isHorizontal
                label={<Trans>Last Observed</Trans>}
            >
                <DateTime>{lastSeenAt}</DateTime>
            </DrawerDetailsItemText>
            <DrawerDetailsItemText isHorizontal label={<Trans>Method</Trans>}>
                {lastScanRequest.method}
            </DrawerDetailsItemText>
            <DrawerDetailsItemText isHorizontal label={<Trans>URL</Trans>}>
                {lastScanRequestHostname + lastScanRequestPathname}
            </DrawerDetailsItemText>
            {lastScanRequest.initiator && lastScanRequest.initiator.url && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Initiator Url</Trans>}
                >
                    {lastScanRequest.initiator.url}
                </DrawerDetailsItemText>
            )}
            {lastScanRequest.initiator && lastScanRequest.initiator.type && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Initiator Type</Trans>}
                >
                    <Tag>{lastScanRequest.initiator.type}</Tag>
                </DrawerDetailsItemText>
            )}
            {lastScanRequest.initiator &&
                lastScanRequest.initiator.urls &&
                lastScanRequest.initiator.urls.length > 0 && (
                    <DrawerDetailsItemText
                        isHorizontal
                        label={<Trans>Initiator Script</Trans>}
                    >
                        <p className="break-all">
                            {lastScanRequest.initiator.urls[0]}
                        </p>
                    </DrawerDetailsItemText>
                )}
            {lastScanRequest.cookies && lastScanRequest.cookies.length > 0 && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Initiator Script</Trans>}
                >
                    <div className="gap-2">
                        {lastScanRequest.cookies.map((cookie) => (
                            <Tag className="mx-1" type="primary">
                                {cookie.name}
                            </Tag>
                        ))}
                    </div>
                </DrawerDetailsItemText>
            )}
            {lastScanRequest.search && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Search Params</Trans>}
                >
                    <Table hasMinWidth={false} isRounded hasHeader={false}>
                        <TableBody>
                            {decodedSearchParams.map(([key, value]) => (
                                <RequestParamTableRow
                                    paramName={key}
                                    paramValue={value}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </DrawerDetailsItemText>
            )}
        </div>
    )
}
