import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const ORGANIZATION_ALERT_SUMMARY_QUERY = gql`
    query organizationAlertSummaryQuery(
        $groupingId: ID!
        $filters: AlertFilters
    ) {
        alert(groupingId: $groupingId, filters: $filters) {
            id
            type
            subjectType
            severityType
            message
            subjectName
            lastCreatedAt
            cookie {
                id
                name
                description
                domain
                belongsToOrganization
                purposes {
                    id
                    name
                }
                vendor {
                    id
                    name
                }
            }
            tracker {
                id
                domain
                belongsToOrganization
                purposes {
                    id
                    name
                }
                vendor {
                    id
                    name
                }
            }
            lastAlertsForAudit {
                id
                audit {
                    name
                    imageUrl
                    domains {
                        url
                    }
                }
                scanCookie {
                    id
                    type
                    rootDomain
                    name
                    value
                    retention
                    expiresAt
                    request
                    blockedReasons
                    scanPage {
                        url
                    }
                    audit {
                        imageUrl
                        name
                    }
                    scan {
                        startedAt
                        consentProfile {
                            name
                        }
                    }
                    scanRequest {
                        id
                        method
                        audit {
                            id
                            name
                            imageUrl
                        }
                        scan {
                            id
                            startedAt
                            consentProfile {
                                name
                            }
                        }
                        scanPage {
                            url
                        }
                        initiatorScanRequests {
                            id
                            url
                            tracker {
                                vendor {
                                    imageUrl
                                }
                            }
                        }
                        downstreamScanRequests {
                            id
                            url
                            tracker {
                                vendor {
                                    imageUrl
                                }
                            }
                        }
                        method
                        resourceType
                        initiator
                        rootDomain
                        tracker {
                            vendor {
                                imageUrl
                            }
                        }
                        url
                    }
                }
                scanRequest {
                    id
                    method
                    resourceType
                    initiator
                    rootDomain
                    url
                    tracker {
                        vendor {
                            imageUrl
                        }
                    }
                    audit {
                        id
                        name
                        imageUrl
                    }
                    scan {
                        id
                        startedAt
                        consentProfile {
                            name
                        }
                    }
                    scanPage {
                        url
                    }
                    initiatorScanRequests {
                        id
                        url
                        tracker {
                            vendor {
                                imageUrl
                            }
                        }
                    }
                    downstreamScanRequests {
                        id
                        url
                        tracker {
                            vendor {
                                imageUrl
                            }
                        }
                    }
                }
            }
            audits {
                id
                name
                imageUrl
                consentProfiles {
                    name
                }
                latestAlerts {
                    lastScanCookies {
                        id
                        name
                    }
                }
                domains {
                    id
                    url
                }
            }
            vendor {
                id
                name
                types
                description
                imageUrl
                belongsToOrganization
            }
            lastScanRequest {
                id
                method
                resourceType
                initiator
                rootDomain
                url
                tracker {
                    vendor {
                        imageUrl
                    }
                }
                audit {
                    id
                    name
                    imageUrl
                    domains {
                        url
                    }
                }
                scan {
                    id
                    startedAt
                    consentProfile {
                        name
                    }
                    consentSettings {
                        name
                    }
                }
                scanPage {
                    url
                }
                initiatorScanRequests {
                    id
                    url
                    tracker {
                        id
                        vendor {
                            imageUrl
                        }
                    }
                }
                downstreamScanRequests {
                    id
                    url
                    tracker {
                        id
                        vendor {
                            imageUrl
                        }
                    }
                }
            }
            lastScanCookie {
                id
                type
                rootDomain
                name
                value
                retention
                expiresAt
                request
                blockedReasons
                scanPage {
                    url
                }
                audit {
                    imageUrl
                    name
                }
                scan {
                    startedAt
                    consentProfile {
                        name
                    }
                }
                scanRequest {
                    id
                    method
                    audit {
                        id
                        name
                        imageUrl
                    }
                    scan {
                        id
                        startedAt
                        consentProfile {
                            name
                        }
                    }
                    scanPage {
                        url
                    }
                    initiatorScanRequests {
                        id
                        url
                        tracker {
                            vendor {
                                imageUrl
                            }
                        }
                    }
                    downstreamScanRequests {
                        id
                        url
                        tracker {
                            vendor {
                                imageUrl
                            }
                        }
                    }
                    method
                    resourceType
                    initiator
                    rootDomain
                    tracker {
                        vendor {
                            imageUrl
                        }
                    }
                    url
                }
            }
        }
    }
`

const useOrganizationAlertSummaryQuery = ({ filters }) => {
    const { groupingId, startDate, endDate } = filters
    const variables = {
        groupingId,
        filters: {
            startDate,
            endDate,
        },
    }

    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(ORGANIZATION_ALERT_SUMMARY_QUERY, {
        variables,
        fetchPolicy: 'network-only',
    })

    return {
        error,
        alert: data.alert || {},
        lastScanCookie: data.alert?.lastScanCookie || null,
        lastScanRequest: data.alert?.lastScanRequest || null,
        isFetching,
        refetch,
    }
}

export default useOrganizationAlertSummaryQuery
