import React from 'react'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import AuditSettingsPageAuditScenarioTableRowActionButtonWithState from './AuditSettingsPageAuditScenarioTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment AuditSettingsPageAuditScenarioTableRow on AuditScenario {
        id
        name
        deviceOs
    }
`

const AuditSettingsPageAuditScenarioTableRow = ({
    auditScenario,
    onDeleteAuditScenario,
}) => {
    const { name, deviceOs } = auditScenario
    return (
        <TableRow data-testid="AuditSettingsPageAuditScenarioTableRow">
            <TableCell>{name}</TableCell>
            <TableCell>
                <Tag className="capitalize">{deviceOs.toLowerCase()}</Tag>
            </TableCell>
            <TableCell className="text-right">
                <AuditSettingsPageAuditScenarioTableRowActionButtonWithState
                    auditScenario={auditScenario}
                    onDeleteAuditScenarioCompleted={onDeleteAuditScenario}
                />
            </TableCell>
        </TableRow>
    )
}

AuditSettingsPageAuditScenarioTableRow.FRAGMENT = FRAGMENT

export default AuditSettingsPageAuditScenarioTableRow
