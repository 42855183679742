import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import StaffDataIdentifiersPageTableRow from './StaffDataIdentifiersPageTableRow'

const QUERY = gql`
    query staffDataIdentifiersPage($search: String, $offset: Int) {
        staffDataIdentifiers(search: $search, offset: $offset) {
            edges {
                cursor
                node {
                    ...StaffDataIdentifiersPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${StaffDataIdentifiersPageTableRow.FRAGMENT}
`

export default function useStaffDataIdentifiersPageQuery({ search }) {
    const variables = { search }
    const {
        data = {},
        error,
        fetchMore,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore] = useFetchMore(
        fetchMore,
        data,
        'staffDataIdentifiers'
    )
    if (error) {
        return { error }
    }
    if (isFetching) {
        return {
            dataIdentifiers: [],
            isFetching,
        }
    }
    return {
        dataIdentifiers: connectionToCollection(data.staffDataIdentifiers),
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore:
            !isFetching &&
            !error &&
            data.staffDataIdentifiers.pageInfo.hasNextPage,
    }
}
