/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import AuditSearchSelect from '../../utils/AuditSearchSelect'
import ModalFooter from '../../utils/ModalFooter'
import FormSelectList from '../../utils/FormSelectList'
import SelectedAvatarGroup from '../../utils/SelectedAvatarGroup'

const CreateCookieTableModal = ({
    loading,
    formState,
    selectedAudits,
    onDismiss,
    onSubmit,
    onAddAudit,
    onRemoveAudit,
}) => {
    const { getErrorMessages, getNativeInputProps } = formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-2xl">
            <ModalTitle
                title={<Trans>Create new cookie table</Trans>}
                description={
                    <Trans>{selectedAudits.length} audits selected</Trans>
                }
                iconName="cookie"
                iconColor="none"
                iconSize="md"
                onClose={onDismiss}
            />
            <FormGroup
                label={<Trans>Name</Trans>}
                className="mb-4"
                isHorizontal
            >
                <FormInput
                    disabled={loading}
                    errors={getErrorMessages('name')}
                    {...getNativeInputProps('name')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Selected Audits</Trans>}
                className="mb-4"
                isHorizontal
            >
                <FormSelectList
                    errors={getErrorMessages('audits')}
                    selectedItems={
                        <SelectedAvatarGroup selectedItems={selectedAudits} />
                    }
                >
                    <AuditSearchSelect
                        className="h-[400px]"
                        value={selectedAudits.map(({ id }) => id)}
                        onSelect={(id, audit) => onAddAudit(audit)}
                        onDeselect={(id) => onRemoveAudit(id)}
                        selectedAudits={selectedAudits}
                    />
                </FormSelectList>
            </FormGroup>
            <ModalFooter>
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button onClick={onSubmit} loading={loading}>
                    <Trans>Create</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default CreateCookieTableModal
