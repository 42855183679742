import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import { DEVICE_OS_IOS, DEVICE_OS_ANDROID } from '@clew/shared/src/constants'
import Table from '../../utils/Table'
import AlertTag from '../../utils/AlertTag'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import DrawerTabError from '../../utils/DrawerTabError'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import Tag from '../../utils/Tag'

const NORMAL_PERMISSIONS = [
    'ACCESS_NETWORK_STATE',
    'ACCESS_WIFI_STATE',
    'BLUETOOTH',
    'INTERNET',
    'NFC',
    'VIBRATE',
    'WAKE_LOCK',
]

const DANGEROUS_PERMISSIONS = [
    'POST_NOTIFICATIONS',
    'ACCESS_FINE_LOCATION',
    'ACCESS_COARSE_LOCATION',
    'READ_EXTERNAL_STORAGE',
    'WRITE_EXTERNAL_STORAGE',
    'READ_CONTACTS',
    'WRITE_CONTACTS',
    'READ_CALENDAR',
    'WRITE_CALENDAR',
    'CAMERA',
    'RECORD_AUDIO',
    'READ_PHONE_STATE',
    'CALL_PHONE',
    'READ_CALL_LOG',
    'WRITE_CALL_LOG',
    'SEND_SMS',
    'RECEIVE_SMS',
    'READ_SMS',
    'BODY_SENSORS',
    'FOREGROUND_SERVICE',
]

const AD_PERMISSIONS = [
    'AD_ID',
    'ACCESS_ADSERVICES_ATTRIBUTION',
    'ACCESS_ADSERVICES_AD_ID',
    'AD_SERVICES_CONFIG',
    'ACCESS_ADSERVICES_TOPICS',
]

const SPECIAL_PERMISSIONS = ['SYSTEM_ALERT_WINDOW', 'WRITE_SETTINGS']

// self curated
const IOS_DANGEROUS_PERMISSIONS = [
    'NSCameraUsageDescription',
    'NSMicrophoneUsageDescription',
    'NSPhotoLibraryUsageDescription',
    'NSPhotoLibraryAddUsageDescription',
    'NSLocationWhenInUseUsageDescription',
    'NSLocationAlwaysUsageDescription',
    'NSLocationAlwaysAndWhenInUseUsageDescription',
    'NSContactsUsageDescription',
    'NSCalendarsUsageDescription',
    'NSRemindersUsageDescription',
    'NSSpeechRecognitionUsageDescription',
    'NSHealthShareUsageDescription',
    'NSHealthUpdateUsageDescription',
    'NSMotionUsageDescription',
    'NSFaceIDUsageDescription',
    'NSHomeKitUsageDescription',
    'NSAppleMusicUsageDescription',
    'NSBluetoothPeripheralUsageDescription',
    'NSUserTrackingUsageDescription',
]

const QUERY = gql`
    query scanDetailsDrawerTabAuditBuild($id: ID!) {
        scan(id: $id) {
            id
            auditBuild {
                id
                deviceOs
                metaData
            }
        }
    }
`

export default function ScanDetailsDrawerTabAuditBuild({ scanId }) {
    const variables = { id: scanId }
    const { data, loading, error } = useQuery(QUERY, {
        variables,
    })
    if (loading) return <DrawerTabLoading />
    if (error) return <DrawerTabError error={error} />

    const { auditBuild } = data.scan
    if (!auditBuild || auditBuild.length === 0) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No build details</Trans>}
                description={<Trans>No build details were found.</Trans>}
            />
        )
    }
    const { deviceOs, metaData } = auditBuild
    if (!metaData) {
        return null
    }
    const deviceMeta = metaData[deviceOs.toLowerCase()]
    return (
        <div>
            <div>
                <h3 className="mb-4 text-sm font-medium text-slate-900">
                    <Trans>Permissions</Trans>
                </h3>
                {deviceOs === DEVICE_OS_IOS && deviceMeta && (
                    <Table hasMinWidth={false} isRounded hasHeader={false}>
                        <TableBody>
                            {Object.entries(deviceMeta.UsageDescriptions)?.map(
                                ([key, value]) => {
                                    const isDangerous =
                                        IOS_DANGEROUS_PERMISSIONS.some(
                                            (value) => key.includes(value)
                                        )
                                    return (
                                        <TableRow>
                                            <TableCellAvatar
                                                py="xs"
                                                px="sm"
                                                title={key}
                                                text={value}
                                            />
                                            <TableCell
                                                py="xs"
                                                className="text-right"
                                            >
                                                {isDangerous && (
                                                    <Tag type="yellow">
                                                        Dangerous
                                                    </Tag>
                                                )}
                                                {!isDangerous && (
                                                    <Tag type="green">
                                                        Normal
                                                    </Tag>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            )}
                        </TableBody>
                    </Table>
                )}
                {deviceOs === DEVICE_OS_ANDROID && deviceMeta && (
                    <Table hasMinWidth={false} isRounded hasHeader={false}>
                        <TableBody>
                            {deviceMeta.permissions?.map((permission) => {
                                const isDangerous = DANGEROUS_PERMISSIONS.some(
                                    (value) => permission.includes(value)
                                )
                                const isSpecial = SPECIAL_PERMISSIONS.some(
                                    (value) => permission.includes(value)
                                )
                                const isAd = AD_PERMISSIONS.some((value) =>
                                    permission.includes(value)
                                )
                                const isNormal = NORMAL_PERMISSIONS.some(
                                    (value) => permission.includes(value)
                                )
                                return (
                                    <TableRow>
                                        <TableCell py="xs">
                                            {permission}
                                        </TableCell>
                                        <TableCell
                                            py="xs"
                                            className="text-right"
                                        >
                                            {isDangerous && (
                                                <Tag type="yellow">
                                                    Dangerous
                                                </Tag>
                                            )}
                                            {isAd && (
                                                <Tag type="blue">
                                                    Advertising
                                                </Tag>
                                            )}
                                            {isSpecial && (
                                                <Tag type="red">Special</Tag>
                                            )}
                                            {isNormal && (
                                                <Tag type="green">Normal</Tag>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                )}
            </div>
        </div>
    )
}
