import React from 'react'
import SearchInput from '../../utils/SearchInput'
import TableActionsContainer from '../../utils/TableActionsContainer'
import Button from '../../utils/Button'

export default function StaffDataIdentifiersPageTableActions({
    search,
    onSearch,
}) {
    return (
        <TableActionsContainer isSticky>
            <div className="flex items-center gap-2">
                <Button
                    type="white"
                    size="sm"
                    iconBeforeProps={{
                        name: 'check-done-01',
                        size: 'sm',
                    }}
                    disabled
                />
                <SearchInput value={search} onChange={onSearch} />
            </div>
        </TableActionsContainer>
    )
}
