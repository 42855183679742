import React from 'react'
import { Trans } from '@lingui/macro'
import { AUDIT_TYPE_MOBILE, AUDIT_TYPE_WEB } from '@clew/shared/src/constants'
import Select from '../../utils/Select'
import SearchInput from '../../utils/SearchInput'
import TableActionsContainer from '../../utils/TableActionsContainer'
import AuditsPageActionsViewButtonGroup from './AuditsPageActionsViewButtonGroup'
import AnimatedTabs, { AnimatedTab } from '../../utils/AnimatedTabs'

export default function AuditsPageActions({
    selectedType,
    tags,
    search,
    selectedTag,
    selectedListStyle,
    onSearch,
    onChangeTag,
    onChangeListStyle,
    onChangeSelectedType,
}) {
    return (
        <TableActionsContainer>
            <div className="flex items-center gap-2">
                <SearchInput value={search} onChange={onSearch} />
                <AnimatedTabs size="md" activeTab={selectedType}>
                    <AnimatedTab
                        name="ALL"
                        onClick={() => onChangeSelectedType('ALL')}
                    >
                        <Trans>All</Trans>
                    </AnimatedTab>
                    <AnimatedTab
                        name={AUDIT_TYPE_WEB}
                        onClick={() => onChangeSelectedType(AUDIT_TYPE_WEB)}
                    >
                        <Trans>Web</Trans>
                    </AnimatedTab>
                    <AnimatedTab
                        name={AUDIT_TYPE_MOBILE}
                        onClick={() => onChangeSelectedType(AUDIT_TYPE_MOBILE)}
                    >
                        <Trans>Mobile</Trans>
                    </AnimatedTab>
                </AnimatedTabs>
                <Select
                    className="w-[150px]"
                    onChange={(e) => onChangeTag(e.target.value)}
                    value={selectedTag}
                >
                    <option value="ALL">All tags</option>
                    {tags.map((tag) => (
                        <option value={tag}>{tag}</option>
                    ))}
                </Select>
            </div>
            <div>
                <AuditsPageActionsViewButtonGroup
                    value={selectedListStyle}
                    onChange={onChangeListStyle}
                />
            </div>
        </TableActionsContainer>
    )
}
