import React from 'react'
import { Trans } from '@lingui/macro'
import { COOKIE_TABLE_VERSIONS_STATUS_FINISHED } from '@clew/shared/src/constants'
import Button from '../../utils/Button'
import DrawerDetailsItem from '../../utils/DrawerDetailsItem'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerLoading from '../../utils/DrawerLoading'
import useUpdateCookieTablePageQuery from './useUpdateCookieTablePageQuery'
import useUpdateCookieTableFormState from './useUpdateCookieTableFormState'
import useUpdateCookieTableMutation from './useUpdateCookieTableMutation'
import useNotifications from '../../hooks/useNotifications'
import Icon from '../../utils/Icon'
import DrawerItem from '../../utils/DrawerItem'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import ButtonLink from '../../utils/ButtonLink'
import DrawerError from '../../utils/DrawerError'

function getSortedVersions(cookieTable) {
    const latestVersion = cookieTable.latestVersion || {}
    const versions = cookieTable.versions ? [...cookieTable.versions] : []
    const filteredVersions = versions.filter(
        (version) => version.id !== latestVersion.id
    )
    const combinedVersions = [latestVersion, ...filteredVersions]
    combinedVersions.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    )

    return combinedVersions
}

export default function UpdateCookieTableDrawer({
    cookieTableId,
    onCompleted,
    onCreateNewVersion,
    onCustomizeCookieTable,
    onEmbedCookieTable,
    onDismiss,
}) {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const { NEXT_PUBLIC_API_URL } = process.env
    const { isFetching, cookieTable, error } = useUpdateCookieTablePageQuery({
        cookieTableId,
    })

    const [updateCookieTable] = useUpdateCookieTableMutation({
        onCompleted,
    })
    const formState = useUpdateCookieTableFormState(cookieTable)
    const { getNativeInputProps, getErrorMessages } = formState

    const onSubmit = async () => {
        if (formState.validate()) {
            try {
                const { name } = formState.values
                const input = {
                    name,
                }
                const variables = {
                    id: cookieTableId,
                    input,
                }
                await updateCookieTable({ variables })
                formState.resetForm()
                onDismiss()
                dispatchSuccess({
                    message: (
                        <Trans>
                            Cookie table <strong>{input.name}</strong> updated!
                        </Trans>
                    ),
                })
            } catch (e) {
                dispatchGraphqlError(e)
            }
        }
    }

    const versions = getSortedVersions(cookieTable)
    const url = `${NEXT_PUBLIC_API_URL}/cookie-table/${cookieTableId}`

    if (isFetching) {
        return <DrawerLoading />
    }
    if (error) {
        return <DrawerError error={error} />
    }
    return (
        <>
            <DrawerHeader title={cookieTable.name} />
            <DrawerDetailsItem label="Link to published table" className="px-6">
                <div className="flex items-center gap-1">
                    <Icon name="link-01" size="xs" />
                    <p className="text-sm text-gray-600">{url}</p>
                </div>
            </DrawerDetailsItem>
            <DrawerItem className="flex w-full flex-row gap-4 pt-8">
                <Button
                    type="white"
                    className="w-32"
                    iconBeforeProps={{
                        name: 'palette',
                        size: 'sm',
                    }}
                    onClick={onCustomizeCookieTable}
                >
                    Customize
                </Button>
                <Button
                    type="white"
                    className="w-32"
                    iconBeforeProps={{
                        name: 'link-external-01',
                        size: 'sm',
                    }}
                    onClick={() => window.open(url, '_blank')}
                >
                    Preview
                </Button>
                <Button
                    type="white"
                    className="w-32"
                    onClick={onEmbedCookieTable}
                    iconBeforeProps={{
                        name: 'code-01',
                        size: 'sm',
                    }}
                >
                    Embed
                </Button>
            </DrawerItem>
            <DrawerItem>
                <FormGroup label={<Trans>Name</Trans>}>
                    <FormInput
                        errors={getErrorMessages('name')}
                        {...getNativeInputProps('name')}
                    />
                </FormGroup>
            </DrawerItem>
            <DrawerItem className="flex-col px-6">
                <p className="text-sm font-medium">Versions</p>
                <p className="w-full text-sm text-gray-600">
                    You can create multiple versions of each cookie table. By
                    creating a new version you can compare both old and new
                    versions.
                </p>
            </DrawerItem>
            <DrawerItem className="mt-4 h-full overflow-auto">
                <div className="pb-4">
                    <ButtonLink onClick={onCreateNewVersion}>
                        Add new version
                    </ButtonLink>
                </div>
                {/* @TODO: Handle 'finished' status in back-end */}
                {versions
                    .filter(
                        (version) =>
                            version.status ===
                            COOKIE_TABLE_VERSIONS_STATUS_FINISHED
                    )
                    .map((version, index) => (
                        <div
                            key={version?._id || index}
                            className="flex flex-row items-center justify-between border-b border-gray-200 py-4"
                        >
                            <div>
                                <h2 className="text-sm font-semibold text-gray-700">
                                    {version?.name}
                                </h2>
                                <p className="text-sm text-gray-600">
                                    Created on:{' '}
                                    <DateTime>{version?.createdAt}</DateTime>
                                </p>
                                <div className="flex items-end text-sm text-gray-600">
                                    <p>
                                        <Trans>Period from</Trans>{' '}
                                        <DateTime dateOnly dateFormat="dd MMMM">
                                            {version?.startDate}
                                        </DateTime>
                                    </p>
                                    <p className="mx-1">
                                        <Trans>until</Trans>
                                    </p>
                                    <p>
                                        <DateTime dateOnly dateFormat="dd MMMM">
                                            {version?.endDate}
                                        </DateTime>{' '}
                                    </p>
                                </div>
                            </div>
                            {index === 0 && <Tag type="green">Live</Tag>}
                        </div>
                    ))}
            </DrawerItem>

            <DrawerItem className="flex gap-2" onClick={onDismiss}>
                <Button type="white" className="w-32">
                    Cancel
                </Button>
                <Button className="w-32" onClick={onSubmit}>
                    Save
                </Button>
            </DrawerItem>
        </>
    )
}
