import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query identifiedScanVendors(
        $search: String
        $scanId: String
        $scanPageId: String
        $offset: Int
        $orderBy: VendorOrderByInput
        $filters: IdentifiedScanVendorsFilters
    ) {
        identifiedScanVendors(
            search: $search
            scanId: $scanId
            scanPageId: $scanPageId
            offset: $offset
            orderBy: $orderBy
            filters: $filters
        ) {
            edges {
                node {
                    occurrence
                    vendor {
                        id
                        domain
                        name
                        types
                        imageUrl
                        belongsToOrganization
                    }
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

const useIdentifiedScanVendorsQuery = (search, scanId, scanPageId, auditId) => {
    const orderBy = { occurrence: 'DESC' }
    const variables = { search, scanId, scanPageId, orderBy }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        skip: auditId !== null,
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'identifiedScanVendors'
    )
    return {
        error,
        identifiedScanVendors: connectionToCollection(
            data.identifiedScanVendors
        ),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}

export default useIdentifiedScanVendorsQuery
