import React from 'react'
import clsx from 'clsx'

export default function ModalFooter({ className, children, leftActions }) {
    return (
        <div
            className={clsx(
                'mt-10 flex w-full justify-between gap-8',
                className
            )}
        >
            <div className="flex items-center gap-2">{leftActions}</div>
            <div className="flex items-center gap-2">{children}</div>
        </div>
    )
}
