import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import AlertTag from '../../utils/AlertTag'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import { useCurrentDrawerStack } from '../../hooks/useDrawerStack'
import GroupedAlertDetailsDrawer from '../alerts/GroupedAlertDetailsDrawer'
import * as constants from '../../../constants'

const { APPLICATION_LEVEL_ORGANIZATION } = constants

export default function IdentifiedTrackersDrawerTabAlerts({
    auditId,
    alerts,
    level,
    startDate,
    endDate,
    period,
}) {
    const { add } = useCurrentDrawerStack()

    if (alerts.length === 0) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No alerts</Trans>}
                description={
                    <Trans>No alerts were found during this scan. </Trans>
                }
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {alerts.map((alert) => {
                    const { id, subjectName, subjectType } = alert
                    return (
                        <TableRow
                            isSelectable={
                                level !== APPLICATION_LEVEL_ORGANIZATION
                            }
                            key={id}
                            onClick={() =>
                                add(GroupedAlertDetailsDrawer, {
                                    groupingId: id,
                                    level,
                                    startDate,
                                    endDate,
                                    period,
                                    auditId,
                                })
                            }
                        >
                            <TableCellAvatar
                                columnId="subjectName"
                                py="xs"
                                px="sm"
                                avatarIconName={
                                    subjectType?.toLowerCase() || 'tracker'
                                }
                                title={subjectName}
                                text={subjectType.toLowerCase()}
                            />
                            <TableCell py="sm">
                                <div className="flex justify-end gap-1">
                                    <AlertTag alert={alert} />
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
