/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

export default function AuditSettingsPageAuditBuildTableRowActionButtons({
    isLoading,
    canDeleteAuditBuild,
    onDeleteAuditBuild,
    canRetryAuditBuild,
    onRetryAuditBuild,
}) {
    return (
        <DropdownMenu
            position="bottom-start"
            content={({ close }) => (
                <>
                    <DropdownMenuItem
                        iconName="trash-03"
                        disabled={!canDeleteAuditBuild}
                        onClick={(e) => {
                            onDeleteAuditBuild()
                            close()
                            e.stopPropagation()
                        }}
                    >
                        <Trans>Delete build</Trans>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        iconName="refresh"
                        disabled={!canRetryAuditBuild}
                        onClick={(e) => {
                            onRetryAuditBuild()
                            close()
                            e.stopPropagation()
                        }}
                    >
                        <Trans>Retry</Trans>
                    </DropdownMenuItem>
                </>
            )}
        >
            <ActionButton disabled={isLoading} />
        </DropdownMenu>
    )
}
