import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import { useMutation } from '@apollo/client'
import useNotifications from '../../hooks/useNotifications'
import { CreateAuditBuildModal } from './CreateAuditBuildModal'
import useCreateAuditBuildFormState from './useCreateAuditBuildFormState'

const MUTATION = gql`
    mutation createAuditBuild($auditId: ID!, $input: CreateAuditBuildInput!) {
        createAuditBuild(auditId: $auditId, input: $input) {
            auditBuild {
                id
            }
        }
    }
`

const useCreateAuditBuild = (options) => useMutation(MUTATION, options)

export function CreateAuditBuildModalWithState({
    audit,
    onDismiss,
    onCompleted,
    onError,
}) {
    const [createAuditBuild, { loading: isSubmitting }] = useCreateAuditBuild({
        onCompleted,
        onError,
    })
    const formState = useCreateAuditBuildFormState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateAuditBuildModal
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            isSubmitting={isSubmitting}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { deviceOs, apkFile, zipFile } = formState.values
                        const input = {
                            deviceOs,
                            apkFile,
                            zipFile,
                        }
                        await createAuditBuild({
                            variables: { auditId: audit.id, input },
                        })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: <Trans>New audit build created!</Trans>,
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}
