import React, { useState } from 'react'
import clsx from 'clsx'

const formatPostData = (postData) => {
    try {
        const unescapedInput = postData.replace(/\\"/g, '"')
        const json = JSON.parse(unescapedInput)
        return JSON.stringify(json, null, 2)
    } catch (e) {
        return null
    }
}

const highlightString = (string, patterns = []) => {
    let finalString = ''
    patterns.forEach((pattern) => {
        const regex = new RegExp(pattern.replace('(?i)', ''), 'i')
        finalString = string.replace(regex, (match) => {
            return `<span class="bg-red-600">${match}</span>`
        })
    })
    return finalString
}

export default function PostDataCodeBlock({ className, postData, patterns }) {
    const json = formatPostData(postData)
    if (!json) {
        return null
    }
    return (
        <CodeBlockWithHighlights
            code={json}
            patterns={patterns}
            className={className}
        />
    )
}

export function CodeBlockWithHighlights({ className, code, patterns }) {
    return (
        <code className={clsx(className, 'block overflow-scroll bg-gray-800')}>
            <pre
                className="w-full space-x-4 p-4 pl-6 text-left text-xs text-white"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: highlightString(code, patterns),
                }}
            />
        </code>
    )
}
