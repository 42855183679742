import React from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import PageLoading from '../../utils/PageLoading'
import useNotifications from '../../hooks/useNotifications'
import CreateAuditPage from './CreateAuditPage'
import useCreateAuditFormState from './useCreateAuditFormState'
import useCreateAuditMutation from './useCreateAuditMutation'
import useCreateAuditPageQuery from './useCreateAuditPageQuery'

const CreateAuditPageWithState = () => {
    const history = useHistory()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const { isFetching, cmps } = useCreateAuditPageQuery()
    const [createAudit, { loading }] = useCreateAuditMutation()
    const formState = useCreateAuditFormState(cmps)
    return (
        <CreateAuditPage
            cmps={cmps}
            formState={formState}
            isFetching={isFetching}
            isLoading={loading}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.valuesToInput()
                        const { data } = await createAudit({
                            variables: { input },
                        })
                        formState.resetForm()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Audit{' '}
                                    <strong>
                                        {data.createAudit.audit.name}
                                    </strong>{' '}
                                    created!
                                </Trans>
                            ),
                        })
                        history.push('/audits', { refetch: true })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default CreateAuditPageWithState
