import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import InfoCard from '../../utils/InfoCard'
import Duration from '../../utils/Duration'
import { DrawerDetailsItemText } from '../../utils/DrawerDetailsItem'
import Tag from '../../utils/Tag'
import Tooltip from '../../utils/Tooltip'
import { toTitleCase } from '../../../utils/text'

export default function AlertDetailsDrawerTabAlert({ alert }) {
    const {
        vendor,
        message,
        severityType,
        subjectType,
        type,
        scan,
        scanCookie,
        scanRequest,
    } = alert

    if (!alert) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No alerts</Trans>}
                description={
                    <Trans>No alerts were found during this scan. </Trans>
                }
            />
        )
    }

    const isTracker = subjectType.toLowerCase() === 'tracker'
    const item = isTracker ? scanRequest : scanCookie

    return (
        <>
            <InfoCard
                hasBackgroundcolor
                iconName={subjectType?.toLowerCase()}
                type={severityType?.toLowerCase()}
                title={toTitleCase(type?.toLowerCase().split('_').join(' '))}
                text={message}
            />
            <div className="flex flex-col gap-4">
                <h2 className="pt-4 font-semibold">
                    <Trans>General Information</Trans>
                </h2>

                {vendor?.name && (
                    <DrawerDetailsItemText
                        isHorizontal
                        label={<Trans>Vendor</Trans>}
                    >
                        {vendor?.name}
                    </DrawerDetailsItemText>
                )}
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Allowed legal bases</Trans>}
                >
                    <div className="flex flex-row gap-1">
                        {scan && (
                            <>
                                {scan.consentProfile?.purposes.map(
                                    (purpose) => (
                                        <Tag key={purpose.id} type="green">
                                            {purpose.name}
                                        </Tag>
                                    )
                                )}
                                {scan.consentSettings?.purposes?.map(
                                    (purpose) => (
                                        <Tag key={purpose.id} type="green">
                                            {purpose.name}
                                        </Tag>
                                    )
                                )}
                            </>
                        )}
                    </div>
                </DrawerDetailsItemText>
                {scan?.consentProfile?.name && (
                    <DrawerDetailsItemText
                        isHorizontal
                        label={<Trans>Scan consent profile</Trans>}
                    >
                        <Tag>{scan?.consentProfile?.name}</Tag>
                    </DrawerDetailsItemText>
                )}
                {scan?.consentSettings?.name && (
                    <DrawerDetailsItemText
                        isHorizontal
                        label={<Trans>Scan consent profile</Trans>}
                    >
                        <Tag>{scan?.consentSettings?.name}</Tag>
                    </DrawerDetailsItemText>
                )}

                <h2 className="pt-4 font-semibold">
                    {`${toTitleCase(subjectType)} details`}
                </h2>
                {isTracker ? (
                    <>
                        {item?.rootDomain && (
                            <DrawerDetailsItemText
                                isHorizontal
                                label={<Trans>Rootdomain</Trans>}
                            >
                                {item.rootDomain}
                            </DrawerDetailsItemText>
                        )}
                        {item?.url && (
                            <DrawerDetailsItemText
                                isHorizontal
                                label={<Trans>URL</Trans>}
                            >
                                <Tooltip
                                    event="hover"
                                    position="top-end"
                                    content={
                                        <div className="max-w-[400px] overflow-hidden break-words">
                                            {item?.url}
                                        </div>
                                    }
                                    contentClassName="max-w-[400px]"
                                >
                                    {item?.url}
                                </Tooltip>
                            </DrawerDetailsItemText>
                        )}
                        {item?.method && item?.resourceType && (
                            <DrawerDetailsItemText
                                isHorizontal
                                label={<Trans>Resource type</Trans>}
                            >
                                <Tag type="primary">{item.resourceType}</Tag>
                            </DrawerDetailsItemText>
                        )}
                    </>
                ) : (
                    <>
                        {item?.name && (
                            <DrawerDetailsItemText
                                isHorizontal
                                label={<Trans>Name</Trans>}
                            >
                                {item?.name}
                            </DrawerDetailsItemText>
                        )}
                        {scanCookie?.retention && (
                            <DrawerDetailsItemText
                                isHorizontal
                                label={<Trans>Retention</Trans>}
                            >
                                <Duration format={Duration.DAYS}>
                                    {scanCookie?.retention}
                                </Duration>
                            </DrawerDetailsItemText>
                        )}
                        {item?.domain && (
                            <DrawerDetailsItemText
                                isHorizontal
                                label={<Trans>Domain</Trans>}
                            >
                                {item?.domain}
                            </DrawerDetailsItemText>
                        )}
                    </>
                )}
            </div>
        </>
    )
}
