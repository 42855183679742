import React, { useState } from 'react'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import Tag from '../../utils/Tag'
import CollapseButton from '../../utils/CollapseButton'
import TableBody from '../../utils/TableBody'
import PostDataCodeBlock, {
    CodeBlockWithHighlights,
} from '../../utils/PostDataCodeBlock'
import TableCellAvatar from '../../utils/TableCellAvatar'
import Tooltip from '../../utils/Tooltip'

function ScanRequestTableRow({ type, scanRequest, dataIdentifier }) {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const { url, postData, tracker } = scanRequest
    const { hostname, pathname, searchParams } = new URL(url)
    const finalAvatarSrc = tracker?.vendor?.imageUrl
    return (
        <>
            <TableRow>
                <TableCellAvatar
                    py="sm"
                    px="sm"
                    truncate
                    avatarIconName="code-browser"
                    avatarSrc={finalAvatarSrc}
                    className="w-2/3"
                    title={hostname}
                    text={
                        <Tooltip
                            content={url}
                            contentClassName="max-w-[400px] break-all		"
                        >
                            {pathname}
                        </Tooltip>
                    }
                    beforeAvatar={
                        <CollapseButton
                            className="ml-6"
                            isCollapsed={isCollapsed}
                            onClick={(e) => {
                                setIsCollapsed(!isCollapsed)
                                e.stopPropagation()
                            }}
                        />
                    }
                />
            </TableRow>
            {!isCollapsed && (
                <tr>
                    <td colSpan="10" className="max-w-full">
                        {type === 'SEARCH_PARAMS' && (
                            <CodeBlockWithHighlights
                                className="w-[800px]"
                                code={searchParams.toString()}
                                patterns={dataIdentifier.patterns}
                            />
                        )}
                        {type === 'POST_DATA' && (
                            <PostDataCodeBlock
                                className="max-h-[400px] overflow-y-scroll"
                                postData={postData}
                                patterns={dataIdentifier.patterns}
                            />
                        )}
                    </td>
                </tr>
            )}
        </>
    )
}

export default function ScanDetailsDrawerTabGroupedDataIdentifiersTableRow({
    groupedScanDataIdentifier,
}) {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const { scanRequests, dataIdentifier, type } = groupedScanDataIdentifier
    const { id, name, patterns } = dataIdentifier
    return (
        <>
            <TableRow>
                <TableCell py="xs" px="sm">
                    <div className="flex items-center gap-1">
                        <CollapseButton
                            isCollapsed={isCollapsed}
                            onClick={(e) => {
                                setIsCollapsed(!isCollapsed)
                                e.stopPropagation()
                            }}
                        />
                        {name}
                    </div>
                </TableCell>
                <TableCell py="sm">
                    <div className="flex justify-end gap-1">
                        <Tag>{scanRequests.length}x</Tag>
                    </div>
                </TableCell>
            </TableRow>
            {!isCollapsed && (
                <tr>
                    <td colSpan="10">
                        <div className="max-h-[400px] overflow-y-scroll">
                            {scanRequests.length > 0 && (
                                <table className="w-full">
                                    <TableBody>
                                        {scanRequests.map((scanRequest) => (
                                            <ScanRequestTableRow
                                                key={scanRequest.id}
                                                type={type}
                                                scanRequest={scanRequest}
                                                dataIdentifier={dataIdentifier}
                                            />
                                        ))}
                                    </TableBody>
                                </table>
                            )}
                        </div>
                    </td>
                </tr>
            )}
        </>
    )
}
