import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/client'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../utils/validation'
import useNotifications from '../hooks/useNotifications'

const QUERY = gql`
    query updateVendor($id: ID!) {
        vendor(id: $id) {
            id
            name
            types
            externalId
            description
            domain
            websiteUrl
            belongsToOrganization
        }
    }
`

const UPDATE_MUTATION = gql`
    mutation ($id: ID!, $input: VendorInput!) {
        updateVendor(id: $id, input: $input) {
            vendor {
                id
                name
            }
        }
    }
`

const GENERATE_MUTATION = gql`
    mutation ($id: ID!) {
        generateVendor(id: $id) {
            feedbackId
            vendor {
                description
                types
                domain
                websiteUrl
            }
        }
    }
`

export const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this vendor.</Trans>,
    },
])

export const useUpdateVendorFormState = ({
    externalId = '',
    name = '',
    description = '',
    types = [],
    domain = '',
    websiteUrl = '',
} = {}) => {
    const formState = useFormState(
        {
            externalId,
            name,
            description,
            types: types || [],
            domain,
            websiteUrl,
        },
        {
            validation,
        }
    )
    return formState
}

const useUpdateVendor = (vendorId, { onCompleted, onError }) => {
    const [feedbackId, setFeedbackId] = useState()
    const { data: fetchDate, loading: isFetching } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        variables: { id: vendorId },
    })
    const vendor = fetchDate?.vendor
    const [updateVendor, { loading: isUpdating }] = useMutation(
        UPDATE_MUTATION,
        {
            onCompleted,
            onError,
        }
    )
    const [generateVendor, { loading: isGenerating, data: generateData }] =
        useMutation(GENERATE_MUTATION)
    const formState = useUpdateVendorFormState(vendor)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const newFeedbackId = generateData?.generateVendor?.feedbackId
    useEffect(() => {
        if (newFeedbackId) {
            setFeedbackId(newFeedbackId)
        }
    }, [newFeedbackId])
    return {
        vendor,
        generatedVendor: generateData?.vendor,
        feedbackId,
        isFetching,
        isGenerating,
        isUpdating,
        formState,
        handleFeedbackCompleted: () => {
            setFeedbackId(null)
        },
        handleGenerate: async () => {
            const { data } = await generateVendor({
                variables: { id: vendorId },
            })
            if (data?.generateVendor?.vendor) {
                const { domain, description, websiteUrl } =
                    data?.generateVendor?.vendor
                formState.setValues({ domain, description, websiteUrl })
            }
        },
        handleSubmit: async () => {
            if (formState.validate()) {
                try {
                    const input = formState.values
                    await updateVendor({ variables: { id: vendorId, input } })
                    formState.resetForm()
                    dispatchSuccess({
                        message: <Trans>Vendor updated successfully!</Trans>,
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }
        },
    }
}

export default useUpdateVendor
