import React from 'react'
import { Trans } from '@lingui/macro'
import HeaderMenu from './HeaderMenu'
import HeaderMenuItem from './HeaderMenuItem'
import * as constants from '../../constants'

const { USER_ROLE_OWNER: OWNER } = constants

export default function OrganizationHeaderMenu({ className, currentUser }) {
    return (
        <HeaderMenu className={className}>
            <HeaderMenuItem to="/dashboard" iconName="dashboard">
                <Trans>Dashboard</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem
                exact
                strict
                to="/audits"
                matchPath={[
                    '/',
                    '/audits',
                    '/domains',
                    '/pages',
                    '/scans/:id/',
                ]}
                iconName="clock"
            >
                <Trans>Audits</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem to="/alerts" iconName="alert-triangle">
                <Trans>Alerts</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem
                exact
                to="/vendors"
                matchPath={[
                    '/vendors',
                    '/vendors*',
                    '/vendors/:id/',
                    '/classifications/organization',
                    '/classifications/organization*',
                ]}
                iconName="building-07"
            >
                <Trans>Vendors</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem to="/scans" iconName="scan">
                <Trans>Scans</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem to="/reports" exact iconName="report">
                <Trans>Reports</Trans>
            </HeaderMenuItem>
            {currentUser.role === OWNER && (
                <HeaderMenuItem
                    exact
                    to="/settings"
                    matchPath={['/settings', '/settings/*']}
                    iconName="settings"
                >
                    <Trans>Settings</Trans>
                </HeaderMenuItem>
            )}
        </HeaderMenu>
    )
}
