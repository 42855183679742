/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalLoading from '../../utils/ModalLoading'
import ModalFooter from '../../utils/ModalFooter'
import FormGroup from '../../utils/FormGroup'
import VendorTableSearchSelect from '../../utils/VendorTableSearchSelect'
import FormSelectList from '../../utils/FormSelectList'

const CreateVendorTableReportModal = ({
    formState,
    isFetching = false,
    setSelectedVendorTable,
    selectedVendorTable,
    onDismiss,
    onSubmit,
}) => {
    const { getErrorMessages } = formState
    return (
        <Modal className="max-w-2xl" onDismiss={onDismiss}>
            <ModalTitle
                title={<Trans>Create vendor table report</Trans>}
                description={
                    <Trans>
                        Export all vendors included in the selected vendor table
                    </Trans>
                }
                onClose={onDismiss}
            />
            {(() => {
                if (isFetching) {
                    return <ModalLoading />
                }
                return (
                    <>
                        <FormGroup
                            label={<Trans>Select Vendortable</Trans>}
                            isHorizontal
                            className="mb-4"
                        >
                            <FormSelectList
                                errors={getErrorMessages('vendortable')}
                            >
                                <VendorTableSearchSelect
                                    className="h-[400px]"
                                    onHandleSelectVendorTable={
                                        setSelectedVendorTable
                                    }
                                    selectedVendorTable={selectedVendorTable}
                                />
                            </FormSelectList>
                        </FormGroup>
                        <ModalFooter>
                            <Button type="white" onClick={onDismiss}>
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button onClick={onSubmit}>
                                <Trans>Create</Trans>
                            </Button>
                        </ModalFooter>
                    </>
                )
            })()}
        </Modal>
    )
}

export default CreateVendorTableReportModal
