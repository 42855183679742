import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { DEVICE_OS_ANDROID } from '@clew/shared/src/constants'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'deviceOs',
        validate: isRequired,
        message: (
            <Trans>Choose a device os to which this scenario applies.</Trans>
        ),
    },
])

const useCreateAuditBuildFormState = () => {
    const formState = useFormState(
        {
            deviceOs: DEVICE_OS_ANDROID,
        },
        {
            validation,
        }
    )
    return formState
}

export default useCreateAuditBuildFormState
