import {  ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import * as storage from '../utils/storage'
import { isUnauthenticatedError } from '../utils/graphql'

export const createUnauthorizedApolloClient = () =>
    new ApolloClient({
        cache: new InMemoryCache(),
        link: createUploadLink({
            uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
        }),
    })

export default function createApolloClient() {
    const setAuthContext = async (request, { headers }) => {
        const adminHeaders = {
            ...headers,
        }
        const accessToken = await storage.getAccessToken()
        if (accessToken === null) {
            return { headers: adminHeaders }
        }
        return {
            headers: {
                ...adminHeaders,
                authorization: `Bearer ${accessToken}`,
            },
        }
    }
    const removeJwtTokenFromCacheLink = onError((error) => {
        const { graphQLErrors } = error
        if (typeof graphQLErrors !== 'undefined' && graphQLErrors.length > 0) {
            graphQLErrors.map(async (graphQLError) => {
                if (isUnauthenticatedError(graphQLError)) {
                    storage.removeAccessToken()
                    storage.removeRefreshToken()
                }
            })
        }
        // eslint-disable-next-line no-console
        console.log({ error })
    })

    const httpLink = createUploadLink({
        uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
    })

    const setAuthorizationHeadersLink = setContext(setAuthContext)

    const linkFlow = setAuthorizationHeadersLink.concat(
        removeJwtTokenFromCacheLink.concat(httpLink)
    )
    return new ApolloClient({
        link: linkFlow,
        cache: new InMemoryCache(),
    })
}
