import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteAuditScenarioModal = ({
    auditScenario,
    isLoading,
    onDismiss,
    onConfirm,
}) => (
    <ConfirmModal
        title={<Trans>Delete Scenario</Trans>}
        description={
            <Trans>
                Are you sure you want to delete{' '}
                <strong>{auditScenario.name}</strong> from this audit?
            </Trans>
        }
        confirmButtonText={<Trans>Delete scenario</Trans>}
        isLoading={isLoading}
        isDisabled={isLoading}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
    />
)

export default DeleteAuditScenarioModal
