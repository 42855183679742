import React from 'react'
import useIdentifiedScanVendorsQuery from './useIdentifiedScanVendorsQuery'
import useIdentifiedVendorsPageQuery from '../vendors/useIdentifiedVendorsPageQuery'
import useSearch from '../../hooks/useSearch'
import SelectList from '../../utils/SelectList'
import SearchInput from '../../utils/SearchInput'
import InfiniteScroll from '../../utils/InfiniteScroll'
import SelectListOption from '../../utils/SelectListOption'

const IdentifiedScanVendorSelectList = ({
    className,
    value = null,
    onChange,
    onSelect,
    onDeselect,
    scanPageId = null,
    scanId = null,
    startDate = null,
    endDate = null,
    period = null,
    auditId = null,
    isCheckbox = true,
    isRadio = false,
}) => {
    const hasArrayValue = Array.isArray(value)
    const [search, handleSearch] = useSearch('')

    // Query is skipped when auditId is provided
    const {
        identifiedScanVendors,
        isFetching: isFetchingScanVendors,
        isFetchingMore: isFetchingMoreScanVendors,
        handleFetchMore: handleFetchMoreScanVendors,
        hasMore: hasMoreScanVendors,
    } = useIdentifiedScanVendorsQuery(search, scanId, scanPageId, auditId)

    // This is based on auditId and returns the 'cached' version
    const { vendors, isFetching, isFetchingMore, handleFetchMore, hasMore } =
        useIdentifiedVendorsPageQuery(
            search,
            auditId,
            startDate,
            endDate,
            period
        )

    let finalIdentifiedVendors = identifiedScanVendors
    let finalIsFetching = isFetchingScanVendors
    let finalIsFetchingMore = isFetchingMoreScanVendors
    let finalHandleFetchMore = handleFetchMoreScanVendors
    let finalHasMore = hasMoreScanVendors
    if (auditId) {
        finalIdentifiedVendors = vendors
        finalIsFetching = isFetching
        finalIsFetchingMore = isFetchingMore
        finalHandleFetchMore = handleFetchMore
        finalHasMore = hasMore
    }

    return (
        <SelectList
            className={className}
            isFetching={finalIsFetching}
            searchInput={
                <div className="flex gap-2">
                    <SearchInput
                        value={search}
                        onChange={handleSearch}
                        className="w-full"
                    />
                </div>
            }
        >
            <InfiniteScroll
                isFetchingMore={finalIsFetchingMore}
                onFetchMore={finalHandleFetchMore}
                hasMoreRecords={finalHasMore}
            >
                {finalIdentifiedVendors.map((identifiedVendor) => {
                    const { vendor } = identifiedVendor
                    let isSelected = false
                    if (value) {
                        if (hasArrayValue) {
                            isSelected = value.includes(vendor.id)
                        } else {
                            isSelected = value === vendor.id
                        }
                    }
                    return (
                        <SelectListOption
                            avatarSrc={vendor.imageUrl}
                            avatarDefaultIcon="building-07"
                            key={vendor.id}
                            disabled
                            isCheckbox={isCheckbox}
                            isRadio={isRadio}
                            checked={
                                hasArrayValue
                                    ? value.includes(vendor.id)
                                    : value === vendor.id
                            }
                            onClick={() => {
                                if (isSelected) {
                                    if (typeof onDeselect === 'function') {
                                        onDeselect(vendor.id, vendor)
                                    }
                                    if (typeof onChange === 'function') {
                                        if (hasArrayValue) {
                                            onChange(
                                                value.filter(
                                                    (v) => v !== vendor.id
                                                )
                                            )
                                        } else {
                                            onChange(null)
                                        }
                                    }
                                } else {
                                    if (typeof onSelect === 'function') {
                                        onSelect(vendor.id, vendor)
                                    }
                                    if (typeof onChange === 'function') {
                                        if (hasArrayValue) {
                                            onChange([...value, vendor.id])
                                        } else {
                                            onChange(vendor.id)
                                        }
                                    }
                                }
                            }}
                        >
                            {vendor.name}
                        </SelectListOption>
                    )
                })}
            </InfiniteScroll>
        </SelectList>
    )
}

export default IdentifiedScanVendorSelectList
