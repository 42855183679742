import React, { useRef, useState, useEffect, forwardRef } from 'react'
import clsx from 'clsx'
import { cva } from 'cva'

const tabsVariant = cva(
    'relative flex w-fit rounded-lg border border-gray-200 bg-gray-50 text-sm font-semibold text-gray-500 backdrop-blur-sm hover:border-gray-300',
    {
        variants: {
            size: {
                md: 'h-[38px] px-0.5',
            },
        },
        defaultVariants: {
            size: 'md',
        },
    }
)

const tabVariant = cva(
    'my-auto cursor-pointer select-none rounded-full text-center text-sm text-gray-500',
    {
        variants: {
            isActive: {
                true: 'text-gray-700',
                false: 'hover:text-gray-700',
            },
            size: {
                sm: 'px-3',
                md: 'px-4',
            },
        },
        defaultVariants: {
            size: 'md',
            isActive: false,
        },
    }
)

export const AnimatedTab = forwardRef(
    ({ isActive, children, name, className, ...props }, ref) => (
        <button
            ref={ref}
            type="button"
            aria-current={isActive ? 'page' : undefined}
            className={tabVariant({
                isActive,
                className,
            })}
            name={name}
            {...props}
        >
            {children}
        </button>
    )
)

const AnimatedTabs = ({ className, children, activeTab, size }) => {
    const tabsRef = useRef({})
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0)
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0)
    useEffect(() => {
        const currentTab = tabsRef.current[activeTab]
        if (currentTab) {
            setTabUnderlineLeft(currentTab.offsetLeft)
            setTabUnderlineWidth(currentTab.clientWidth)
        }
    }, [activeTab])

    const childrenWithProps = React.Children.map(children, (child, index) => {
        const { name } = child.props
        return React.cloneElement(child, {
            ref: (el) => {
                tabsRef.current[name] = el
            },
            size,
            isActive: name === activeTab,
        })
    })

    return (
        <div className={tabsVariant({ size, className })}>
            <span
                className="absolute bottom-0 top-0 -z-10 flex overflow-hidden rounded-md py-0.5 drop-shadow transition-all duration-300"
                style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
            >
                <span className="h-full w-full rounded-md bg-white" />
            </span>
            {childrenWithProps}
        </div>
    )
}

export default AnimatedTabs
