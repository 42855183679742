import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditHeader from '../../layout/AuditHeader'
import EmptyPageCard from '../../utils/EmptyPageCard'
import AuditHeaderWithState from '../audits-details/AuditHeaderWithState'
import AuditScansPageTable from './AuditScansPageTable'
import TableStateProvider from '../../providers/TableStateProvider'
import AuditScanPageTableActions from './AuditScansPageTableActions'

const AuditScansPage = ({
    audit,
    scans,
    selectedScans,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onStartScan,
    onFetchMore,
    onDeleteScans,
    onCreateScanReport,
    onScanCompleted,
    onScanUploadCompleted,
}) => (
    <PageContainer data-testid="AuditScansPage">
        <AuditHeader />
        <SidebarContainer>
            <PageContent size="full" isFetching={isFetching}>
                <AuditHeaderWithState
                    title={<Trans>Scans</Trans>}
                    description={
                        <Trans>
                            All the scans that have been performed for this
                            audit.
                        </Trans>
                    }
                    selectedScans={selectedScans}
                    onScanCompleted={onScanCompleted}
                    onUploadCompleted={onScanUploadCompleted}
                />
                {(() => {
                    if (scans.length === 0) {
                        return (
                            <EmptyPageCard
                                className="mt-6"
                                iconName="clock"
                                title={<Trans>No scans</Trans>}
                                description={
                                    <Trans>
                                        No scans were found for this audit.
                                        <br />
                                        Start by adding new scans here.
                                    </Trans>
                                }
                            >
                                <Button onClick={onStartScan} className="mt-6">
                                    Start a new Scan
                                </Button>
                            </EmptyPageCard>
                        )
                    }
                    return (
                        <TableStateProvider>
                            <AuditScanPageTableActions
                                onDeleteScans={onDeleteScans}
                                onCreateScanReport={onCreateScanReport}
                                scans={scans}
                            />
                            <AuditScansPageTable
                                audit={audit}
                                scans={scans}
                                isFetchingMore={isFetchingMore}
                                isFetching={isFetching}
                                onDeleteScans={onDeleteScans}
                                onFetchMore={onFetchMore}
                                hasMoreRecords={hasMoreRecords}
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default AuditScansPage
