/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import Button from '../../utils/Button'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'
import FormButtonGroup from '../../utils/FormButtonGroup'
import TagSelect from '../../utils/TagSelect'

const CreateMobileAuditForm = ({ formState, onSubmit, isLoading }) => {
    const { values, handleChange, getErrorMessages, getNativeInputProps } =
        formState
    return (
        <Card>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    onSubmit()
                }}
            >
                <CardHeader
                    title={<Trans>Audit Information</Trans>}
                    description={
                        <Trans>
                            Please fill in all information related to the audit.
                        </Trans>
                    }
                />
                <div className="divide-y">
                    <FormGroup
                        isHorizontal
                        label={<Trans>Name</Trans>}
                        htmlFor="name"
                    >
                        <FormInput
                            id="name"
                            className="max-w-lg"
                            errors={getErrorMessages('name')}
                            {...getNativeInputProps('name')}
                        />
                    </FormGroup>
                    <FormGroup
                        isHorizontal
                        label={<Trans>Apple App Store url</Trans>}
                        htmlFor="appleAppStoreUrl"
                    >
                        <FormInput
                            id="appleAppStoreUrl"
                            className="max-w-lg"
                            errors={getErrorMessages('appleAppStoreUrl')}
                            {...getNativeInputProps('appleAppStoreUrl')}
                        />
                    </FormGroup>
                    <FormGroup
                        isHorizontal
                        label={<Trans>Google Play Store url</Trans>}
                        htmlFor="googlePlayStoreUrl"
                    >
                        <FormInput
                            id="googlePlayStoreUrl"
                            className="max-w-lg"
                            errors={getErrorMessages('googlePlayStoreUrl')}
                            {...getNativeInputProps('googlePlayStoreUrl')}
                        />
                    </FormGroup>

                    <FormGroup isHorizontal label={<Trans>Tags</Trans>}>
                        <TagSelect
                            value={values.tags}
                            onChange={(tags) => handleChange('tags', tags)}
                        />
                    </FormGroup>
                </div>
                <FormButtonGroup>
                    <Button
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        <Trans>Save changes</Trans>
                    </Button>
                    <Link to="/audits">
                        <Button type="white">
                            <Trans>Cancel</Trans>
                        </Button>
                    </Link>
                </FormButtonGroup>
            </form>
        </Card>
    )
}

export default CreateMobileAuditForm
