import React from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import CreateMobileAuditPage from './CreateMobileAuditPage'
import useCreateAuditMutation from '../audits-create/useCreateAuditMutation'
import useCreateMobileAuditFormState from './useCreateMobileAuditFormState'

const CreateMobileAuditPageWithState = () => {
    const history = useHistory()
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [createAudit, { loading }] = useCreateAuditMutation()
    const formState = useCreateMobileAuditFormState()
    return (
        <CreateMobileAuditPage
            formState={formState}
            isLoading={loading}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.valuesToInput()
                        const { data } = await createAudit({
                            variables: { input },
                        })
                        formState.resetForm()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Audit{' '}
                                    <strong>
                                        {data.createAudit.audit.name}
                                    </strong>{' '}
                                    created!
                                </Trans>
                            ),
                        })
                        history.push('/audits', { refetch: true })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
        />
    )
}

export default CreateMobileAuditPageWithState
