import React from 'react'
import { Trans } from '@lingui/macro'
import { DEVICE_OS_IOS, DEVICE_OS_ANDROID } from '@clew/shared/src/constants'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalLoading from '../../utils/ModalLoading'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import RadioButton from '../../utils/RadioButton'
import FormUpload from '../../utils/FormUpload'
import ModalFooter from '../../utils/ModalFooter'
import FormSelectList from '../../utils/FormSelectList'
import SelectList from '../../utils/SelectList'
import SelectListOption from '../../utils/SelectListOption'
import FormInput from '../../utils/FormInput'

export default function UploadScanModal({
    audit,
    purposes,
    onDismiss,
    formState,
    isFetching,
    onSubmit,
    isUploading,
}) {
    const { values, handleChange, getErrorMessages } = formState
    const { deviceType } = values
    const { isMobile } = audit
    return (
        <Modal onDismiss={onDismiss} className="max-w-2xl">
            <ModalTitle
                title={<Trans>Upload scan session</Trans>}
                description={<Trans>Upload a HAR file of a session</Trans>}
                onClose={onDismiss}
            />
            {(() => {
                if (isFetching) {
                    return <ModalLoading />
                }
                return (
                    <>
                        <FormGroup
                            label={<Trans>Scan name (optional)</Trans>}
                            htmlFor="name"
                        >
                            <FormInput
                                id="name"
                                errors={formState.getErrorMessages('name')}
                                {...formState.getNativeInputProps('name')}
                            />
                        </FormGroup>
                        {isMobile && (
                            <FormGroup label={<Trans>Device type</Trans>}>
                                <RadioButton.Group>
                                    <RadioButton
                                        value={DEVICE_OS_IOS}
                                        checked={deviceType === DEVICE_OS_IOS}
                                        onChange={() =>
                                            formState.handleChange(
                                                'deviceType',
                                                DEVICE_OS_IOS
                                            )
                                        }
                                    >
                                        <Trans>Apple (iOS)</Trans>
                                    </RadioButton>
                                    <RadioButton
                                        value={DEVICE_OS_ANDROID}
                                        checked={
                                            deviceType === DEVICE_OS_ANDROID
                                        }
                                        onChange={() =>
                                            formState.handleChange(
                                                'deviceType',
                                                DEVICE_OS_ANDROID
                                            )
                                        }
                                    >
                                        <Trans>Android</Trans>
                                    </RadioButton>
                                </RadioButton.Group>
                            </FormGroup>
                        )}
                        <FormGroup
                            label={<Trans>Consent profile</Trans>}
                            className="mb-4"
                        >
                            <RadioButton.Group>
                                <RadioButton
                                    value="NO_CONSENT"
                                    checked={
                                        formState.values.consentType ===
                                        'NO_CONSENT'
                                    }
                                    onChange={() => formState.handleNoConsent()}
                                >
                                    <Trans>No consent</Trans>
                                </RadioButton>
                                <RadioButton
                                    value="REJECT_ALL"
                                    checked={
                                        formState.values.consentType ===
                                        'REJECT_ALL'
                                    }
                                    onChange={() => formState.handleRejectAll()}
                                >
                                    <Trans>Reject all</Trans>
                                </RadioButton>
                                <RadioButton
                                    value="ACCEPT_ALL"
                                    checked={
                                        formState.values.consentType ===
                                        'ACCEPT_ALL'
                                    }
                                    onChange={() => formState.handleAcceptAll()}
                                >
                                    <Trans>Accept all</Trans>
                                </RadioButton>
                                <RadioButton
                                    value="CUSTOM"
                                    checked={
                                        formState.values.consentType ===
                                        'CUSTOM'
                                    }
                                    onChange={() => formState.handleCustom()}
                                >
                                    <Trans>Custom</Trans>
                                </RadioButton>
                            </RadioButton.Group>
                        </FormGroup>
                        <FormGroup label={<Trans>Webclew Purposes</Trans>}>
                            <FormSelectList
                                disabled={
                                    formState.values.consentType !== 'CUSTOM'
                                }
                            >
                                <SelectList>
                                    {purposes.map(({ id, name }) => (
                                        <SelectListOption
                                            key={id}
                                            disabled
                                            isCheckbox
                                            checked={formState.values.purposeIds.includes(
                                                id
                                            )}
                                            onClick={() =>
                                                formState.handleChangePurpose(
                                                    id
                                                )
                                            }
                                        >
                                            {name}
                                        </SelectListOption>
                                    ))}
                                </SelectList>
                            </FormSelectList>
                        </FormGroup>
                        <FormGroup
                            label={<Trans>HAR/PCAP file</Trans>}
                            className="mb-4"
                            htmlFor="scanSessionFile"
                        >
                            <FormUpload
                                id="scanSessionFile"
                                accept=".har,.pcap"
                                errors={getErrorMessages('scanSessionFile')}
                                onChange={(e) =>
                                    handleChange(
                                        'scanSessionFile',
                                        e.target.files[0]
                                    )
                                }
                            />
                        </FormGroup>
                        <ModalFooter>
                            <Button type="white" onClick={onDismiss}>
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button
                                onClick={onSubmit}
                                disabled={isUploading}
                                loading={isUploading}
                            >
                                <Trans>Upload</Trans>
                            </Button>
                        </ModalFooter>
                    </>
                )
            })()}
        </Modal>
    )
}
