import React, { useEffect } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import UploadScanModal from './UploadScanModal'
import useUploadScanMutation, {
    useUploadScanModalQuery,
} from './useUploadScanMutation'
import useUploadScanFormState from './useUploadScanFormState'

const useSelectedConsentProfile = (formState, consentProfiles = []) => {
    const selected = formState.values.cmpId || null
    const [first] = consentProfiles
    useEffect(() => {
        if (selected === null && first) {
            formState.handleChange('consentProfileId', first.id)
        }
    }, [first, selected])
    return selected
}

export default function UploadScanModalWithState({
    audit,
    onDismiss,
    onCompleted,
}) {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const { purposes, isFetching } = useUploadScanModalQuery()
    const formState = useUploadScanFormState(audit, purposes)
    const [uploadScan, { loading, error: uploadError }] = useUploadScanMutation(
        {
            onCompleted,
        }
    )
    useSelectedConsentProfile(formState, audit.consentProfiles)
    return (
        <UploadScanModal
            onDismiss={onDismiss}
            audit={audit}
            formState={formState}
            purposes={purposes}
            isFetching={isFetching}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const {
                            scanSessionFile,
                            deviceType,
                            purposeIds,
                            consentType,
                            name,
                        } = formState.values
                        const input = {
                            deviceType,
                            auditId: audit.id,
                            scanSessionFile,
                            purposeIds,
                            consentType,
                            name,
                        }
                        const variables = { input }
                        await uploadScan({ variables })
                        onDismiss()
                        dispatchSuccess({
                            message: <Trans>New scan session added!</Trans>,
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
            isUploading={loading}
        />
    )
}
