import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { DEVICE_OS_IOS } from '@clew/shared/src/constants'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'scanSessionFile',
        validate: isRequired,
        message: <Trans>Choose a HAR/PCAP file.</Trans>,
    },
    {
        path: 'consentType',
        validate: isRequired,
        message: <Trans>Please choose a consent profile.</Trans>,
    },
])

const useUploadScanFormState = (audit, allPurposes = []) => {
    const formState = useFormState(
        {
            name: '',
            deviceType: DEVICE_OS_IOS,
            scanSessionFile: null,
            consentType: 'NO_CONSENT',
            purposeIds: [],
        },
        {
            validation,
        }
    )
    formState.handleNoConsent = () => {
        formState.handleChange('consentType', 'NO_CONSENT')
        formState.handleChange(
            'purposeIds',
            allPurposes
                .filter(({ externalId }) => externalId === 'essential')
                .map(({ id }) => id)
        )
    }
    formState.handleRejectAll = () => {
        formState.handleChange('consentType', 'REJECT_ALL')
        formState.handleChange(
            'purposeIds',
            allPurposes
                .filter(({ externalId }) => externalId === 'essential')
                .map(({ id }) => id)
        )
    }
    formState.handleAcceptAll = () => {
        formState.handleChange('consentType', 'ACCEPT_ALL')
        formState.handleChange(
            'purposeIds',
            allPurposes.map(({ id }) => id)
        )
    }
    formState.handleCustom = () => {
        formState.handleChange('consentType', 'CUSTOM')
        formState.handleChange('purposeIds', [])
    }
    formState.handleChangePurpose = (purposeId) => {
        formState.handleChange('purposeIds', (purposeIds) => {
            if (purposeIds.includes(purposeId)) {
                return purposeIds.filter((id) => id !== purposeId)
            }
            return [...purposeIds, purposeId]
        })
    }
    return formState
}

export default useUploadScanFormState
