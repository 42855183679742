import React, { useRef, useState, useEffect } from 'react'
import Avatar from './Avatar'
import Button from './Button'
import { useCurrentDrawerStack } from '../hooks/useDrawerStack'
import Tooltip from './Tooltip'

export default function DrawerHeader({
    title,
    afterTitle,
    beforeDescription,
    description,
    afterDescription,
    avatar,
    avatarColor,
    avatarSrc,
    avatarIconColor,
    avatarIconName,
    avatarStatus,
    avatarStatusSrc,
    buttons,
    onClose,
    onForward,
    onBack,
}) {
    const { back, forward, canGoBack, canGoForward, close } = useCurrentDrawerStack()

    const titleRef = useRef(null)
    const [isTooltipNeeded, setIsTooltipNeeded] = useState(false)
    const maxTitleWidth = 384 // max-w-96 in pixels

    useEffect(() => {
        if (titleRef.current) {
            setIsTooltipNeeded(titleRef.current.offsetWidth > maxTitleWidth)
        }
    }, [title])

    return (
        <div className="mb-6 px-6">
            <div className="mb-6 flex justify-between pt-8">
                <div className="flex items-center gap-2">
                    <div className="flex items-center">
                        <Button
                            type="white"
                            grouped="left"
                            size="sm"
                            disabled={!canGoBack}
                            iconBeforeProps={{
                                name: 'chevron-left',
                                size: 'sm',
                                color: 'none',
                            }}
                            onClick={() => {
                                back()
                                if (onBack) {
                                    onBack()
                                }
                            }}
                        />
                        <Button
                            type="white"
                            grouped="right"
                            size="sm"
                            disabled={!canGoForward}
                            iconBeforeProps={{
                                name: 'chevron-right',
                                size: 'sm',
                                color: 'none',
                            }}
                            onClick={() => {
                                forward()
                                if (onForward) {
                                    onForward()
                                }
                            }}
                        />
                    </div>
                    <Button
                        type="white"
                        size="sm"
                        iconBeforeProps={{
                            name: 'x',
                            size: 'sm',
                            color: 'none',
                        }}
                        onClick={() => {
                            close()
                            if (onClose) {
                                onClose()
                            }
                        }}
                    />
                </div>
                {buttons && (
                    <div className="flex items-center gap-3">{buttons}</div>
                )}
            </div>
            <div className="flex gap-4">
                {avatar}
                {(avatarIconName || avatarSrc) && (
                    <Avatar
                        avatarStatus={avatarStatus}
                        avatarStatusSrc={avatarStatusSrc}
                        size="xl"
                        className="flex-shrink-0"
                        src={avatarSrc}
                        iconName={avatarIconName}
                        iconColor={avatarIconColor}
                        color={avatarColor}
                    />
                )}
                <div className="flex flex-col gap-2 pt-1">
                    <div className="flex items-center gap-4">
                        {title &&
                            (isTooltipNeeded ? (
                                <Tooltip content={title}>
                                    <h3
                                        ref={titleRef}
                                        className="max-w-96 truncate text-2xl font-medium leading-6 text-gray-900"
                                    >
                                        {title}
                                    </h3>
                                </Tooltip>
                            ) : (
                                <h3
                                    ref={titleRef}
                                    className="text-2xl font-medium leading-6 text-gray-900"
                                >
                                    {title}
                                </h3>
                            ))}
                        {afterTitle}
                    </div>
                    {description && (
                        <div className="flex w-full items-center gap-2">
                            {beforeDescription}
                            <p className="line-clamp-2 text-sm leading-5 text-gray-500">
                                {description}
                            </p>
                            {afterDescription}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
