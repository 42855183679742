import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteAuditBuildModal = ({
    auditBuild,
    isLoading,
    onDismiss,
    onConfirm,
}) => (
    <ConfirmModal
        title={<Trans>Delete build</Trans>}
        description={
            <Trans>
                Are you sure you want to delete{' '}
                <strong>{auditBuild.version}</strong> from this audit?
            </Trans>
        }
        confirmButtonText={<Trans>Delete build</Trans>}
        isLoading={isLoading}
        isDisabled={isLoading}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
    />
)

export default DeleteAuditBuildModal
