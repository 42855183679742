import React from 'react'
import useSearch from '../../hooks/useSearch'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import StaffDataIdentifiersPage from './StaffDataIdentifiersPage'
import useStaffDataIdentifiersPageQuery from './useStaffDataIdentifiersPageQuery'
import useRefetch from '../../hooks/useRefetch'

export default function StaffDataIdentifiersPageWithState() {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const {
        dataIdentifiers,
        error,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useStaffDataIdentifiersPageQuery({
        search,
    })
    useRefetch(refetch)
    return (
        <StaffDataIdentifiersPage
            search={search}
            dataIdentifiers={dataIdentifiers}
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasMore}
            onFetchMore={handleFetchMore}
            onSearch={handleSearch}
            isFetching={isFetching}
            error={error}
        />
    )
}
