import React, { useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import useDrawerStack from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import Tooltip from '../../utils/Tooltip'
import Tag from '../../utils/Tag'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import AlertCountTag from '../../utils/AlertCountTag'
import PurposesTags from '../../utils/PurposesTags'
import ScanCookieDetailsDrawer from './ScanCookieDetailsDrawer'
import useSearch from '../../hooks/useSearch'
import SearchInput from '../../utils/SearchInput'
import AnimatedTabs, { AnimatedTab } from '../../utils/AnimatedTabs'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import DrawerTabError from '../../utils/DrawerTabError'
import InfiniteScroll from '../../utils/InfiniteScroll'
import useScanPageDetailDataSinksDrawerQuery from './useScanPageDetailDataSinksDrawerQuery'
import useFilters from '../../hooks/useFilters'

export default function ScanPageDetailsDrawerTabCookies({ scanPageId }) {
    const { add } = useDrawerStack()
    const [search, handleSearch] = useSearch('')
    const [activeTab, setActiveTab] = useState('all')
    const [typeFilter, onChangeTypeFilter] = useState(null)
    const [purposeFilter, onChangePurposeFilter] = useState(null)
    const [vendorsFilter, onChangeVendorsFilter] = useState([])
    const {
        activeFilters,
        filterValues,
        onRemoveFilter,
        onAddFilter,
        onRemoveAllFilters,
    } = useFilters({
        type: typeFilter,
        purpose: purposeFilter,
        vendors: vendorsFilter,
    })
    const {
        scanDataSinks,
        isFetching: loading,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        error,
    } = useScanPageDetailDataSinksDrawerQuery({
        scanPageId,
        search,
        filters: { ...filterValues },
    })

    if (error) return <DrawerTabError error={error} />
    return (
        <div className="flex flex-col gap-3">
            {loading && <DrawerTabLoading />}
            {!loading && (
                <InfiniteScroll
                    isFetchingMore={isFetchingMore}
                    onFetchMore={handleFetchMore}
                    hasMoreRecords={hasMore}
                >
                    <Table hasMinWidth={false} isRounded hasHeader={false}>
                        <TableBody>
                            {scanDataSinks.length === 0 ? (
                                <DrawerEmptyCard
                                    iconName="scan"
                                    title={<Trans>Data identifiers</Trans>}
                                    description={
                                        <Trans>
                                            No data identifiers were found
                                        </Trans>
                                    }
                                />
                            ) : (
                                scanDataSinks.map(({ dataSink }) => {
                                    const { id, name } = dataSink
                                    return (
                                        <TableRow
                                            isSelectable
                                            key={id}
                                            onClick={() => {}}
                                        >
                                            <TableCell py="sm">
                                                {name}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            )}
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            )}
        </div>
    )
}
