import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import AuditSettingsPageAuditBuildTableRowActionButtons from './AuditSettingsPageAuditBuildTableRowActionButtons'
import DeleteAuditBuildModalWithState from './DeleteAuditBuildModalWithState'
import useNotifications from '../../hooks/useNotifications'
import useRetryAuditBuildMutation from './useRetryAuditBuildMutation'

const { USER_ROLE_OWNER: OWNER } = constants

const AuditSettingsPageAuditBuildTableRowActionButtonWithState = ({
    auditBuild,
    onDeleteAuditBuildCompleted,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const { currentUser } = useAppState()
    const [isDeleteAuditBuildModalOpen, setDeleteAuditBuildModalOpen] =
        useState(false)
    const [retryAuditBuild] = useRetryAuditBuildMutation()
    return (
        <>
            {isDeleteAuditBuildModalOpen && (
                <DeleteAuditBuildModalWithState
                    auditBuild={auditBuild}
                    onDismiss={() => setDeleteAuditBuildModalOpen(false)}
                    onCompleted={onDeleteAuditBuildCompleted}
                />
            )}
            <AuditSettingsPageAuditBuildTableRowActionButtons
                auditBuild={auditBuild}
                canDeleteAuditBuild={currentUser.role === OWNER}
                onDeleteAuditBuild={() => setDeleteAuditBuildModalOpen(true)}
                canRetryAuditBuild={
                    currentUser.role === OWNER && !auditBuild.uploadCompletedAt
                }
                onRetryAuditBuild={async () => {
                    try {
                        await retryAuditBuild({
                            variables: { id: auditBuild.id },
                        })
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    {auditBuild.name} successfully retried.
                                </Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }}
            />
        </>
    )
}

export default AuditSettingsPageAuditBuildTableRowActionButtonWithState
