import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation deleteAuditScenario($id: ID!) {
        deleteAuditScenario(id: $id)
    }
`

const useDeleteAuditScenarioMutation = (options) =>
    useMutation(MUTATION, options)

export default useDeleteAuditScenarioMutation
