import React from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useRefetch from '../../hooks/useRefetch'
import useEvents from '../../hooks/useEvents'
import useNotifications from '../../hooks/useNotifications'
import useDeleteScansMutation from './useDeleteScansMutation'
import useStartAuditMutation from '../audits-details/useStartAuditMutation'
import useAuditScansPageQuery from './useAuditScansPageQuery'
import AuditScansPage from './AuditScansPage'
import useCreateReportMutation from './useCreateReportMutation'

const AuditScansPageWithState = () => {
    useCloseDrawerOnLeave()
    const history = useHistory()
    const { dispatchSuccess, dispatchError } = useNotifications()
    const {
        audit,
        scans,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useAuditScansPageQuery()
    useEvents(['scan:in-progress', 'scan:completed', 'scan:failed'], () =>
        refetch()
    )
    useRefetch(refetch)

    const [deleteScans] = useDeleteScansMutation()
    const [createReport] = useCreateReportMutation()
    const [startAudit] = useStartAuditMutation()

    const handleStartAudit = async () => {
        try {
            await startAudit()
            refetch()
        } catch (e) {
            const { message } = e.graphQLErrors[0]
            dispatchError({ message })
        }
    }

    const handleCreateScanReport = async (
        selectedScans,
        onClearSelectedRows
    ) => {
        try {
            await createReport({
                variables: {
                    input: {
                        auditId: audit.id,
                        scanIds: selectedScans.map(({ id }) => id),
                    },
                },
            })
            dispatchSuccess({
                message: <Trans>New report created!</Trans>,
            })
            history.push(`/audits/${audit.id}/reports`)
            onClearSelectedRows()
        } catch (e) {
            const { message } = e.graphQLErrors[0]
            dispatchError({ message })
        }
    }

    const handleDeleteScans = async (selectedScans, onClearSelectedRows) => {
        try {
            await deleteScans({
                variables: {
                    input: {
                        ids: selectedScans.map(({ id }) => id),
                    },
                },
            })
            dispatchSuccess({
                message: <Trans>Deleted scans!</Trans>,
            })
            onClearSelectedRows()
            refetch()
        } catch (e) {
            const { message } = e.graphQLErrors[0]
            dispatchError({ message })
        }
    }

    return (
        <AuditScansPage
            audit={audit}
            scans={scans}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasMore}
            onFetchMore={handleFetchMore}
            onScanCompleted={() => refetch()}
            onScanUploadCompleted={() => refetch()}
            onStartScan={handleStartAudit}
            onDeleteScans={handleDeleteScans}
            onCreateScanReport={handleCreateScanReport}
        />
    )
}

export default AuditScansPageWithState
