import React from 'react'
import { Trans } from '@lingui/macro'
import { useCurrentDrawerStack } from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCellAvatar from '../../utils/TableCellAvatar'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import ScanCookieDetailsDrawer from '../scans/ScanCookieDetailsDrawer'
import DrawerTabError from '../../utils/DrawerTabError'
import { useAlertDetailsDrawerScanCookiesQuery } from './useAlertDetailsDrawerQuery'
import InfiniteScroll from '../../utils/InfiniteScroll'

export default function GroupedAlertDetailsDrawerTabCookies({
    vendorImageUrl,
    alertGroupingId,
    auditId,
    scanId,
    startDate,
    endDate,
}) {
    const { add } = useCurrentDrawerStack()
    const {
        scanCookies,
        error,
        isFetching,
        isFetchingMore,
        hasMore,
        handleFetchMore,
    } = useAlertDetailsDrawerScanCookiesQuery({
        auditId,
        scanId,
        filters: { alertGroupingId, startDate, endDate },
    })

    if (error) <DrawerTabError error={error} />
    if (!isFetching && (!scanCookies || scanCookies.length === 0)) {
        return (
            <DrawerEmptyCard
                iconName="cookie"
                title={<Trans>No cookies</Trans>}
                description={<Trans>No cookies were found.</Trans>}
            />
        )
    }
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore && !isFetching}
        >
            <Table
                isFetching={isFetching}
                hasMinWidth={false}
                isRounded
                hasHeader={false}
            >
                <TableBody>
                    {scanCookies.map((scanCookie) => {
                        const { id, name, scanPage, audit } = scanCookie
                        return (
                            <TableRow
                                key={id}
                                isSelectable
                                onClick={() => {
                                    add(ScanCookieDetailsDrawer, {
                                        scanCookieId: id,
                                    })
                                }}
                            >
                                <TableCellAvatar
                                    columnId="subjectName"
                                    py="sm"
                                    px="sm"
                                    truncate
                                    className="w-1/2"
                                    avatarIconName="cookie"
                                    avatarSrc={audit?.imageUrl}
                                    avatarStatusSrc={vendorImageUrl}
                                    avatarStatusName="cookie"
                                    title={name}
                                    text={
                                        scanPage &&
                                        new URL(scanPage.url).pathname
                                    }
                                />
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}
