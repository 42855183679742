import React from 'react'
import { Trans } from '@lingui/macro'
import HeaderMenu from './HeaderMenu'
import HeaderMenuItem from './HeaderMenuItem'

export default function StaffHeaderMenu({ className }) {
    return (
        <HeaderMenu className={className}>
            <HeaderMenuItem to="/staff" exact iconName="building-07">
                <Trans>Organizations</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem to="/staff/scans" iconName="scan">
                <Trans>Scans</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem to="/staff/vendors" iconName="globe-search">
                <Trans>Vendors</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem
                to="/staff/jobs/audit"
                matchPath={['/staff/jobs']}
                iconName="clock"
            >
                <Trans>Jobs</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem
                to="/staff/data-identifiers"
                iconName="data-identifier"
            >
                <Trans>Data identifiers</Trans>
            </HeaderMenuItem>
        </HeaderMenu>
    )
}
