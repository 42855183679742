import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import ActionButton from '../../utils/ActionButton'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'

export default function AuditHeaderActions({
    isMobile,
    isWeb,
    isCode,
    onStartScan,
    isLoading,
    onUploadSession,
}) {
    return (
        <div className="flex items-center gap-2">
            <DropdownMenu
                position="bottom-start"
                // eslint-disable-next-line react/no-unstable-nested-components
                content={({ close }) => (
                    <DropdownMenuItem
                        iconName="upload"
                        disabled={isLoading}
                        onClick={() => {
                            close()
                            onUploadSession()
                        }}
                    >
                        Upload new session
                    </DropdownMenuItem>
                )}
            >
                <ActionButton className="border border-slate-300 bg-white text-slate-700 shadow-xs active:text-slate-800 enabled:hover:bg-slate-100 disabled:border-slate-300" />
            </DropdownMenu>
            <Button
                onClick={onStartScan}
                loading={isLoading}
                disabled={isLoading}
            >
                <Trans>Start new scan</Trans>
            </Button>
        </div>
    )
}
