import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import Tag from '../../utils/Tag'
import Tooltip from '../../utils/Tooltip'
import Duration from '../../utils/Duration'
import { useCurrentDrawerStack } from '../../hooks/useDrawerStack'
import { getPageStatusColor } from '../../../utils/entities'
import ScanPageDetailsDrawer from '../scans/ScanPageDetailsDrawer'
import SearchInput from '../../utils/SearchInput'
import useSearch from '../../hooks/useSearch'

export default function IdentifiedScanTrackerDetailsDrawerTabObservations({
    pages,
}) {
    const { add } = useCurrentDrawerStack()
    const [search, handleSearch] = useSearch('')

    const filteredPages = pages?.filter((page) => {
        const pageUrl = page.scanPages[0]?.url?.toLowerCase() || ''
        const pageId = page.scanPages[0]?.id?.toLowerCase() || ''
        return pageUrl.includes(search) || pageId.includes(search)
    })

    if (!filteredPages.length) {
        return (
            <>
                <SearchInput
                    className="mb-4"
                    onChange={handleSearch}
                    value={search}
                />
                <DrawerEmptyCard
                    iconName="scan"
                    title={<Trans>No Information</Trans>}
                    description={<Trans>There was no tracker found. </Trans>}
                />
            </>
        )
    }

    return (
        <div className="flex flex-col gap-4">
            <SearchInput onChange={handleSearch} value={search} />
            <Table hasMinWidth={false} isRounded hasHeader={false}>
                <TableBody>
                    {filteredPages.map((page) => {
                        const { id, url, status, duration } = page.scanPages[0]
                        return (
                            <TableRow
                                isSelectable
                                key={id}
                                onClick={() => {
                                    add(ScanPageDetailsDrawer, {
                                        scanPageId: id,
                                    })
                                }}
                            >
                                <TableCell py="sm" truncate className="w-2/3">
                                    <Tooltip
                                        event="hover"
                                        content={
                                            <div className="max-w-[400px] overflow-hidden break-words">
                                                {url}
                                            </div>
                                        }
                                        contentClassName="max-w-[400px]"
                                    >
                                        {new URL(url).pathname}
                                    </Tooltip>
                                </TableCell>
                                <TableCell py="sm">
                                    <div className="flex gap-1 justify-end">
                                        <Tag
                                            type={getPageStatusColor(status)}
                                            className=" capitalize"
                                        >
                                            {status
                                                .toLowerCase()
                                                .replace('_', ' ')}
                                        </Tag>

                                        <Tag iconNameBefore="clock" type="gray">
                                            <Duration format="secs-short">
                                                {duration}
                                            </Duration>
                                        </Tag>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    )
}
