import React from 'react'
import { Trans } from '@lingui/macro'
import HeaderMenu from './HeaderMenu'
import HeaderMenuItem from './HeaderMenuItem'

export default function AuditHeaderMenu({ audit, isFetching, className }) {
    const { isMobile, isWeb, isCode } = audit
    return (
        <HeaderMenu className={className}>
            <HeaderMenuItem
                exact
                to={`/audits/${audit.id}`}
                iconName="bar-chart-square-2"
            >
                <Trans>Overview</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem
                exact
                to={`/audits/${audit.id}/alerts`}
                iconName="alert-triangle"
            >
                <Trans>Alerts</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem
                exact
                to={`/audits/${audit.id}/vendors`}
                matchPath={[
                    `/audits/${audit.id}/vendors*`,
                    `/audits/${audit.id}/cookies*`,
                    `/audits/${audit.id}/trackers*`,
                ]}
                iconName="building-07"
            >
                <Trans>Vendors</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem
                exact
                to={`/audits/${audit.id}/scans`}
                iconName="scan"
            >
                <Trans>Scans</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem
                exact
                to={`/audits/${audit.id}/reports`}
                iconName="report"
            >
                <Trans>Reports</Trans>
            </HeaderMenuItem>
            <HeaderMenuItem
                exact
                to={`/audits/${audit.id}/settings`}
                iconName="settings"
            >
                <Trans>Settings</Trans>
            </HeaderMenuItem>
        </HeaderMenu>
    )
}
