import React from 'react'
import { Trans } from '@lingui/macro'
import { DEVICE_OS_ANDROID, DEVICE_OS_IOS } from '@clew/shared/src/constants'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import RadioButton from '../../utils/RadioButton'
import ModalFooter from '../../utils/ModalFooter'
import FormUpload from '../../utils/FormUpload'

export function CreateAuditBuildModal({
    formState,
    onDismiss,
    onSubmit,
    isSubmitting,
}) {
    const { values, handleChange, getErrorMessages, getNativeInputProps } =
        formState
    const { deviceOs, consentType } = values
    return (
        <Modal onDismiss={onDismiss} className="max-w-[700px]">
            <ModalTitle
                title={<Trans>Create build</Trans>}
                description={
                    <Trans>
                        Add a new build to this audit. A build is a specific
                        version of your app on which a scan can be ran.
                    </Trans>
                }
                onClose={onDismiss}
            />
            <FormGroup label={<Trans>Device operating system</Trans>}>
                <RadioButton.Group>
                    <RadioButton
                        value={DEVICE_OS_ANDROID}
                        checked={deviceOs === DEVICE_OS_ANDROID}
                        onChange={() =>
                            handleChange('deviceOs', DEVICE_OS_ANDROID)
                        }
                    >
                        <Trans>Android</Trans>
                    </RadioButton>
                    <RadioButton
                        value={DEVICE_OS_IOS}
                        checked={deviceOs === DEVICE_OS_IOS}
                        onChange={() => handleChange('deviceOs', DEVICE_OS_IOS)}
                    >
                        <Trans>Apple (iOS)</Trans>
                    </RadioButton>
                </RadioButton.Group>
            </FormGroup>
            {deviceOs === DEVICE_OS_ANDROID && (
                <FormGroup
                    label={<Trans>APK file</Trans>}
                    className="mb-4"
                    htmlFor="apkFile"
                >
                    <FormUpload
                        id="apkFile"
                        accept=".apk"
                        disabled={isSubmitting}
                        errors={getErrorMessages('apkFile')}
                        onChange={(e) =>
                            handleChange('apkFile', e.target.files[0])
                        }
                    />
                </FormGroup>
            )}
            {deviceOs === DEVICE_OS_IOS && (
                <FormGroup
                    label={<Trans>ZIP/TAR file</Trans>}
                    className="mb-4"
                    htmlFor="zipFile"
                >
                    <FormUpload
                        id="zipFile"
                        accept=".zip,.tar.gz,application/tar+gzip,.tar,.gz"
                        disabled={isSubmitting}
                        errors={getErrorMessages('zipFile')}
                        onChange={(e) =>
                            handleChange('zipFile', e.target.files[0])
                        }
                    />
                </FormGroup>
            )}
            <ModalFooter>
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>

                <Button onClick={onSubmit} loading={isSubmitting}>
                    <Trans>Create build</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    )
}
