import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageHeader from '../../layout/PageHeader'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import EmptyPageCard from '../../utils/EmptyPageCard'
import AuditsPageTable from './AuditsPageTable'
import AuditsPageGrid from './AuditsPageGrid'
import AuditsPageActions from './AuditsPageActions'
import PageLoading from '../../utils/PageLoading'

const AuditsSubtitleContainer = ({ title, children }) => (
    <div className="mb-8">
        <h2 className="mb-4 text-sm font-semibold text-slate-900">{title}</h2>
        {children}
    </div>
)

const AuditsPage = ({
    selectedType,
    selectedListStyle,
    selectedTag,
    search,
    tags,
    audits,
    myFavoriteAudits,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onDeleteAuditCompleted,
    onMarkAsFavoriteCompleted,
    onSearch,
    onChangeTag,
    onChangeListStyle,
    onChangeSelectedType,
    onCreateAudit,
}) => (
    <PageContainer data-testid="AuditsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <PageContent>
                <PageHeader
                    title={<Trans>Audits</Trans>}
                    rightChildren={
                        <Button onClick={onCreateAudit}>
                            <Trans>Create new audit</Trans>
                        </Button>
                    }
                />
                <AuditsPageActions
                    tags={tags}
                    search={search}
                    selectedListStyle={selectedListStyle}
                    selectedTag={selectedTag}
                    selectedType={selectedType}
                    onSearch={onSearch}
                    onChangeTag={onChangeTag}
                    onChangeListStyle={onChangeListStyle}
                    onChangeSelectedType={onChangeSelectedType}
                />
                {(() => {
                    if (isFetching) {
                        return <PageLoading />
                    }
                    const noFiltersActive =
                        search === '' &&
                        selectedTag === 'ALL' &&
                        selectedType === 'ALL'
                    if (audits.length === 0 && noFiltersActive) {
                        return (
                            <EmptyPageCard
                                iconName="clock"
                                title={<Trans>No audits</Trans>}
                                description={
                                    <Trans>
                                        No audits were found for this
                                        organization.
                                        <br />
                                        Start by adding new audits here.
                                    </Trans>
                                }
                            >
                                <Link to="/audits/create">
                                    <Button className="mt-6">
                                        <Trans>Create new audit</Trans>
                                    </Button>
                                </Link>
                            </EmptyPageCard>
                        )
                    }
                    const hasFilters = !noFiltersActive
                    const hasNoFavorites = myFavoriteAudits?.length === 0
                    if (hasNoFavorites || hasFilters) {
                        if (selectedListStyle === 'LIST') {
                            return (
                                <AuditsPageTable
                                    audits={audits}
                                    onDeleteAuditCompleted={
                                        onDeleteAuditCompleted
                                    }
                                    onMarkAsFavoriteCompleted={
                                        onMarkAsFavoriteCompleted
                                    }
                                />
                            )
                        }
                        return (
                            <AuditsPageGrid
                                audits={audits}
                                isFetchingMore={isFetchingMore}
                                onFetchMore={onFetchMore}
                                hasMoreRecords={hasMoreRecords}
                                onDeleteAuditCompleted={onDeleteAuditCompleted}
                                onMarkAsFavoriteCompleted={
                                    onMarkAsFavoriteCompleted
                                }
                            />
                        )
                    }
                    if (selectedListStyle === 'LIST') {
                        return (
                            <>
                                <AuditsSubtitleContainer
                                    title={<Trans>Favorites</Trans>}
                                >
                                    <AuditsPageTable
                                        hideFavoriteStar
                                        audits={myFavoriteAudits}
                                        onDeleteAuditCompleted={
                                            onDeleteAuditCompleted
                                        }
                                        onMarkAsFavoriteCompleted={
                                            onMarkAsFavoriteCompleted
                                        }
                                    />
                                </AuditsSubtitleContainer>
                                <AuditsSubtitleContainer
                                    title={<Trans>All audits</Trans>}
                                >
                                    <AuditsPageTable
                                        audits={audits}
                                        isFetchingMore={isFetchingMore}
                                        onFetchMore={onFetchMore}
                                        hasMoreRecords={hasMoreRecords}
                                        onDeleteAuditCompleted={
                                            onDeleteAuditCompleted
                                        }
                                        onMarkAsFavoriteCompleted={
                                            onMarkAsFavoriteCompleted
                                        }
                                    />
                                </AuditsSubtitleContainer>
                            </>
                        )
                    }
                    return (
                        <>
                            <AuditsSubtitleContainer
                                title={<Trans>Favorites</Trans>}
                            >
                                <AuditsPageGrid
                                    hideFavoriteStar
                                    audits={myFavoriteAudits}
                                    onDeleteAuditCompleted={
                                        onDeleteAuditCompleted
                                    }
                                    onMarkAsFavoriteCompleted={
                                        onMarkAsFavoriteCompleted
                                    }
                                />
                            </AuditsSubtitleContainer>
                            <AuditsSubtitleContainer
                                title={<Trans>All audits</Trans>}
                            >
                                <AuditsPageGrid
                                    audits={audits}
                                    isFetchingMore={isFetchingMore}
                                    onFetchMore={onFetchMore}
                                    hasMoreRecords={hasMoreRecords}
                                    onDeleteAuditCompleted={
                                        onDeleteAuditCompleted
                                    }
                                    onMarkAsFavoriteCompleted={
                                        onMarkAsFavoriteCompleted
                                    }
                                />
                            </AuditsSubtitleContainer>
                        </>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default AuditsPage
