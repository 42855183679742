import React from 'react'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import Duration from '../../utils/Duration'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableRow from '../../utils/TableRow'
import Tooltip from '../../utils/Tooltip'
import Avatar from '../../utils/Avatar'
import AlertCountTag from '../../utils/AlertCountTag'
import { getScanStatusColor } from '../../../utils/entities'
import * as constants from '../../../constants'
import LoadingIndicator from '../../utils/LoadingIndicator'
import useDrawerStack from '../../hooks/useDrawerStack'
import ScanDetailsDrawer from '../scans/ScanDetailsDrawer'
import ActionButton from '../../utils/ActionButton'
import ScanPageActionsDropdownMenu from './ScanPageActionsDropdownMenu'
import { ScanDurationCell } from '../scans/ScanPageTableRow'
import Icon from '../../utils/Icon'

const {
    SCAN_PAGE_STATUS_IN_PROGRESS: IN_PROGRESS,
    SCAN_PAGE_STATUS_FAILED: FAILED,
} = constants

const FRAGMENT = gql`
    fragment StaffScansPageTableRow on Scan {
        id
        url
        name
        isWeb
        isMobile
        duration
        status
        auditScenario {
            id
            name
        }
        consentProfile {
            name
        }
        consentSettings {
            name
        }
        loginProfile {
            name
        }
        audit {
            id
            name
            imageUrl
        }
        organization {
            id
            name
            imageUrl
        }
        createdAt
        startedAt
        completedAt
        duration
        errorMessages
        criticalAlertsCount
        warningAlertsCount
        issueAlertsCount
    }
`

const StaffScansPageTableRow = ({ scan, onDeleteScanCompleted }) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const {
        id,
        url,
        name,
        isMobile,
        audit,
        organization,
        status,
        createdAt,
        startedAt,
        completedAt,
        duration,
        auditScenario,
        consentProfile,
        consentSettings,
        loginProfile,
        errorMessages,
        criticalAlertsCount,
        warningAlertsCount,
        issueAlertsCount,
    } = scan
    return (
        <TableRow
            isSelectable
            isActive={currentDrawerProps?.vendorId === id}
            selectionValue={scan}
            selectionCriteria={(row) => row.id === id}
            onClick={() => {
                let activeTab = 'pages'
                if (isMobile) {
                    activeTab = 'alerts'
                }
                open(ScanDetailsDrawer, {
                    scanId: scan.id,
                    onDeleteScanCompleted,
                    initialDrawerState: {
                        activeTab,
                    },
                })
            }}
        >
            <TableCellAvatar
                columnId="url"
                size="xs"
                avatarSrc={audit.imageUrl}
                avatarIconName="scan"
                title={url || name}
            />
            <TableCell size="xs" className="text-gray-500">
                {isMobile ? <Icon name="app" /> : <Icon name="globe" />}
            </TableCell>
            <TableCell size="xs">
                <AlertCountTag
                    criticalAlertsCount={criticalAlertsCount}
                    issueAlertsCount={issueAlertsCount}
                    warningAlertsCount={warningAlertsCount}
                />
            </TableCell>
            <TableCell size="xs">
                {consentProfile && <Tag type="gray">{consentProfile.name}</Tag>}
                {auditScenario && <Tag type="gray">{auditScenario.name}</Tag>}
                {loginProfile && <Tag type="gray">{loginProfile.name}</Tag>}
                {consentSettings && (
                    <Tag type="gray">{consentSettings.name}</Tag>
                )}
            </TableCell>
            <TableCell size="xs">
                <Tooltip
                    enabled={status === FAILED}
                    content={errorMessages.join(' ')}
                >
                    <Tag
                        type={getScanStatusColor(status)}
                        className="mr-2 flex items-center capitalize"
                    >
                        {status === IN_PROGRESS && (
                            <LoadingIndicator size={12} className="mr-2" />
                        )}
                        {status.toLowerCase().replace('_', ' ')}
                    </Tag>
                </Tooltip>
            </TableCell>
            <TableCell size="xs">
                <DateTime>{createdAt}</DateTime>
            </TableCell>
            <TableCell size="xs">
                <DateTime timeOnly>{startedAt}</DateTime>
            </TableCell>
            <TableCell size="xs">
                <ScanDurationCell
                    startedAt={startedAt}
                    completedAt={completedAt}
                >
                    {duration}
                </ScanDurationCell>
            </TableCell>
            <TableCell size="xs">
                {organization && (
                    <Avatar
                        src={organization.imageUrl}
                        iconName="building-07"
                        tooltipContent={organization.name}
                    />
                )}
            </TableCell>
            <TableCell className="text-right" size="sm">
                <ScanPageActionsDropdownMenu
                    scan={scan}
                    onDeleteScanCompleted={onDeleteScanCompleted}
                >
                    <ActionButton />
                </ScanPageActionsDropdownMenu>
            </TableCell>
        </TableRow>
    )
}

StaffScansPageTableRow.FRAGMENT = FRAGMENT

export default StaffScansPageTableRow
