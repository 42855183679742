export const COUNTRY_BE = 'BE'
export const COUNTRY_US = 'US'

export const AVAILABLE_COUNTRIES = [COUNTRY_BE, COUNTRY_US]

export const LANGUAGE_NL = 'nl'
export const LANGUAGE_EN = 'en'
export const AVAILABLE_LANGUAGES = [LANGUAGE_NL, LANGUAGE_EN]

export const DATE_FORMAT_EUROPE = 'dd/mm/yyyy'
export const DATE_FORMAT_US = 'mm/dd/yyyy'
export const AVAILABLE_DATE_FORMATS = [DATE_FORMAT_EUROPE, DATE_FORMAT_US]

export const TIME_FORMAT_EUROPE = 'HH:mm'
export const AVAILABLE_TIME_FORMATS = [TIME_FORMAT_EUROPE]

export const TIME_ZONE_EUROPE_BRUSSELS = 'Europe/Brussels'
export const AVAILABLE_TIME_ZONES = [TIME_ZONE_EUROPE_BRUSSELS]

export const DEFAULT_PAGE_SIZE = 25
export const DEFAULT_DATE_FORMAT = DATE_FORMAT_EUROPE
export const DEFAULT_TIME_FORMAT = TIME_FORMAT_EUROPE
export const DEFAULT_TIMEZONE = TIME_ZONE_EUROPE_BRUSSELS
export const DEFAULT_LANGUAGE = LANGUAGE_NL
export const DEFAULT_COUNTRY = COUNTRY_BE

export const USER_TYPE_STAFF = 'STAFF'
export const USER_TYPE_USER = 'USER'
export const AVAILABLE_USER_TYPES = [USER_TYPE_STAFF, USER_TYPE_USER]

export const USER_ROLE_OWNER = 'OWNER'
export const USER_ROLE_MEMBER = 'MEMBER'
export const AVAILABLE_USER_ROLES = [USER_ROLE_OWNER, USER_ROLE_MEMBER]

export const INVITATION_STATUS_PENDING = 'PENDING'
export const INVITATION_STATUS_EXPIRED = 'EXPIRED'
export const INVITATION_STATUS_ACCEPTED = 'ACCEPTED'

export const AUDIT_TYPE_MOBILE = 'MOBILE'
export const AUDIT_TYPE_WEB = 'WEB'

export const SCAN_STATUS_CREATED = 'CREATED'
export const SCAN_STATUS_QUEUED = 'QUEUED'
export const SCAN_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const SCAN_STATUS_COMPLETED = 'COMPLETED'
export const SCAN_STATUS_FAILED = 'FAILED'
export const SCAN_STATUS_STOPPED = 'STOPPED'
export const AVAILABLE_SCAN_STATUSES = [
    SCAN_STATUS_CREATED,
    SCAN_STATUS_QUEUED,
    SCAN_STATUS_IN_PROGRESS,
    SCAN_STATUS_COMPLETED,
    SCAN_STATUS_STOPPED,
    SCAN_STATUS_FAILED,
]

export const SCAN_TRIGGER_AUTOMATED = 'AUTOMATED'
export const SCAN_TRIGGER_MANUAL = 'MANUAL'
export const AVAILABLE_SCAN_TRIGGERS = [
    SCAN_TRIGGER_AUTOMATED,
    SCAN_TRIGGER_MANUAL,
]

export const SCAN_SOURCE_PUPPETEER = 'PUPPETEER'
export const SCAN_SOURCE_HAR = 'HAR'
export const SCAN_SOURCE_APPETIZE = 'APPETIZE'
export const SCAN_SOURCE_CORELLIUM = 'CORELLIUM'

export const AVAILABLE_SCAN_SOURCES = [
    SCAN_SOURCE_PUPPETEER,
    SCAN_SOURCE_HAR,
    SCAN_SOURCE_APPETIZE,
    SCAN_SOURCE_CORELLIUM,
]

export const SCAN_PAGE_STATUS_CREATED = 'CREATED'
export const SCAN_PAGE_STATUS_QUEUED = 'QUEUED'
export const SCAN_PAGE_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const SCAN_PAGE_STATUS_COMPLETED = 'COMPLETED'
export const SCAN_PAGE_STATUS_FAILED = 'FAILED'
export const AVAILABLE_SCAN_PAGE_STATUSES = [
    SCAN_PAGE_STATUS_CREATED,
    SCAN_PAGE_STATUS_QUEUED,
    SCAN_PAGE_STATUS_IN_PROGRESS,
    SCAN_PAGE_STATUS_COMPLETED,
    SCAN_PAGE_STATUS_FAILED,
]

export const COOKIE_TABLE_VERSIONS_STATUS_FINISHED = 'FINISHED'
export const COOKIE_TABLE_VERSIONS_STATUS_COMPARING = 'COMPARING'
export const COOKIE_TABLE_VERSIONS_STATUS_STATUSES = [
    COOKIE_TABLE_VERSIONS_STATUS_FINISHED,
    COOKIE_TABLE_VERSIONS_STATUS_COMPARING,
]

export const COOKIE_TABLE_ITEM_STATUS_ADDED = 'ADDED'
export const COOKIE_TABLE_ITEM_STATUS_DEPRECATED = 'DEPRECATED'
export const COOKIE_TABLE_ITEM_STATUS_STATUSES = [
    COOKIE_TABLE_ITEM_STATUS_ADDED,
    COOKIE_TABLE_ITEM_STATUS_DEPRECATED,
]

export const VENDOR_TABLE_VERSIONS_STATUS_FINISHED = 'FINISHED'
export const VENDOR_TABLE_VERSIONS_STATUS_COMPARING = 'COMPARING'
export const VENDOR_TABLE_VERSIONS_STATUS_STATUSES = [
    VENDOR_TABLE_VERSIONS_STATUS_FINISHED,
    VENDOR_TABLE_VERSIONS_STATUS_COMPARING,
]

export const VENDOR_TABLE_ITEM_STATUS_ADDED = 'ADDED'
export const VENDOR_TABLE_ITEM_STATUS_DEPRECATED = 'DEPRECATED'
export const VENDOR_TABLE_ITEM_STATUS_STATUSES = [
    VENDOR_TABLE_ITEM_STATUS_ADDED,
    VENDOR_TABLE_ITEM_STATUS_DEPRECATED,
]

export const REPORT_STATUS_CREATED = 'CREATED'
export const REPORT_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const REPORT_STATUS_COMPLETED = 'COMPLETED'
export const REPORT_STATUS_FAILED = 'FAILED'
export const AVAILABLE_REPORT_STATUSES = [
    REPORT_STATUS_CREATED,
    REPORT_STATUS_IN_PROGRESS,
    REPORT_STATUS_COMPLETED,
    REPORT_STATUS_FAILED,
]

export const AUDIT_SCAN_FREQUENCY_DISABLED = 'DISABLED'
export const AUDIT_SCAN_FREQUENCY_DAILY = 'DAILY'
export const AUDIT_SCAN_FREQUENCY_WEEKLY = 'WEEKLY'
export const AUDIT_SCAN_FREQUENCY_MONTHLY = 'MONTHLY'
export const AVAILABLE_AUDIT_SCAN_FREQUENCIES = [
    AUDIT_SCAN_FREQUENCY_DISABLED,
    AUDIT_SCAN_FREQUENCY_DAILY,
    AUDIT_SCAN_FREQUENCY_WEEKLY,
    AUDIT_SCAN_FREQUENCY_MONTHLY,
]

export const DOMAIN_CONSENT_RESOLVER_CSS = 'CSS'
export const DOMAIN_CONSENT_RESOLVER_RUNTIME = 'RUNTIME'
export const DOMAIN_CONSENT_RESOLVER_COOKIE = 'COOKIE'
export const AVAILABLE_DOMAIN_CONSENT_RESOLVERS = [
    DOMAIN_CONSENT_RESOLVER_CSS,
    DOMAIN_CONSENT_RESOLVER_RUNTIME,
    DOMAIN_CONSENT_RESOLVER_COOKIE,
]

export const CONSENT_PROFILE_ACCEPT_ALL = 'accept-all'
export const CONSENT_PROFILE_REJECT_ALL = 'reject-all'
export const CONSENT_PROFILE_CUSTOM = 'custom'

export const COOKIE_TYPE_COOKIE = 'COOKIE'
export const COOKIE_TYPE_LOCAL_STORAGE = 'LOCAL_STORAGE'
export const AVAILABLE_COOKIE_TYPES = [
    COOKIE_TYPE_COOKIE,
    COOKIE_TYPE_LOCAL_STORAGE,
]

export const TRACKER_RESOURCE_TYPE_SCRIPT = 'Script'
export const TRACKER_RESOURCE_TYPE_STYLESHEET = 'Stylesheet'
export const TRACKER_RESOURCE_TYPE_DOCUMENT = 'Document'
export const TRACKER_RESOURCE_TYPE_REDIRECT = 'REDIRECT'
export const TRACKER_RESOURCE_TYPE_IMAGE = 'Image'
export const TRACKER_RESOURCE_TYPE_FONT = 'Font'
export const TRACKER_RESOURCE_TYPE_PING = 'Ping'
export const TRACKER_RESOURCE_TYPE_XHR = 'XHR'
export const TRACKER_RESOURCE_TYPE_OTHER = 'Other'
export const AVAILABLE_TRACKER_RESOURCE_TYPES = [
    TRACKER_RESOURCE_TYPE_SCRIPT,
    TRACKER_RESOURCE_TYPE_STYLESHEET,
    TRACKER_RESOURCE_TYPE_DOCUMENT,
    TRACKER_RESOURCE_TYPE_REDIRECT,
    TRACKER_RESOURCE_TYPE_IMAGE,
    TRACKER_RESOURCE_TYPE_FONT,
    TRACKER_RESOURCE_TYPE_PING,
    TRACKER_RESOURCE_TYPE_XHR,
    TRACKER_RESOURCE_TYPE_OTHER,
]

export const IDENTIFIED_PERIOD_LAST_DAY = 'LAST_DAY'
export const IDENTIFIED_PERIOD_LAST_7_DAYS = 'LAST_7_DAYS'
export const IDENTIFIED_PERIOD_LAST_14_DAYS = 'LAST_14_DAYS'
export const IDENTIFIED_PERIOD_LAST_30_DAYS = 'LAST_30_DAYS'
export const IDENTIFIED_PERIOD_THIS_YEAR = 'THIS_YEAR'
export const IDENTIFIED_PERIOD_DAY_SUMMARY = 'DAY_SUMMARY'
export const IDENTIFIED_PERIOD_YEAR_SUMMARY = 'YEAR_SUMMARY'
export const IDENTIFIED_PERIOD_MONTH_SUMMARY = 'MONTH_SUMMARY'
export const IDENTIFIED_PERIOD_CUSTOM = 'CUSTOM'
export const AVAILABLE_IDENTIFIED_PERIODS = [
    IDENTIFIED_PERIOD_LAST_DAY,
    IDENTIFIED_PERIOD_LAST_7_DAYS,
    IDENTIFIED_PERIOD_LAST_14_DAYS,
    IDENTIFIED_PERIOD_LAST_30_DAYS,
    IDENTIFIED_PERIOD_THIS_YEAR,
    IDENTIFIED_PERIOD_YEAR_SUMMARY,
    IDENTIFIED_PERIOD_MONTH_SUMMARY,
    IDENTIFIED_PERIOD_DAY_SUMMARY,
    IDENTIFIED_PERIOD_CUSTOM,
]

export const IDENTIFIED_PERIOD_LAST_30_DAYS_OPTION = 'last-30'
export const IDENTIFIED_PERIOD_LAST_14_DAYS_OPTION = 'last-14'
export const IDENTIFIED_PERIOD_LAST_7_DAYS_OPTION = 'last-7'
export const IDENTIFIED_PERIOD_LAST_DAY_OPTION = 'last-day'
export const IDENTIFIED_PERIOD_DAY_OPTION = 'day'
export const IDENTIFIED_PERIOD_MONTH_OPTION = 'month'
export const IDENTIFIED_PERIOD_CUSTOM_OPTION = 'period'

export const IDENTIFIED_PERIODS = {
    [IDENTIFIED_PERIOD_LAST_DAY_OPTION]: IDENTIFIED_PERIOD_LAST_DAY,
    [IDENTIFIED_PERIOD_LAST_7_DAYS_OPTION]: IDENTIFIED_PERIOD_LAST_7_DAYS,
    [IDENTIFIED_PERIOD_LAST_14_DAYS_OPTION]: IDENTIFIED_PERIOD_LAST_14_DAYS,
    [IDENTIFIED_PERIOD_LAST_30_DAYS_OPTION]: IDENTIFIED_PERIOD_LAST_30_DAYS,
    [IDENTIFIED_PERIOD_DAY_OPTION]: IDENTIFIED_PERIOD_DAY_SUMMARY,
    [IDENTIFIED_PERIOD_MONTH_OPTION]: IDENTIFIED_PERIOD_MONTH_SUMMARY,
    [IDENTIFIED_PERIOD_CUSTOM_OPTION]: IDENTIFIED_PERIOD_CUSTOM,
}

export const USER_STATUS_NEW = 'NEW'
export const USER_STATUS_ACTIVE = 'ACTIVE'

export const REQUEST_PASSWORD_TYPE_RESET = 'RESET'
export const REQUEST_PASSWORD_TYPE_INVITE = 'INVITE'

export const ORDER_BY_ASC = 'ASC'
export const ORDER_BY_DESC = 'DESC'

export const WAIT_FOR_CSS_SELECTOR = 'WAIT_FOR_CSS_SELECTOR'
export const WAIT_FOR_XPATH_SELECTOR = 'WAIT_FOR_XPATH_SELECTOR'
export const WAIT_FOR_NAVIGATION = 'WAIT_FOR_NAVIGATION'
export const CLICK_CSS_SELECTOR = 'CLICK_CSS_SELECTOR'
export const CLICK_XPATH_SELECTOR = 'CLICK_XPATH_SELECTOR'
export const WAIT_FOR_COOKIE_NAME = 'WAIT_FOR_COOKIE_NAME'
export const SELECT_FRAME = 'SELECT_FRAME'
export const ENTER_EMAIL = 'ENTER_EMAIL'
export const ENTER_PASSWORD = 'ENTER_PASSWORD'

export const EXTERNAL_ID_NOT_FOUND = 'EXTERNAL_ID_NOT_FOUND'
export const BULK_UPLOAD_VALIDATION_WARNINGS = [EXTERNAL_ID_NOT_FOUND]
export const MISSING_FIELD = 'MISSING_FIELD'
export const INVALID_FIELD = 'INVALID_FIELD'
export const BULK_UPLOAD_VALIDATION_ERRORS = [MISSING_FIELD, INVALID_FIELD]

export const FOCUS_NEW_TAB = 'FOCUS_NEW_TAB'
export const RUN_CONSOLE_COMMANDS = 'RUN_CONSOLE_COMMANDS'

export const DEVICE_OS_ANDROID = 'ANDROID'
export const DEVICE_OS_IOS = 'IOS'

export const AVAILABLE_DEVICE_OSS = [DEVICE_OS_ANDROID, DEVICE_OS_IOS]
export const READABLE_DEVICE_OSS = {
    [DEVICE_OS_ANDROID]: 'Android',
    [DEVICE_OS_IOS]: 'iOS',
}
