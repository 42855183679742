/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

export default function AuditSettingsPageAuditScenarioTableRowActionButtons({
    isLoading,
    canDeleteAuditScenario,
    onDeleteAuditScenario,
}) {
    return (
        <DropdownMenu
            position="bottom-start"
            content={({ close }) => (
                <DropdownMenuItem
                    iconName="trash-03"
                    disabled={!canDeleteAuditScenario}
                    onClick={(e) => {
                        onDeleteAuditScenario()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Delete scenario</Trans>
                </DropdownMenuItem>
            )}
        >
            <ActionButton disabled={isLoading} />
        </DropdownMenu>
    )
}
