import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import AlertCountTag from '../../utils/AlertCountTag'
import PurposesTags from '../../utils/PurposesTags'
import { useCurrentDrawerStack } from '../../hooks/useDrawerStack'
import IdentifiedCookiesDrawer from '../vendors-cookies/IdentifiedCookieDetailsDrawer'
import * as constants from '../../../constants'
import { useIdentifiedVendorDrawerCookiesQuery } from './useIdentifiedVendorDrawerTabCookies'
import InfiniteScroll from '../../utils/InfiniteScroll'
import DrawerTabError from '../../utils/DrawerTabError'
import useSearch from '../../hooks/useSearch'
import SearchInput from '../../utils/SearchInput'

const { APPLICATION_LEVEL_AUDIT } = constants

export default function IdentifiedVendorDrawerTabCookies({
    vendorId,
    auditId,
    period,
}) {
    const [search, handleSearch] = useSearch('')
    const { period: periodString, startDate, endDate } = period
    const {
        identifiedCookies,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        error,
    } = useIdentifiedVendorDrawerCookiesQuery({
        search,
        filters: {
            vendorId,
            period: periodString,
            startDate,
            endDate,
        },
    })
    const { add } = useCurrentDrawerStack()
    if (error) return <DrawerTabError error={error} />
    if (!isFetching && (!identifiedCookies || identifiedCookies.length === 0)) {
        return (
            <DrawerEmptyCard
                iconName="cookie"
                title={<Trans>No Cookies</Trans>}
                description={<Trans>There were no cookies found</Trans>}
            />
        )
    }
    return (
        <div>
            <SearchInput
                id="identifiedVendorDrawerTabCookiesSearch"
                value={search}
                onChange={handleSearch}
                className="mb-3 w-full"
            />
            <InfiniteScroll
                isFetchingMore={isFetchingMore}
                onFetchMore={handleFetchMore}
                hasMoreRecords={hasMore}
            >
                <Table
                    isFetching={isFetching}
                    hasMinWidth={false}
                    isRounded
                    hasHeader={false}
                >
                    <TableBody>
                        {identifiedCookies.map((identifiedCookie) => {
                            const { id, name, alerts, cookie } =
                                identifiedCookie
                            return (
                                <TableRow
                                    isSelectable
                                    key={id}
                                    onClick={() => {
                                        add(IdentifiedCookiesDrawer, {
                                            auditId,
                                            level: APPLICATION_LEVEL_AUDIT,
                                            identifiedCookieId:
                                                identifiedCookie.id,
                                        })
                                    }}
                                >
                                    <TableCellAvatar
                                        py="sm"
                                        px="sm"
                                        truncate
                                        avatarIconName="cookie"
                                        className="w-2/3"
                                        title={name}
                                        text={
                                            identifiedCookie.rootDomain ||
                                            'No Domain'
                                        }
                                    />
                                    <TableCell py="sm">
                                        <div className="flex justify-end gap-1">
                                            <PurposesTags
                                                showAll
                                                purposes={
                                                    cookie?.purposes || []
                                                }
                                            />
                                            <AlertCountTag alerts={alerts} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </InfiniteScroll>
        </div>
    )
}
