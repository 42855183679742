import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import SearchInput from '../../utils/SearchInput'
import useSearch from '../../hooks/useSearch'
import GroupedAlertObservationTableRow from './GroupedAlertObservationTableRow'

export default function GroupedAlertDetailsDrawerTabObservation({
    auditId,
    audits,
    pages = [],
}) {
    const [search, handleSearch] = useSearch('')

    let finalPages = pages
    let finalAudits = audits

    if (search) {
        const lowercasedSearch = search.toLowerCase()
        finalPages = finalPages.filter(
            (page) =>
                page.id.toLowerCase().includes(lowercasedSearch) ||
                page.url?.toLowerCase().includes(lowercasedSearch) ||
                page.scanPages.some(({ scan }) =>
                    scan.id.toLowerCase().includes(lowercasedSearch)
                )
        )
        finalAudits = audits.filter((audit) =>
            finalPages.some(({ auditIds }) => auditIds.includes(audit.id))
        )
    }
    if (!alert) {
        return (
            <>
                <div className="mb-4 flex w-full flex-row gap-4">
                    <SearchInput
                        className="w-full"
                        onChange={handleSearch}
                        value={search}
                    />
                </div>
                <DrawerEmptyCard
                    iconName="check-circle"
                    title={<Trans>No Observations</Trans>}
                    description={
                        <Trans>
                            Needs to be implemented, we are working on it
                        </Trans>
                    }
                />
            </>
        )
    }
    return (
        <>
            <div className="mb-4 flex w-full flex-row gap-4">
                <SearchInput
                    className="w-full"
                    onChange={handleSearch}
                    value={search}
                />
            </div>
            <Table hasMinWidth={false}>
                <thead>
                    <TableHeaderRow>
                        <TableHeaderCell />
                        <TableHeaderCell columnId="rootDomain">
                            <Trans>Domain</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell>Alerts</TableHeaderCell>
                    </TableHeaderRow>
                </thead>
                <TableBody>
                    {finalAudits.map((audit) => (
                        <GroupedAlertObservationTableRow
                            audit={audit}
                            pages={pages}
                        />
                    ))}
                </TableBody>
            </Table>
        </>
    )
}
