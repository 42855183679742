import gql from 'graphql-tag'
import { useQuery } from '@apollo/client/index'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'

const SCAN_PAGE_REQUESTS_QUERY = gql`
    query scanPageDetailRequestsDrawer(
        $scanId: ID
        $scanPageId: ID
        $search: String
        $offset: Int
        $filters: ScanRequestsFilters
    ) {
        scanRequests(
            scanId: $scanId
            scanPageId: $scanPageId
            search: $search
            offset: $offset
            filters: $filters
        ) {
            edges {
                cursor
                node {
                    id
                    method
                    resourceType
                    url
                    search
                    hasPostData
                    alerts {
                        id
                        type
                        severityType
                        message
                    }
                    tracker {
                        id
                        domain
                        purposes {
                            id
                            name
                        }
                        vendor {
                            id
                            name
                            imageUrl
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export default function useScanPageDetailRequestsDrawerQuery({
    scanId,
    scanPageId,
    search,
    filters,
}) {
    const variables = { scanId, scanPageId, search, filters }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(SCAN_PAGE_REQUESTS_QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'scanRequests'
    )

    return {
        error,
        requests: connectionToCollection(data.scanRequests),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}
