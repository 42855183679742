import React from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../utils/validation'
import Button from './Button'
import Modal from './Modal'
import ModalTitle from './ModalTitle'
import ModalFooter from './ModalFooter'
import FormGroup from './FormGroup'
import FormTextarea from './FormTextarea'
import FormRangeInput from './FormRangeInput'
import useNotifications from '../hooks/useNotifications'

const validation = createFormValidation([
    {
        path: 'score',
        validate: isRequired,
        message: <Trans>The score is required</Trans>,
    },
    {
        path: 'comment',
        validate: isRequired,
        message: <Trans>The comment is required</Trans>,
    },
])

const MUTATION = gql`
    mutation ($feedbackId: ID!, $score: Int!, $comment: String) {
        staffLogFeedback(
            feedbackId: $feedbackId
            score: $score
            comment: $comment
        )
    }
`

const StaffFeedbackModal = ({
    feedbackId,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [sendFeedback, { loading: isSending }] = useMutation(MUTATION, {
        onCompleted,
        onError,
    })
    const formState = useFormState(
        {
            score: 5,
            comment: '',
        },
        {
            validation,
        }
    )
    return (
        <Modal onDismiss={onDismiss} className="max-w-lg">
            <ModalTitle
                title={<Trans>Send feedback</Trans>}
                description={
                    <Trans>
                        You can give feedback about the generated response.
                    </Trans>
                }
                onClose={onDismiss}
            />
            <FormGroup
                label={
                    <div className="flex items-center gap-2">
                        <div>
                            <Trans>Score</Trans>
                        </div>
                        <div>{formState.values.score}</div>
                    </div>
                }
                htmlFor="score"
            >
                <FormRangeInput
                    id="score"
                    min={0}
                    max={10}
                    step={1}
                    errors={formState.getErrorMessages('score')}
                    {...formState.getNativeInputProps('score')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Comment</Trans>} htmlFor="comment">
                <FormTextarea
                    id="comment"
                    rows={4}
                    errors={formState.getErrorMessages('comment')}
                    {...formState.getNativeInputProps('comment')}
                />
            </FormGroup>
            <ModalFooter>
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    onClick={async () => {
                        if (formState.validate()) {
                            try {
                                const { score, comment } = formState.values
                                await sendFeedback({
                                    variables: {
                                        feedbackId,
                                        score: parseInt(score, 10),
                                        comment,
                                    },
                                })
                                formState.resetForm()
                                onDismiss()
                                dispatchSuccess({
                                    message: (
                                        <Trans>
                                            Successfully sent feedback!
                                        </Trans>
                                    ),
                                })
                            } catch (e) {
                                dispatchGraphqlError(e)
                            }
                        }
                    }}
                    loading={isSending}
                >
                    <Trans>Send</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default StaffFeedbackModal
