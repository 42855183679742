import { useContext } from 'react'
import {
    DrawerStackContext,
    CurrentDrawerStackContext,
} from '../providers/DrawerStackProvider'

const useDrawerStack = () => useContext(DrawerStackContext)
export const useCurrentDrawerStack = () => useContext(CurrentDrawerStackContext)

export default useDrawerStack
