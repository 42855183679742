import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageHeader from '../../layout/PageHeader'
import ConsentProfilesPageTable from './ConsentProfilesPageTable'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import SettingsPagePageSidebar from '../settings/SettingsPageSidebar'

const ConsentProfilesPage = ({
    consentProfiles = [],
    isFetchingMore,
    isFetching,
    hasMoreRecords,
    onFetchMore,
    onDeleteCompleted,
    onCreate,
}) => (
    <PageContainer data-testid="SettingsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <SettingsPagePageSidebar />
            <PageContent isFetching={isFetching}>
                {(() => {
                    return (
                        <>
                            <PageHeader
                                className="pb-4"
                                title={<Trans>Consent profiles</Trans>}
                                description={
                                    <Trans>
                                        Specify a specific set of user choices
                                        for the consent management platform
                                        (CMP) of your domain. You can then
                                        assign a consent profiles to a audit in
                                        the ‘Domains & Profiles’ tab of that
                                        audit.{' '}
                                    </Trans>
                                }
                                rightChildren={
                                    <Button onClick={onCreate}>
                                        <Trans>Create consent profile</Trans>
                                    </Button>
                                }
                            />
                            {(() => {
                                if (consentProfiles.length === 0) {
                                    return (
                                        <EmptyPageCard
                                            iconName="settings"
                                            title={
                                                <Trans>
                                                    No consent profiles
                                                </Trans>
                                            }
                                            description={
                                                <Trans>
                                                    No consent profiles were
                                                    found for this organization.
                                                    <br />
                                                    Start by adding new consent
                                                    profiles here.
                                                </Trans>
                                            }
                                        />
                                    )
                                }
                                return (
                                    <ConsentProfilesPageTable
                                        consentProfiles={consentProfiles}
                                        isFetchingMore={isFetchingMore}
                                        hasMoreRecords={hasMoreRecords}
                                        onFetchMore={onFetchMore}
                                        onDeleteCompleted={onDeleteCompleted}
                                    />
                                )
                            })()}
                        </>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default ConsentProfilesPage
