import React from 'react'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import AuditAlertsPage from './AuditDetailsPage'
import useAuditAlertsPageQuery from './useAuditDetailsPageQuery'

const AuditDetailsPageWithState = () => {
    useCloseDrawerOnLeave()
    const { audit, scans, isFetching } = useAuditAlertsPageQuery()
    return (
        <AuditAlertsPage isFetching={isFetching} audit={audit} scans={scans} />
    )
}

export default AuditDetailsPageWithState
