import React from 'react'
import Tag from './Tag'
import Tooltip from './Tooltip'
import { getPageStatusColor } from '../../utils/entities'
import * as constants from '../../constants'

const { SCAN_PAGE_STATUS_FAILED: FAILED } = constants

export default function ScanStatusTag({ scanPage, ...props }) {
    const { status, errorMessages } = scanPage
    return (
        <Tooltip enabled={status === FAILED} content={errorMessages.join(' ')}>
            <Tag
                type={getPageStatusColor(status)}
                className="capitalize"
                {...props}
            >
                {status.toLowerCase().replace('_', ' ')}
            </Tag>
        </Tooltip>
    )
}
