import React from 'react'
import useSearch from '../hooks/useSearch'
import useAuditsPageQuery from '../pages/audits/useAuditsPageQuery'
import AuditDropdown from './AuditHeaderSwitcherDropdown'

export default function AuditDropdownWithState({ currentAudit }) {
    const [search, handleSearch] = useSearch()
    const { audits, isFetching, isFetchingMore, handleFetchMore, hasMore } =
        useAuditsPageQuery({ search })
    return (
        <AuditDropdown
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            currentAudit={currentAudit}
            onSearch={handleSearch}
            audits={audits}
        />
    )
}
