import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableHeaderCell from '../../utils/TableHeaderCell'
import EmptyTable from '../../utils/EmptyTable'
import AuditSettingsPageAuditBuildTableRow from './AuditSettingsPageAuditBuildTableRow'

export function AuditSettingsPageAuditBuildsTable({
    auditBuilds = [],
    onDeleteAuditBuildCompleted,
}) {
    return (
        <Table data-testid="AuditSettingsPageBuildsTable">
            <thead>
                <tr>
                    <TableHeaderCell>
                        <Trans>App</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <Trans>Version</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <Trans>Device OS</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell />
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {auditBuilds.length > 0 ? (
                    auditBuilds.map((build) => (
                        <AuditSettingsPageAuditBuildTableRow
                            key={build.id}
                            auditBuild={build}
                            onDeleteAuditBuildCompleted={
                                onDeleteAuditBuildCompleted
                            }
                        />
                    ))
                ) : (
                    <EmptyTable
                        colSpan={4}
                        title={<Trans>No builds</Trans>}
                        description={
                            <Trans>No builds were found for this audit.</Trans>
                        }
                    />
                )}
            </tbody>
        </Table>
    )
}
