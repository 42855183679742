import React from 'react'
import { Trans } from '@lingui/macro'
import AnimatedTabs, { AnimatedTab } from '../../utils/AnimatedTabs'

const AlertStatusFilterButtonGroup = ({
    selectedAlertStatus,
    totalAlertsCount,
    ignoredAlertsCount,
    reviewedAlertsCount,
    onFilterAlerts,
}) => (
    <AnimatedTabs activeTab={selectedAlertStatus}>
        <AnimatedTab name="ONGOING" onClick={() => onFilterAlerts('ONGOING')}>
            <span>
                <Trans>Alerts</Trans>
                {totalAlertsCount && (
                    <span className="ml-1">({totalAlertsCount})</span>
                )}
            </span>
        </AnimatedTab>
        <AnimatedTab name="IGNORED" onClick={() => onFilterAlerts('IGNORED')}>
            <span>
                <Trans>Ignored</Trans>
                {ignoredAlertsCount && (
                    <span className="ml-1">({ignoredAlertsCount})</span>
                )}
            </span>
        </AnimatedTab>
        <AnimatedTab name="REVIEWED" onClick={() => onFilterAlerts('REVIEWED')}>
            <span>
                <Trans>Delegated</Trans>
                {reviewedAlertsCount && (
                    <span className="ml-1">({reviewedAlertsCount})</span>
                )}
            </span>
        </AnimatedTab>
    </AnimatedTabs>
)

export default AlertStatusFilterButtonGroup
