import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import Avatar from '../utils/Avatar'
import LoadingIndicator from '../utils/LoadingIndicator'
import SearchInput from '../utils/SearchInput'
import Icon from '../utils/Icon'
import Tag from '../utils/Tag'
import InfiniteScroll from '../utils/InfiniteScroll'

export default function AuditDropdown({
    currentAudit,
    audits,
    isFetching,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    search,
    onSearch,
}) {
    const location = useLocation()
    const parts = location.pathname.split('/')
    const finalParts = parts.slice(3)
    return (
        <>
            <div className="px-2 pb-2 pt-1">
                <SearchInput value={search} onChange={onSearch} />
            </div>
            <div className="h-[200px] overflow-auto border-b border-t border-slate-200">
                {isFetching && (
                    <div className="flex h-full w-full items-center justify-center">
                        <LoadingIndicator />
                    </div>
                )}
                {!isFetching && (
                    <InfiniteScroll
                        isFetchingMore={isFetchingMore}
                        onFetchMore={onFetchMore}
                        hasMoreRecords={hasMoreRecords}
                    >
                        <ul className="py-2">
                            {audits.map((audit) => {
                                const isCurrent = currentAudit.id === audit.id
                                return (
                                    <li>
                                        <Link
                                            to={`/audits/${
                                                audit.id
                                            }/${finalParts.join('/')}`}
                                        >
                                            <button
                                                type="button"
                                                className={clsx(
                                                    'flex w-full items-center justify-between px-4 py-2 hover:bg-slate-100',
                                                    !isCurrent &&
                                                        'hover:bg-slate-100',
                                                    isCurrent && 'bg-slate-100'
                                                )}
                                            >
                                                <div className="flex items-center gap-2">
                                                    <Avatar
                                                        size="sm"
                                                        iconName={
                                                            audit.isMobile
                                                                ? 'app'
                                                                : 'domain'
                                                        }
                                                        src={audit.imageUrl}
                                                    />
                                                    <div className="max-w-44 truncate">
                                                        {audit.name}
                                                    </div>
                                                </div>
                                                {isCurrent && (
                                                    <Tag>Current</Tag>
                                                )}
                                            </button>
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </InfiniteScroll>
                )}
            </div>
            <Link to="/audits/create">
                <button
                    type="button"
                    className="flex w-full items-center gap-2 px-4 py-3 text-slate-600 hover:bg-slate-100"
                >
                    <Icon name="plus" />
                    <Trans>Create Audit</Trans>
                </button>
            </Link>
        </>
    )
}
