import React from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import Duration from '../../utils/Duration'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableRow from '../../utils/TableRow'
import Tooltip from '../../utils/Tooltip'
import AlertCountTag from '../../utils/AlertCountTag'
import { getScanStatusColor } from '../../../utils/entities'
import * as constants from '../../../constants'
import LoadingIndicator from '../../utils/LoadingIndicator'
import useDrawerStack from '../../hooks/useDrawerStack'
import ScanDetailsDrawer from '../scans/ScanDetailsDrawer'
import CodeScanDetailsDrawer from '../scans/CodeScanDetailsDrawer'
import { APPLICATION_LEVEL_AUDIT } from '../../../constants'
import ScanPageActionsDropdownMenu from '../staff-scans/ScanPageActionsDropdownMenu'
import ActionButton from '../../utils/ActionButton'
import { ScanDurationCell } from '../scans/ScanPageTableRow'

const {
    SCAN_PAGE_STATUS_IN_PROGRESS: IN_PROGRESS,
    SCAN_PAGE_STATUS_FAILED: FAILED,
} = constants

const FRAGMENT = gql`
    fragment AuditScansPageTableRow on Scan {
        id
        url
        name
        isWeb
        isMobile
        type
        duration
        status
        auditScenario {
            id
            name
        }
        consentProfile {
            id
            name
        }
        consentSettings {
            name
        }
        loginProfile {
            id
            name
        }
        audit {
            id
            name
            imageUrl
        }
        createdAt
        startedAt
        completedAt
        errorMessages
        alertsCount
        criticalAlertsCount
        warningAlertsCount
        issueAlertsCount
    }
`

const AuditScansPageTableRow = ({ scan, audit, onDeleteScanCompleted }) => {
    const { open, compare, currentDrawerProps, isComparing } = useDrawerStack()
    const {
        id,
        url,
        name,
        isWeb,
        isMobile,
        type,
        status,
        createdAt,
        duration,
        auditScenario,
        consentProfile,
        loginProfile,
        errorMessages,
        criticalAlertsCount,
        warningAlertsCount,
        issueAlertsCount,
        consentSettings,
        startedAt,
        completedAt,
    } = scan
    const isActive = currentDrawerProps?.scanId === id
    return (
        <TableRow
            isSelectable
            isActive={isActive}
            selectionValue={scan}
            selectionCriteria={(row) => row.id === id}
            onClick={() => {
                if (isActive) {
                    return
                }
                let activeTab = 'pages'
                if (isMobile) {
                    activeTab = 'alerts'
                }
                if (isComparing) {
                    compare(ScanDetailsDrawer, {
                        scanId: scan.id,
                        onDeleteScanCompleted,
                        initialDrawerState: {
                            activeTab,
                        },
                    })
                } else if (type === 'CODE') {
                    open(CodeScanDetailsDrawer, {
                        scanId: scan.id,
                        onDeleteScanCompleted,
                        level: APPLICATION_LEVEL_AUDIT,
                    })
                } else {
                    open(ScanDetailsDrawer, {
                        scanId: scan.id,
                        onDeleteScanCompleted,
                        level: APPLICATION_LEVEL_AUDIT,
                        initialDrawerState: {
                            activeTab,
                        },
                    })
                }
            }}
        >
            <TableCellAvatar
                columnId="url"
                size="xs"
                avatarSrc={audit?.imageUrl}
                avatarIconName="scan"
                title={url || name}
            />
            <TableCell size="xs">
                <AlertCountTag
                    criticalAlertsCount={criticalAlertsCount}
                    issueAlertsCount={issueAlertsCount}
                    warningAlertsCount={warningAlertsCount}
                />
            </TableCell>
            <TableCell size="xs">
                {consentProfile && <Tag type="gray">{consentProfile.name}</Tag>}
                {auditScenario && <Tag type="gray">{auditScenario.name}</Tag>}
                {loginProfile && <Tag type="gray">{loginProfile.name}</Tag>}
                {consentSettings?.name && (
                    <Tag type="gray">{consentSettings.name}</Tag>
                )}
            </TableCell>
            <TableCell size="xs">
                <Tooltip
                    enabled={status === FAILED}
                    content={errorMessages.join(' ')}
                >
                    <Tag
                        type={getScanStatusColor(status)}
                        className="mr-2 flex items-center capitalize"
                    >
                        {status === IN_PROGRESS && (
                            <LoadingIndicator size={12} className="mr-2" />
                        )}
                        {status.toLowerCase().replace('_', ' ')}
                    </Tag>
                </Tooltip>
            </TableCell>
            <TableCell size="xs">
                <DateTime>{createdAt}</DateTime>
            </TableCell>
            <TableCell size="xs">
                <ScanDurationCell
                    startedAt={startedAt}
                    completedAt={completedAt}
                >
                    {duration}
                </ScanDurationCell>
            </TableCell>
            <TableCell className="text-right" size="sm">
                <ScanPageActionsDropdownMenu
                    scan={scan}
                    onDeleteScanCompleted={onDeleteScanCompleted}
                >
                    <ActionButton />
                </ScanPageActionsDropdownMenu>
            </TableCell>
        </TableRow>
    )
}

AuditScansPageTableRow.FRAGMENT = FRAGMENT

export default AuditScansPageTableRow
