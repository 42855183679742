import React from 'react'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import Icon from '../../utils/Icon'
import Tag from '../../utils/Tag'

function ButtonGroupItem({
    title,
    description,
    iconName,
    onSubmit,
    isDisabled = false,
}) {
    const iconColor = isDisabled ? 'default' : 'primary'
    return (
        <button
            type="button"
            disabled={isDisabled}
            onClick={onSubmit}
            className={clsx(
                'group flex w-full items-center gap-4 rounded-xl border p-4 text-left transition-colors',
                isDisabled
                    ? 'cursor-not-allowed border-gray-100'
                    : 'hover:border-gray-300'
            )}
        >
            <div className="flex items-center justify-center text-gray-400">
                <Icon name={iconName} size="xl" color={iconColor} />
            </div>

            <div className="flex-1">
                <h3 className="font-medium text-slate-900">{title}</h3>
                <p className="text-sm text-slate-600">{description}</p>
            </div>

            <div
                className={clsx(
                    'h-5 w-5 text-slate-400',
                    !isDisabled && 'group-hover:text-slate-900'
                )}
            >
                <Icon name="chevron-right" />
            </div>
        </button>
    )
}

export function CreateAuditModal({ onDismiss }) {
    const history = useHistory()
    return (
        <Modal className="max-w-2xl" onDismiss={onDismiss}>
            <ModalTitle
                title={<Trans>Create new audit</Trans>}
                description={
                    <Trans>What kind of audit would you like to start?</Trans>
                }
                onClose={onDismiss}
            />
            <div className="mx-auto flex w-full flex-col gap-3">
                <ButtonGroupItem
                    title={<Trans>Website or web application</Trans>}
                    iconName="globe"
                    description={
                        <Trans>
                            Provide us the url to your website or web
                            application.
                        </Trans>
                    }
                    onSubmit={() => {
                        history.push('/audits/create')
                    }}
                />
                <ButtonGroupItem
                    title={<Trans>Mobile application</Trans>}
                    iconName="phone-01"
                    description={
                        <Trans>
                            Start by uploading your Android or iOS (Apple)
                            mobile application.
                        </Trans>
                    }
                    onSubmit={() => {
                        history.push('/audits/create-mobile')
                    }}
                />
                <ButtonGroupItem
                    title={
                        <div className="flex items-center gap-2">
                            <Trans>Codebase</Trans>
                            <Tag type="yellow" size="sm">
                                Coming soon...
                            </Tag>
                        </div>
                    }
                    iconName="code-02"
                    description={
                        <Trans>
                            Provide us the git repository of your codebase.
                        </Trans>
                    }
                    isDisabled
                />
            </div>
        </Modal>
    )
}
